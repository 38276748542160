import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
// import Confetti from "@assets/confetti.svg";
import { Chip } from "@components/chip";
import { IconHeadlineButtonTemplate } from "@components/icon-headline-button-template";
// import { Image } from "@components/ui/image";
import { contentFinished_RestartContentNodeAfterFailedMutation } from "@relay/contentFinished_RestartContentNodeAfterFailedMutation.graphql";
import { contentFinished_RestartContentNodeAfterPassedMutation } from "@relay/contentFinished_RestartContentNodeAfterPassedMutation.graphql";
import { Path } from "@router/paths";
import { error100, error20, success100, success20 } from "@themes/colors";
import { TkaH3Span } from "@themes/font-tags";
import {
	CONTENT_SUBMISSION_FRAGMENT,
	RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION,
	RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION,
} from "./content-finished.graphql";
import { PlaceholderWrapper, Wrapper } from "./content-finished.styles";
import { ContentFinishedProps } from "./content-finished.types";
import { VOCAB_CONTENT_NODE_ID } from "../../../../consts";

//TODO: add-translations
export const ContentFinished = ({ contentSubmissionFragmentRef }: ContentFinishedProps) => {
	const { windowHeight, windowWidth } = useWindowSize();
	const [showConfetti, setShowConfetti] = useState(false);

	const contentSubmission = useFragment(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [restartContentNodeAfterPassed] =
		useMutation<contentFinished_RestartContentNodeAfterPassedMutation>(
			RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION,
		);

	const [restartContentNodeAfterFailed] =
		useMutation<contentFinished_RestartContentNodeAfterFailedMutation>(
			RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION,
		);

	const navigate = useNavigate();

	const contentId = contentSubmission.learnOpportunity?.id;

	const isFinishedRootState =
		contentSubmission.learnOpportunity?.root?.structureDefinition.viewerTreeState?.kind ===
		"IsFinished";

	const rewards = contentSubmission.definition.rewardResults;
	const receivedPoints =
		rewards?.reduce((acc, cur) => {
			if (cur.kind === "GamificationPoints_PointsReceived") {
				return acc + (cur.points?.num ?? 0);
			}
			return acc;
		}, 0) ?? 0;

	const nextContentNode = contentSubmission.learnOpportunity?.nextContentNodeId;
	const hasNextContentNode = !!nextContentNode;
	const contentNodeStatus =
		contentSubmission.learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.status;
	const canBeRestartedAfterFailed = contentNodeStatus === "CanBeRestartedAfterFailed";
	const canBeRestartedAfterPassed = contentNodeStatus === "CanBeRestartedAfterPassed";
	const canBeRestartet = canBeRestartedAfterFailed || canBeRestartedAfterPassed;
	const passed = contentSubmission.definition.status === "passed";
	const isRootFinish = !hasNextContentNode && isFinishedRootState;

	const isVocabContent = contentSubmission.learnOpportunity?.id === VOCAB_CONTENT_NODE_ID;

	const handleRepeatOnClick = () => {
		if (!contentId) return;
		if (canBeRestartedAfterFailed) {
			restartContentNodeAfterFailed({
				variables: {
					input: {
						contentNodeId: contentId,
					},
				},
				onCompleted: (response) => {
					const id = response.LearnV2.restartContentNodeAfterFailed?.contentSubmission.id;
					id && navigate(Path.contentSubmission.withId(id).path);
				},
			});
		} else if (canBeRestartedAfterPassed) {
			restartContentNodeAfterPassed({
				variables: {
					input: {
						contentNodeId: contentId,
					},
				},
				onCompleted: (response) => {
					const id = response.LearnV2.restartContentNodeAfterPassed?.contentSubmission.id;
					id && navigate(Path.contentSubmission.withId(id).path);
				},
			});
		}
	};

	const repeatContentSubTitle = canBeRestartet
		? "Versuche es noch einmal, um die Lektion erfolgreich abzuschließen."
		: "Du konntest diese Lektion leider nicht erfolgreich abschließen.";
	const title = passed ? "Geschafft!" : "Fast geschaft ...";
	const contentFinishedSubtitle = isVocabContent
		? "Du hast deine Lernsession erfolgreich abgeschlossen. Gratulation!"
		: passed
		? "Du bist bereit für die nächste Lektion."
		: repeatContentSubTitle;
	const subtitle = isRootFinish
		? "Du hast diesen Kurs erfolgreich abgeschlossen. Gratulation!"
		: contentFinishedSubtitle;

	useEffect(() => {
		if (passed) {
			setShowConfetti(true);

			setTimeout(() => {
				setShowConfetti(false);
			}, 5000);

			return () => {
				setShowConfetti(false);
			};
		}
	}, [passed]);

	return (
		<Wrapper>
			{showConfetti && passed && (
				<Confetti
					width={windowWidth}
					height={windowHeight}
					onConfettiComplete={(c) => {
						c?.stop();
					}}
				/>
			)}
			<PlaceholderWrapper>
				<IconHeadlineButtonTemplate
					iconName={isRootFinish ? "gamingTrophyCheckSuccess" : "flags"}
					iconColor={passed ? success100 : error100}
					iconBackgroundColor={passed ? success20 : error20}
					iconBottomNode={
						receivedPoints > 0 && (
							<Chip>
								<TkaH3Span>+&nbsp;{receivedPoints}</TkaH3Span>
								<Icon icon="coinStar" sizeRem={1} />
							</Chip>
						)
					}
					title={title}
					subtitle={subtitle}
					buttonLabel={canBeRestartet ? "Lektion neu starten" : undefined}
					buttonIconName={canBeRestartet ? "synchronizeArrowClock" : undefined}
					buttonColorVersion="tertiary"
					onClick={handleRepeatOnClick}
				/>
			</PlaceholderWrapper>
		</Wrapper>
	);
};
