/**
 * @generated SignedSource<<f7cc4e00885b7d0aabf5e9c3d367dd5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type LearnableKind = "Course";
import { FragmentRefs } from "relay-runtime";
export type gridView_PublishedLearnableFragment$data = {
  readonly id: string;
  readonly kind: LearnableKind;
  readonly " $fragmentSpreads": FragmentRefs<"educationalOfferCard_PublishedCourseLearnableFragment">;
  readonly " $fragmentType": "gridView_PublishedLearnableFragment";
};
export type gridView_PublishedLearnableFragment$key = {
  readonly " $data"?: gridView_PublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"gridView_PublishedLearnableFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "gridView_PublishedLearnableFragment"
};

(node as any).hash = "86b90d26bfa5014978de185b3c83ec05";

export default node;
