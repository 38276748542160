import { CheckboxStatus } from "@thekeytechnology/academies-lib-webapp/components/checkbox";
import { RadioButtonStatus } from "@thekeytechnology/academies-lib-webapp/components/radio-button";
import { MultipleChoiceGapTextStatus } from "./multiple-choice-gap-text.interface";

export const convertMultipleChoiceStatusToRadioButtonStatus = (
	status: MultipleChoiceGapTextStatus,
) => {
	switch (status) {
		case MultipleChoiceGapTextStatus.Wrong:
			return RadioButtonStatus.Error;
		case MultipleChoiceGapTextStatus.Disabled:
			return RadioButtonStatus.Disabled;
		default:
			return RadioButtonStatus.Default;
	}
};

export const convertMultipleChoiceStatusToCheckboxStatus = (
	status: MultipleChoiceGapTextStatus,
) => {
	switch (status) {
		case MultipleChoiceGapTextStatus.Wrong:
			return CheckboxStatus.Error;
		case MultipleChoiceGapTextStatus.Disabled:
			return CheckboxStatus.Disabled;
		default:
			return CheckboxStatus.Default;
	}
};
