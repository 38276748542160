import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = tw.div`
	h-full
	grid
	grid-rows-[1fr_min-content]
	xl:grid-cols-[min-content_1fr]
	bg-shade-5
`;

export const NavbarWrapper = tw.div`
	row-start-2
	row-end-2
	xl:row-auto
	shadow-brand-main-100/10
	shadow-[0rem_-0.125rem_0.625rem_0rem]
	xl:shadow-none
	z-10
`;

export const ScreenWrapper = tw.div`
	row-start-1
	row-end-1
	xl:row-auto
	flex
	flex-col
	overflow-hidden
`;

export const ScrollWrapper = tw.div`
	overflow-y-auto
	xl:overflow-hidden
	flex-1
	flex
	flex-col
`;

export const ChildrenWrapper = styled.div<{ noPadding: boolean }>`
	${tw`
		xl:overflow-y-auto
		flex-1
		bg-shade-0
		rounded-tl-24
		rounded-tr-24
		xl:rounded-tr-none
		xl:ml-24
		p-24
		md:p-40
	`}
	${({ noPadding }) => (noPadding ? tw`p-0 md:p-0` : "")}
`;

export const BottomContentWrapper = tw.div`
	flex-none
	z-10
	bg-shade-0
	shadow-brand-main-100/10
	shadow-[0rem_-0.125rem_0.625rem_0rem]
	xl:ml-24
`;
