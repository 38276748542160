import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { InputSwitch } from "@thekeytechnology/academies-lib-webapp/components/input-switch";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useContext } from "react";
import { useTheme } from "styled-components";
import { LearnableKind } from "@relay/gridView_Query.graphql";
import { TkaH3Span, TkaP2Span } from "@themes/font-tags";
import { ButtonWrapper, FilterHeadline, InputSwitchWrapper, Wrapper } from "./filters-modal.styles";
import { FiltersModalProps } from "./filters-modal.types";
import { OffersScreenContext } from "../../offers.context";

// TODO: This seems obsolete, consider removing it?

export const FiltersModal = ({ onClose: handleOnClose }: FiltersModalProps) => {
	const theme = useTheme();
	const { t } = useTkaTranslation("offers");
	const { filters, helpers } = useContext(OffersScreenContext);

	const buildIsChecked = (kind: LearnableKind) => filters.kinds.includes(kind);
	const handleOnChange = {
		educational: () => {
			helpers.toggleKind("Course");
		},
	};

	return (
		<Wrapper>
			<FilterHeadline>{t("Filter")}</FilterHeadline>
			<TkaH3Span tkaColor={theme.colors.shade100}>{t("Angebote anzeigen")}</TkaH3Span>
			<InputSwitchWrapper>
				<TkaP2Span tkaColor={theme.colors.shade100}>{t("Weiterbildungen")}</TkaP2Span>
				<InputSwitch
					checked={buildIsChecked("Course")}
					onChange={handleOnChange.educational}
				/>
			</InputSwitchWrapper>
			<ButtonWrapper>
				<Button
					className={"ml-auto"}
					onClick={handleOnClose}
					colorVersion={"tertiary"}
					label={t("Schließen")}
				/>
			</ButtonWrapper>
		</Wrapper>
	);
};
