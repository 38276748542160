import { css, cva } from "@styled-system/css";

export const cardClass = cva({
	base: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		gap: "8",
		height: "full",
		padding: "12",
		border: "1px solid",
		borderRadius: "12",
		boxShadow: "card",
		appearance: "none",
		textAlign: "left",
	},
	variants: {
		color: {
			default: {
				backgroundColor: "onPrimary",
				borderColor: "shade.20",
			},
			yellow: {
				backgroundColor: "warning.0",
				borderColor: "warning.20",
			},
			green: {
				backgroundColor: "success.0",
				borderColor: "success.20",
			},
			blue: {
				backgroundColor: "info.0",
				borderColor: "info.20",
			},
			red: {
				backgroundColor: "error.0",
				borderColor: "error.20",
			},
		},
	},
	defaultVariants: {
		color: "default",
	},
});

export const headerClass = css({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	gap: "8",
});

export const tagClass = cva({
	base: {
		paddingX: "8",
		paddingY: "4",
		borderRadius: "4",
	},
	variants: {
		color: {
			default: {
				backgroundColor: "shade.80",
				color: "shade.5",
			},
			yellow: {
				backgroundColor: "warning.100",
				color: "warning.0",
			},
			green: {
				backgroundColor: "success.100",
				color: "success.0",
			},
			blue: {
				backgroundColor: "info.100",
				color: "info.0",
			},
			red: {
				backgroundColor: "error.100",
				color: "error.0",
			},
		},
	},
	defaultVariants: {
		color: "default",
	},
});

export const deleteButtonClass = css({
	position: "absolute",
	top: "12",
	right: "12",
	display: "none",
	_groupHover: {
		display: "block",
	},
});

export const contentClass = cva({
	base: {
		padding: "8",
		width: "full",
	},
	variants: {
		color: {
			default: {
				color: "shade.80",
			},
			yellow: {
				color: "warning.100",
			},
			green: {
				color: "success.100",
			},
			blue: {
				color: "info.100",
			},
			red: {
				color: "error.100",
			},
		},
	},
	defaultVariants: {
		color: "default",
	},
});

export const markedTextClass = cva({
	base: {
		width: "full",
		padding: "10",
		borderRadius: "8",
		fontStyle: "italic",
	},
	variants: {
		color: {
			default: {
				color: "shade.80",
				backgroundColor: "shade.20",
			},
			yellow: {
				color: "warning.100",
				backgroundColor: "warning.20",
			},
			green: {
				color: "success.100",
				backgroundColor: "success.20",
			},
			blue: {
				color: "info.100",
				backgroundColor: "info.20",
			},
			red: {
				color: "error.100",
				backgroundColor: "error.20",
			},
		},
	},
	defaultVariants: {
		color: "default",
	},
});
