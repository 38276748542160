import { graphql } from "react-relay";

export const CONTENT_OVERVIEW_QUERY = graphql`
	query contentOverview_GetContentOverviewQuery($id: ID!) {
		node(id: $id) {
			... on LearnOpportunityV2 {
				...contentOverview_LearnOpportunityV2Fragment
			}
		}
	}
`;

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment contentOverview_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		description
		instructorsV2 {
			...instructorsComponent_InstructorsConnectionFragment
		}
		indexPath
		structureDefinition {
			title
		}
		typeDefinition {
			... on LearnOpportunityELearningContentTypeDefinition {
				contentKind
				extension {
					... on LearnContentExtensionImpl {
						receivableGamificationPoints
						receivedGamificationPoints
					}
				}
			}
			... on LearnAsyncContentTypeDefinition {
				contentKind
				extension {
					... on LearnContentExtensionImpl {
						receivableGamificationPoints
						receivedGamificationPoints
					}
				}
			}
		}
		image {
			url
		}
		root {
			...branchNodesTab_LearnOpportunityV2Fragment
		}
		lengthInSecondsV2
		indexPath
		...attachmentsCollection_AttachmentsCollectionFragment
		...contentElementsCollection_LearnOpportunityV2Fragment
		...eLearningContentOverview_LearnOpportunityV2Fragment
		...asyncContentOverview_LearnOpportunityV2Fragment
	}
`;
