import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	height: "full",
});

export const headerClass = css({
	display: "flex",
	justifyContent: "space-between",
	width: "full",
	marginBottom: "24",
});

export const headerPointsClass = css({
	display: "flex",
	alignItems: "center",
	gap: "4",
});

export const purpleGradientClass = css({
	position: "relative",
	display: "flex",
	justifyContent: "space-between",
	padding: "32",
	borderRadius: "12",
	width: "full",
	background: "[rgba(83, 39, 230, 0.60)]",
	boxShadow:
		"[0px 1px 1px 0px rgba(16, 24, 32, 0.04), 0px 2px 5px 0px rgba(16, 24, 32, 0.02), 0px 4px 0px 0px rgba(41, 19, 113, 0.11)]",
	_before: {
		content: "''",
		position: "absolute",
		top: "0",
		left: "0",
		right: "0",
		bottom: "0",
		zIndex: "1",
		borderRadius: "12",
		background: "[radial-gradient(150% 417.55% at 5.45% 43.49%, #8E73EB 0%, #5327E6 60%)]",
	},
});

export const greenGradientClass = css({
	position: "relative",
	display: "flex",
	justifyContent: "space-between",
	marginTop: "24",
	paddingX: "32",
	paddingY: "16",
	borderRadius: "12",
	width: "full",
	background: "[radial-gradient(120% 1000% at 5% 54%, #7270CF 0%, #079E83 50%, #27E59B 100%)]",
	boxShadow:
		"[0px 1px 1px 0px rgba(16, 24, 32, 0.04), 0px 2px 5px 0px rgba(16, 24, 32, 0.02), 0px 4px 0px 0px rgba(41, 19, 113, 0.11)]",
});

export const userDataWrapperClass = css({
	position: "relative",
	zIndex: "10",
	display: "flex",
	gap: "16",
});

export const userDataClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "4",
});

export const userLevelAndButtonWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-end",
	alignItems: "flex-end",
});

export const userEditButtonClass = css({
	position: "absolute",
	zIndex: "10",
	top: "12",
	right: "12",
	display: "flex",
	alignItems: "center",
	gap: "4",
	paddingX: "8",
	paddingY: "[3px]",
	backgroundColor: "shade.0",
	borderRadius: "4",
});

export const userLevelWrapperClass = css({
	position: "relative",
	zIndex: "10",
});

export const userLevelTitleClass = css({
	display: "flex",
	gap: "4",
});

export const userLevelProgressWrapperClass = css({
	display: "flex",
	gap: "8",
	alignItems: "center",
});

export const userLevelProgressBarClass = css({
	height: "full",
	width: "[0%]",
	borderRadius: "full",
	backgroundColor: "shade.100",
});

export const userLevelProgressClass = css({
	width: "[84px]",
	height: "[10px]",
	borderRadius: "full",
	backgroundColor: "primary.40",
});

export const daysWrapperClass = css({
	display: "flex",
	alignItems: "center",
	gap: "16",
});

export const dayClass = css({
	position: "relative",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "[40px]",
	height: "[40px]",
	borderRadius: "full",
});

export const dayTextClass = css({
	position: "absolute",
	top: "[50%]",
	left: "[50%]",
	transform: "translate(-50%, -50%)",
	color: "shade.0",
});

export const sectionClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
	marginTop: "24",
});

export const sectionHeaderClass = css({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
});

export const gridClass = css({
	display: "grid",
	gap: "24",
	gridTemplateColumns: "12",
	gridAutoRows: "fr",
});

export const gapClass = css({
	height: "[1.5rem]",
});

export const gridItemClass = css({
	gridRow: "[1 / -1]",
	gridColumn: "[1 / -1]",
	md: {
		gridColumn: "6",
	},
	xl: {
		gridColumn: "4",
	},
	"2xl": {
		gridColumn: "3",
	},
});
