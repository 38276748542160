import { ReduxStore } from "@thekeytechnology/academies-lib-webapp";
import { AxiosProgressEvent, AxiosResponse } from "axios";
import http from "../axios";
import { refreshJWTToken } from "../network/JwtMiddleware";

class UploadFileService {
	upload(
		url: string,
		file: File,
		onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
		onBeforeRequest?: (formData: FormData) => void,
	): Promise<AxiosResponse<any, any>> {
		const formData = new FormData();

		formData.append("file", file);
		onBeforeRequest?.(formData);

		const loginData = ReduxStore.getState().auth.loginData;

		if (!loginData) return Promise.reject();

		return http
			.post(url, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: loginData.accessToken!,
				},
				onUploadProgress,
			})
			.catch((error) => {
				if (error.response.status === 401) {
					return refreshJWTToken().then(() =>
						this.upload(url, file, onUploadProgress, onBeforeRequest),
					);
				}
				return Promise.reject(error);
			});
	}
}

export default new UploadFileService();
