import { PageViewTracker } from "@thekeytechnology/academies-lib-webapp/analytics";
import { RouteDefinition } from "@thekeytechnology/academies-lib-webapp/navigation";
import {
	CurrentUserData,
	setCurrentUser,
	selectFirstLogin,
	selectLoginData,
} from "@thekeytechnology/academies-lib-webapp/slices";
import { graphql } from "react-relay";
import _, { isArray } from "lodash";
import React, { ReactElement, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { RouteObject } from "react-router";
import { BrowserRouter, Navigate, Route as DOMRoute, Routes } from "react-router-dom";
import { CookieBannerScreen } from "@components/cookie-banner";
import {
	Permission,
	permissionBasedNavigation_Query,
} from "@relay/permissionBasedNavigation_Query.graphql";
import { Path } from "@router/index";
import { BaseScreenSkeleton } from "./base-screen.skeleton";
import { SelectionMenuComponent } from "@components/selection-menu";

const PERMISSION_QUERY = graphql`
	query permissionBasedNavigation_Query {
		Viewer {
			Auth {
				currentUser {
					user {
						id
					}
					permissionsInAccount
				}
				...accountSwitchMenu_AuthViewerSchemaFragment
				...navbar_AuthViewerSchemaFragment
				...profileMenu_AuthViewerSchemaFragment
			}
		}
	}
`;

interface OwnProps {
	routes: RouteDefinition[];
	forbiddenRoute?: ReactElement;
}

interface OwnPropsWithQuery extends OwnProps {
	queryRef: PreloadedQuery<permissionBasedNavigation_Query>;
}

export function PermissionBasedNavigationWithPreloadedQuery({
	routes,
	forbiddenRoute,
	queryRef,
}: OwnPropsWithQuery) {
	const query = usePreloadedQuery<permissionBasedNavigation_Query>(PERMISSION_QUERY, queryRef);
	const dispatch = useDispatch();
	const firstLogin = useSelector(selectFirstLogin);

	useEffect(() => {
		if (query.Viewer.Auth.currentUser) {
			dispatch(setCurrentUser(query.Viewer.Auth.currentUser as any as CurrentUserData));
		}
	}, [query.Viewer.Auth.currentUser, dispatch]);

	const filteredRoutes = filterByPermission(
		routes,
		!!query.Viewer.Auth.currentUser,
		!!firstLogin,
		[...(query.Viewer.Auth.currentUser?.permissionsInAccount || [])],
	);
	return (
		<BrowserRouter>
			<Routes>
				{filteredRoutes.map((prd) => {
					return (
						<DOMRoute key={prd.path} path={prd.path} element={prd.element}>
							{renderNestedDomRoutes(prd.children)}
						</DOMRoute>
					);
				})}

				<DOMRoute
					key={"verboten"}
					path={"*"}
					element={
						forbiddenRoute ? (
							forbiddenRoute
						) : (
							<Navigate
								replace
								to={
									query.Viewer.Auth.currentUser
										? Path.dashboard.path
										: Path.login.path
								}
							/>
						)
					}
				/>
			</Routes>
			<SelectionMenuComponent />
			<CookieBannerScreen />
			<PageViewTracker />
		</BrowserRouter>
	);
}

export const PermissionBasedNavigation = (props: OwnProps) => {
	const [queryReference, loadQuery] =
		useQueryLoader<permissionBasedNavigation_Query>(PERMISSION_QUERY);
	const loginData = useSelector(selectLoginData);

	useEffect(() => {
		loadQuery({}, { fetchPolicy: "store-and-network" });
	}, [loadQuery, loginData]);

	return (
		<Suspense fallback={<BaseScreenSkeleton />}>
			{queryReference && (
				<PermissionBasedNavigationWithPreloadedQuery queryRef={queryReference} {...props} />
			)}
		</Suspense>
	);
};

export type RequiredPermissionsType =
	| Permission[]
	| "first-login"
	| "logged-in"
	| "logged-in-and-logged-out"
	| "only-logged-out";
export type HasRequiredPermissionsType = { requiredPermissions: RequiredPermissionsType };

export function filterByPermission<ItemType extends HasRequiredPermissionsType>(
	items: ItemType[],
	isLoggedIn: boolean,
	isFirstLogin: boolean,
	permissionsInAccount?: Permission[],
) {
	return items.filter((r) => {
		const firstLogin = r.requiredPermissions === "first-login" && isLoggedIn && isFirstLogin;
		const loggedOutAndRouteRequiresLoggedOut =
			r.requiredPermissions === "only-logged-out" && !isLoggedIn;
		const loggedInAndRouteOnlyRequiredLoggedIn =
			r.requiredPermissions === "logged-in" && isLoggedIn;
		const loggedInOrLoggedOut = r.requiredPermissions === "logged-in-and-logged-out";

		const permissionsAreKnown = permissionsInAccount && isArray(r.requiredPermissions);
		const isLoggedInAndHasAllNecessaryPermissions =
			permissionsAreKnown &&
			(_.difference(r.requiredPermissions, permissionsInAccount).length === 0 ||
				permissionsInAccount.includes("UserInAccountPermission_System_Owner"));

		return (
			firstLogin ||
			loggedInOrLoggedOut ||
			loggedOutAndRouteRequiresLoggedOut ||
			loggedInAndRouteOnlyRequiredLoggedIn ||
			isLoggedInAndHasAllNecessaryPermissions
		);
	});
}

function renderNestedDomRoutes(routes?: RouteObject[]) {
	return routes?.map((route) => (
		<DOMRoute key={route?.path} path={route?.path} element={route?.element}>
			{renderNestedDomRoutes(route.children)}
		</DOMRoute>
	));
}
