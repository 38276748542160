/**
 * @generated SignedSource<<45e466dadf584fccd2662828ca3389ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TextElement_ElearningContentSubmissionDefinitionFragment$data = {
  readonly currentElementState: {
    readonly element: {
      readonly id: string;
      readonly readMore?: string | null;
      readonly readMoreButtonText?: string | null;
      readonly text?: string;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"textLearnElement_EnhancedTextLearnElementFragment">;
    };
  } | null;
  readonly " $fragmentType": "TextElement_ElearningContentSubmissionDefinitionFragment";
};
export type TextElement_ElearningContentSubmissionDefinitionFragment$key = {
  readonly " $data"?: TextElement_ElearningContentSubmissionDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TextElement_ElearningContentSubmissionDefinitionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TextElement_ElearningContentSubmissionDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "currentElementState",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "element",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "readMore",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "readMoreButtonText",
                  "storageKey": null
                }
              ],
              "type": "EnhancedTextLearnElement",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "textLearnElement_EnhancedTextLearnElementFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActiveELearningContentSubmissionDefinition",
  "abstractKey": null
};

(node as any).hash = "3e36b38747f711fe5477f396ce70d201";

export default node;
