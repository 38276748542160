import { BadgeV2 } from "@thekeytechnology/academies-lib-webapp/components/badge-v2";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { Label } from "@thekeytechnology/academies-lib-webapp/components/label";
import { ResponsiveBottomSheetOverlayPanel as ResponsiveBottomSheetOverlayPanelComponent } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-overlay-panel";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import React, { type MouseEvent, useContext, useMemo, useRef } from "react";
import { LearnableKind } from "@relay/gridView_Query.graphql";
import { shade100, shade40 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import {
	DesktopLabelWrapper,
	FilterTextWrapper,
	InputTextMock,
	MobileBadgeWrapper,
	Wrapper,
} from "./filters.styles";
import { OffersScreenContext } from "../../offers.context";
import { FiltersModal } from "../filters-modal";

export const Filters = () => {
	const overlayRef = useRef<ResponsiveBottomSheetOverlayPanelComponent>(null);
	const handleOpenOnClick = (event: MouseEvent<HTMLElement>) => {
		overlayRef.current?.show(event, event.currentTarget);
	};
	const handleOnClose = () => {
		overlayRef.current?.hide();
	};

	const { filters } = useContext(OffersScreenContext);
	const { t } = useTkaTranslation("offers");
	const { isMediumUp } = useWindowSize();
	const calculatedValue = useMemo(() => {
		const defaultValue = "Filter";
		//TODO add translations
		const translationKeyMap: Record<LearnableKind, string> = {
			// GroupCoaching: "Gruppen Coaching",
			// OneToOneCoaching: "Coaching",
			Course: "Weiterbildungen",
		};
		const kindsValue = filters.kinds
			.map((kind) => {
				return t(translationKeyMap[kind]);
			})
			.join(", ");
		return [kindsValue].filter((e) => e).join(", ") || defaultValue;
	}, [filters.kinds]);
	const badgeCount = filters.kinds.length;

	const iconColor = useMemo(() => (isMediumUp ? shade40 : shade100), [isMediumUp]);
	const iconSize = useMemo(() => (isMediumUp ? 1.5 : 0.75), [isMediumUp]);
	return (
		<React.Fragment>
			<Wrapper>
				<InputTextMock onClick={handleOpenOnClick}>
					<Icon icon={"filter"} sizeRem={iconSize} color={iconColor} />
					{isMediumUp && (
						<FilterTextWrapper>
							<TkaP2Span tkaColor={shade100}>{calculatedValue}</TkaP2Span>
						</FilterTextWrapper>
					)}

					{isMediumUp && badgeCount ? (
						<DesktopLabelWrapper>
							<Label label={`+${badgeCount}`} />
						</DesktopLabelWrapper>
					) : (
						<React.Fragment />
					)}
				</InputTextMock>
				{!isMediumUp && badgeCount ? (
					<MobileBadgeWrapper>
						<BadgeV2 value={badgeCount} severity={"brandStrong"} />
					</MobileBadgeWrapper>
				) : (
					<React.Fragment />
				)}
			</Wrapper>
			<ResponsiveBottomSheetOverlayPanelComponent ref={overlayRef}>
				<FiltersModal onClose={handleOnClose} />
			</ResponsiveBottomSheetOverlayPanelComponent>
		</React.Fragment>
	);
};
