/**
 * @generated SignedSource<<e87829fb6bf9800ce9a74b14b46b05c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentSubmissionSuccessModal_ContentSubmissionFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"goToNextElementButton_ContentSubmissionFragment">;
  readonly " $fragmentType": "ContentSubmissionSuccessModal_ContentSubmissionFragment";
};
export type ContentSubmissionSuccessModal_ContentSubmissionFragment$key = {
  readonly " $data"?: ContentSubmissionSuccessModal_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentSubmissionSuccessModal_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentSubmissionSuccessModal_ContentSubmissionFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "goToNextElementButton_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "3b7e3a15f6af0a282696e371ab1034d1";

export default node;
