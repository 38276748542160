import { AuthTemplate } from "@thekeytechnology/academies-lib-webapp/components/auth-template";
import { LoginSteps } from "@thekeytechnology/academies-lib-webapp/screens/login";
import { LoginMarketing } from "./parts/login-marketing";

export const LoginScreen = () => {
	return (
		<AuthTemplate rightSide={<LoginMarketing />}>
			<LoginSteps />
		</AuthTemplate>
	);
};
