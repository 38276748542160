/**
 * @generated SignedSource<<6d8565310f1207e78069bbce7c36448f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentNodeAdvancementStatus = "BlockedByTree" | "CanBeRestartedAfterFailed" | "CanBeRestartedAfterPassed" | "CanBeStarted" | "CanNotBeRestartedAfterFailed" | "CanNotBeRestartedAfterPassed" | "CanNotBeStarted" | "Continue" | "NotContentNode" | "ViolatesTreeFlow";
export type ContentSubmissionStatus = "active" | "failed" | "passed";
export type IHKCertificateOrderStateKind = "ErrorOccurred" | "NotIHK" | "Ordered" | "ReadyToOrder" | "RewardNotAchievedYet";
export type UnlockInfoKind = "Demo" | "ErrorOccurred" | "FullAccess" | "WallNotFound";
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible";
import { FragmentRefs } from "relay-runtime";
export type contentSubmissionNavigation_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly progress?: {
      readonly percentage: number;
    };
    readonly status?: ContentSubmissionStatus;
  };
  readonly id: string;
  readonly learnOpportunity: {
    readonly id: string;
    readonly nextContentNode: {
      readonly typeDefinition: {
        readonly extension?: {
          readonly isUnlockNecessaryToStart?: boolean;
        };
      };
    } | null;
    readonly nextContentNodeId: string | null;
    readonly root: {
      readonly id: string;
      readonly structureDefinition: {
        readonly extension?: {
          readonly cartClickout?: {
            readonly relativeLink: string;
          } | null;
          readonly ihkState?: IHKCertificateOrderStateKind;
          readonly unlockInfo?: {
            readonly kind: UnlockInfoKind;
          };
        };
        readonly viewerTreeState?: {
          readonly kind: ViewerTreeStateKind;
        };
      };
    } | null;
    readonly typeDefinition: {
      readonly contentNodeAdvancementResult?: {
        readonly status: ContentNodeAdvancementStatus;
      };
    };
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"goToNextElementButton_ContentSubmissionFragment" | "goToPreviousElementButton_ContentSubmissionFragment">;
  readonly " $fragmentType": "contentSubmissionNavigation_ContentSubmissionFragment";
};
export type contentSubmissionNavigation_ContentSubmissionFragment$key = {
  readonly " $data"?: contentSubmissionNavigation_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentSubmissionNavigation_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "kind",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "extension",
    "plural": false,
    "selections": [
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isUnlockNecessaryToStart",
            "storageKey": null
          }
        ],
        "type": "LearnContentExtensionImpl",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentSubmissionNavigation_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "progress",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "percentage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "type": "ELearningContentSubmissionDefinition",
          "abstractKey": "__isELearningContentSubmissionDefinition"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "structureDefinition",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "viewerTreeState",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "extension",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CartClickout",
                              "kind": "LinkedField",
                              "name": "cartClickout",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "relativeLink",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "unlockInfo",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "ihkState",
                              "storageKey": null
                            }
                          ],
                          "type": "PublishedRootExtensionImpl",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnOpportunityRootStructureDefinition",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "typeDefinition",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "contentNodeAdvancementResult",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "LearnOpportunityContentTypeDefinition",
              "abstractKey": "__isLearnOpportunityContentTypeDefinition"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nextContentNodeId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "nextContentNode",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "typeDefinition",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "LearnOpportunityELearningContentTypeDefinition",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "LearnAsyncContentTypeDefinition",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "goToPreviousElementButton_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "goToNextElementButton_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "47cc0673bf056cfbce5ba5cb4da31627";

export default node;
