import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import React, { useContext, useEffect, useState } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider/content-submission-modal-context-provider.context";
import { ShowAnswerButton_CanShowElementAnswerMutation } from "@relay/ShowAnswerButton_CanShowElementAnswerMutation.graphql";
import { ShowAnswerButton_ContentSubmissionFragment$key } from "@relay/ShowAnswerButton_ContentSubmissionFragment.graphql";
import { ShowAnswerButton_ShowElementAnswerMutation } from "@relay/ShowAnswerButton_ShowElementAnswerMutation.graphql";

const ELEARNING_CONTENT_SUBMISSION_DEFINITION = graphql`
	fragment ShowAnswerButton_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						id
					}
				}
			}
		}
	}
`;
const CAN_SHOW_ELEMENT_ANSWER_MUTATION = graphql`
	mutation ShowAnswerButton_CanShowElementAnswerMutation($input: CanShowElementAnswerInput!) {
		ELearning {
			canShowElementAnswer(input: $input) {
				clientMutationId
				can
			}
		}
	}
`;

const SHOW_ELEMENT_ANSWER_MUTATION = graphql`
	mutation ShowAnswerButton_ShowElementAnswerMutation($input: ShowElementAnswerElementInput!) {
		ELearning {
			showElementAnswerElement(input: $input) {
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;
interface ShowAnswerButtonProps {
	contentSubmissionFragmentRef: ShowAnswerButton_ContentSubmissionFragment$key;
}
export const ShowAnswerButton = ({ contentSubmissionFragmentRef }: ShowAnswerButtonProps) => {
	const { t } = useTkaTranslation("failureEvaluationModal");
	const [showingShowAnswerButton, setShowingShowAnwserButton] = useState(false);
	const contentSubmission = useFragment<ShowAnswerButton_ContentSubmissionFragment$key>(
		ELEARNING_CONTENT_SUBMISSION_DEFINITION,
		contentSubmissionFragmentRef,
	);
	const [showElementAnswer] = useMutation<ShowAnswerButton_ShowElementAnswerMutation>(
		SHOW_ELEMENT_ANSWER_MUTATION,
	);
	const [canShowAnswer] = useMutation<ShowAnswerButton_CanShowElementAnswerMutation>(
		CAN_SHOW_ELEMENT_ANSWER_MUTATION,
	);

	const { showAnswerClicked } = useContext(ContentSubmissionModalContext);

	const { isMediumUp } = useWindowSize();

	useEffect(() => {
		canShowAnswer({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id!,
				},
			},
			onCompleted: (response) => {
				setShowingShowAnwserButton(response.ELearning.canShowElementAnswer?.can ?? false);
			},
		});
	}, []);

	const onClick = () => {
		showAnswerClicked();
		showElementAnswer({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id!,
					inputElementId: contentSubmission.definition!.currentElementState!.element!.id!,
				},
			},
		});
	};
	if (!showingShowAnswerButton) return <React.Fragment />;
	return (
		<Button
			fillParent={!isMediumUp}
			label={t("failure_evaluation_modal.showAnswer")}
			colorVersion={"outline"}
			minWidthRem={10}
			onClick={onClick}
		/>
	);
};
