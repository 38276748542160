import { OverlayPanel } from "@thekeytechnology/academies-lib-webapp/components/overlay-panel";
import { UserAvatar } from "@thekeytechnology/academies-lib-webapp/components/user-avatar";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useFragment } from "react-relay";
import { avatarWithAccountSwitch_AuthViewerSchemaFragment$key } from "@relay/avatarWithAccountSwitch_AuthViewerSchemaFragment.graphql";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./avatar-with-account-switch.graphql";
import {
	AvatarWrapper,
	ContentWrapper,
	FullScreenWrapper,
	Wrapper,
} from "./avatar-with-account-switch.styles";
import { AvatarWithAccountSwitchProps } from "./avatar-with-account-switch.types";
import { AccountSwitchMenu } from "../account-switch-menu";
import { Header } from "../header";

export const AvatarWithAccountSwitch = ({
	authViewerSchemaFragmentRef,
}: AvatarWithAccountSwitchProps) => {
	const auth = useFragment<avatarWithAccountSwitch_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef,
	);

	const overlayPanelRef = useRef<OverlayPanel>(null);
	const [showAccountSwitchMenu, setShowAccountSwitchMenu] = useState(false);

	const { isMediumUp } = useWindowSize();

	const handleChildrenOnClick = (event: MouseEvent<HTMLElement>) => {
		overlayPanelRef.current?.toggle(event);
		setShowAccountSwitchMenu(true);
	};

	const handleCloseOnClick = () => {
		setShowAccountSwitchMenu(false);
	};

	useEffect(() => {
		setShowAccountSwitchMenu(false);
	}, [isMediumUp]);

	const renderMenu = <AccountSwitchMenu authViewerSchemaFragmentRef={auth} />;
	const renderMenuWithOverlay = isMediumUp ? (
		<OverlayPanel ref={overlayPanelRef}>{renderMenu}</OverlayPanel>
	) : (
		showAccountSwitchMenu && (
			<FullScreenWrapper>
				<Header leftIcon="close" onLeftItemClick={handleCloseOnClick} />
				<ContentWrapper>{renderMenu}</ContentWrapper>
			</FullScreenWrapper>
		)
	);

	if (!auth.currentUser) return null;
	return (
		<Wrapper>
			{renderMenuWithOverlay}
			<AvatarWrapper onClick={handleChildrenOnClick}>
				<UserAvatar userFragmentRef={auth.currentUser.user} />
			</AvatarWrapper>
		</Wrapper>
	);
};
