import { graphql } from "react-relay";

export const NODE_QUERY = graphql`
	query selectionMenu_NodeQuery($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on ContentSubmission {
				id
				...upsertNoteModal_ContentSubmissionFragment
			}
		}
	}
`;

export const LEARN_OPPORTUNITY_V2_QUERY = graphql`
	query selectionMenu_LearnOpportunityV2Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on LearnOpportunityV2 {
				id
				...upsertNoteModal_LearnOpportunityV2Fragment
			}
		}
	}
`;
