import { MaterialCardProps } from "@thekeytechnology/academies-lib-webapp/components/material-card";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useFragment } from "react-relay";
import { MaterialCardsCollection } from "@components/material-cards-collection";
import { contentElementsCollection_LearnOpportunityV2Fragment$key } from "@relay/contentElementsCollection_LearnOpportunityV2Fragment.graphql";
import { TkaH2Span } from "@themes/font-tags";
import { LEARN_OPPORTUNITY_V2_FRAGMENT } from "./content-elements-collection.graphql";
import { ContentElementsCollectionProps } from "./content-elements-collection.interface";
import { Wrapper } from "./content-elements-collection.styles";

export const ContentElementsCollection = ({
	learnOpportunityV2FragmentRef,
}: ContentElementsCollectionProps) => {
	const node = useFragment<contentElementsCollection_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef,
	);
	const { t } = useTkaTranslation("contentOverviewScreen");

	const {
		numFileElements = 0,
		sumTextLengthInSec = 0,
		sumVideoLengthInSec = 0,
		sumPodcastWithTimestampLengthInSec = 0,
		numExerciseElements = 0,
	} = node?.typeDefinition?.extension?.elementsSummary ?? {};

	const hasContentElements =
		numFileElements > 0 ||
		numExerciseElements > 0 ||
		sumTextLengthInSec > 0 ||
		sumVideoLengthInSec > 0 ||
		sumPodcastWithTimestampLengthInSec > 0;

	const cards: MaterialCardProps[] = [];
	if (sumTextLengthInSec > 0) {
		cards.push({
			icon: "readSearch",
			title: t("content_overview_screen.textsLengthTitle"),
			description: t("content_overview_screen.text_length_label", {
				count: Math.ceil(sumTextLengthInSec / 60),
			}),
			isExpanded: false,
		});
	}
	if (numFileElements > 0) {
		cards.push({
			icon: "picture",
			title: t("content_overview_screen.imagesCountTitle"),
			description: t("content_overview_screen.images_count_label", {
				count: numFileElements,
			}),
			isExpanded: false,
		});
	}
	if (sumVideoLengthInSec > 0) {
		cards.push({
			icon: "video",
			title: t("content_overview_screen.videoLengthTitle"),
			description: t("content_overview_screen.video_length_label", {
				count: Math.ceil(sumVideoLengthInSec / 60),
			}),
			isExpanded: false,
		});
	}
	if (sumPodcastWithTimestampLengthInSec > 0) {
		cards.push({
			icon: "microphonePodcast2",
			title: t("content_overview_screen.podcastLengthTitle"),
			description: t("content_overview_screen.podcast_length_label", {
				count: Math.ceil(sumPodcastWithTimestampLengthInSec / 60),
			}),
			isExpanded: false,
		});
	}
	if (numExerciseElements > 0) {
		cards.push({
			icon: "question",
			title: t("content_overview_screen.exerciseCountTitle"),
			description: t("content_overview_screen.exercise_count_label", {
				count: numExerciseElements,
			}),
			isExpanded: false,
		});
	}

	return hasContentElements ? (
		<Wrapper>
			<TkaH2Span>{t("content_overview_screen.contentElementsTitle")}</TkaH2Span>
			<MaterialCardsCollection rows={1} cards={cards} />
		</Wrapper>
	) : null;
};
