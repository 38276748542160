import { css } from "@styled-system/css";

export const header = css({
	display: "flex",
	flexDirection: "column",
	gap: "24",
	height: "full",
});

export const bottonBarWrapper = css({
	display: "flex",
	justifyContent: "flex-end",
	gap: "8",
	padding: "16",
});
