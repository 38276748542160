/**
 * @generated SignedSource<<bb929dec8fc95a2ee7d53901701a7935>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type notesOverview_NotesQueryFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly reminder: {
        readonly __typename: string;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"noteCard_NoteFragment">;
    };
  } | null> | null;
  readonly " $fragmentType": "notesOverview_NotesQueryFragment";
};
export type notesOverview_NotesQueryFragment$key = {
  readonly " $data"?: notesOverview_NotesQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"notesOverview_NotesQueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "notesOverview_NotesQueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NoteEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Note",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "reminder",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "noteCard_NoteFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NoteConnection",
  "abstractKey": null
};

(node as any).hash = "e305ff996ef333f0639ed8612d5047ac";

export default node;
