import { type FC, useCallback, useEffect, useState, useRef } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useMatch } from "react-router-dom";
import { NoteColorPicker } from "@components/note-color-picker";
import { UpsertNoteModal } from "@components/upsert-note-modal";
import type { NoteColor } from "@relay/noteCard_NoteFragment.graphql";
import type { selectionMenu_LearnOpportunityV2Query } from "@relay/selectionMenu_LearnOpportunityV2Query.graphql";
import type { selectionMenu_NodeQuery } from "@relay/selectionMenu_NodeQuery.graphql";
import { BranchNodesPath, ContentSubmissionPath, RootPath } from "@router/paths";
import { P2Span } from "@themes/font-tags";
import {
	DATASET_SELECTION_MENU_ELEMENT_NAME,
	NO_SELECTION_SELECTOR,
} from "./selection-menu.consts";
import { LEARN_OPPORTUNITY_V2_QUERY, NODE_QUERY } from "./selection-menu.graphql";
import { noteCreateButtonClass, selectionMenuClass } from "./selection-menu.styles";
import { SelectionMenuProps } from "./selection-menu.types";
import { getSelectorValueFromParent, hasParentWithMatchingSelector } from "./selection-menu.utils";

export const SelectionMenuComponent: FC<SelectionMenuProps> = ({}) => {
	const [showMenu, setShowMenu] = useState(false);
	const [menuPosition, setMenuPosition] = useState({ x: "0", y: "0" });
	const [upsertNoteModalOpen, setUpsertNoteModalOpen] = useState(false);

	const rootParams = useMatch(RootPath.withIdPlaceholder().path + "/*");
	const contentSubmissionParams = useMatch(ContentSubmissionPath.withIdPlaceholder().path);
	const branchNodesParams = useMatch(
		`${RootPath.withIdPlaceholder().path}/${BranchNodesPath.withIdPlaceholder().path}`,
	);

	const [selectedText, setSelectedText] = useState<string>();
	const [color, setColor] = useState<NoteColor>("default");
	const [elementId, setElementId] = useState<string | null>(null);

	const nodeQuery = useLazyLoadQuery<selectionMenu_NodeQuery>(
		NODE_QUERY,
		{
			id: contentSubmissionParams?.params.rootId || "",
			skip: !contentSubmissionParams?.params.rootId,
		},
		{ UNSTABLE_renderPolicy: "partial", fetchPolicy: "network-only" },
	);

	const learnOpportunityV2Query = useLazyLoadQuery<selectionMenu_LearnOpportunityV2Query>(
		LEARN_OPPORTUNITY_V2_QUERY,
		{
			id: branchNodesParams?.params?.branchNodeId || rootParams?.params?.rootId || "",
			skip: !rootParams?.params?.rootId && !branchNodesParams?.params?.branchNodeId,
		},
		{ UNSTABLE_renderPolicy: "partial", fetchPolicy: "network-only" },
	);

	const menuRef = useRef<HTMLDivElement>(null);

	const removeAllSelections = useCallback(() => {
		const selection = window.getSelection();
		selection?.removeAllRanges();
	}, []);

	const handleNoteCreate = () => {
		removeAllSelections();
		setUpsertNoteModalOpen(true);
		setShowMenu(false);
	};

	const handleUpsertNoteModalDismiss = () => {
		setUpsertNoteModalOpen(false);
		setShowMenu(false);
	};

	const handleUpsertNoteSuccess = () => {
		setUpsertNoteModalOpen(false);
	};

	const handleColorChange = (clr: NoteColor) => {
		setColor(clr);
		setUpsertNoteModalOpen(true);
		setShowMenu(false);
	};

	const handleMouseUp = useCallback(
		(event: MouseEvent) => {
			const selection = window.getSelection();
			const textSelection = selection?.toString();

			const clickedElement = event.target as HTMLElement;
			if (menuRef.current?.contains(clickedElement)) return;

			const selectionNode = (selection?.anchorNode as HTMLElement) ?? clickedElement;

			if (hasParentWithMatchingSelector(selectionNode, NO_SELECTION_SELECTOR)) {
				setShowMenu(false);
				return;
			}

			if (textSelection === "" || textSelection === selectedText) {
				setShowMenu(false);
				setSelectedText(undefined);
				return;
			}

			if (
				hasParentWithMatchingSelector(
					selectionNode,
					`[data-${DATASET_SELECTION_MENU_ELEMENT_NAME}]`,
				)
			) {
				const id = getSelectorValueFromParent(
					selection?.anchorNode?.parentElement ?? clickedElement,
					`[data-${DATASET_SELECTION_MENU_ELEMENT_NAME}]`,
				);
				if (id) setElementId(id);
			}

			setSelectedText(textSelection);
			setShowMenu(true);
			const rect = selection?.getRangeAt(0).getBoundingClientRect();
			if (!rect) return;

			setMenuPosition({
				x: `calc(${rect.left}px + calc(${rect.width}px / 2) - 40px)`,
				y: `calc(${rect.top}px - 50px)`,
			});
		},
		[selectedText],
	);

	useEffect(() => {
		window.addEventListener("mouseup", handleMouseUp);

		return () => {
			window.removeEventListener("mouseup", handleMouseUp);
		};
	}, [handleMouseUp]);

	return (
		<>
			{upsertNoteModalOpen && (
				<UpsertNoteModal
					onDismiss={handleUpsertNoteModalDismiss}
					onSuccess={handleUpsertNoteSuccess}
					color={color}
					selectedText={selectedText}
					sourceElementId={elementId}
					sourceModuleId={branchNodesParams?.params.branchNodeId}
					sourceLearnOpportunityId={rootParams?.params.rootId}
					contentSubmissionFragmentRef={nodeQuery.node}
					learnOpportunityV2FragmentRef={learnOpportunityV2Query.node}
				/>
			)}
			{showMenu && (
				<div
					ref={menuRef}
					className={selectionMenuClass}
					style={{
						top: menuPosition.y,
						left: menuPosition.x,
					}}
				>
					<NoteColorPicker defaultColor={color} onChange={handleColorChange} />
					<button
						type="button"
						onClick={handleNoteCreate}
						className={noteCreateButtonClass}
					>
						<P2Span>Notiz hinzufügen</P2Span>
					</button>
				</div>
			)}
		</>
	);
};
