import { TabWrapper } from "./root-overview-tab.styles";
import { FocusContentMenuSkeleton } from "../../../components/focus-content-menu/focus-content-menu.skeleton";

export const RootOverviewTabSkeleton = () => {
	return (
		<TabWrapper>
			<FocusContentMenuSkeleton />
		</TabWrapper>
	);
};
