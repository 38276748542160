/**
 * @generated SignedSource<<1d7793ab4d6d65afab2f9f32954e3042>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type imageLearnElement_ImageLearnElementFragment$data = {
  readonly file: {
    readonly url: string | null;
  } | null;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "imageLearnElement_ImageLearnElementFragment";
};
export type imageLearnElement_ImageLearnElementFragment$key = {
  readonly " $data"?: imageLearnElement_ImageLearnElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"imageLearnElement_ImageLearnElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "imageLearnElement_ImageLearnElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "file",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FileLearnElement",
  "abstractKey": null
};

(node as any).hash = "be94a28e64276a3e004e474bb135cd30";

export default node;
