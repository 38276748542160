import { graphql } from "react-relay";

export const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment noteSourceElementRenderer_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						id
						title
						elementType
						...orderLearnElement_OrderLearnElementFragment
						...textLearnElement_EnhancedTextLearnElementFragment
						...imageLearnElement_ImageLearnElementFragment
						...videoLearnElement_VideoLearnElementFragment
						...clozeTextLearnElement_ClozeTextLearnElementFragment
						...matrixLearnElementFragment_MatrixLearnElementFragment
						...PodcastWithTimestamp_PodcastWithTimestampElementFragment
						...markMistakesLearnElement_MarkMistakesLearnElementFragment
						...multipleChoiceLearnElement_MultipleChoiceLearnElementFragment
					}
				}
			}
		}
	}
`;

export const LEARN_ELEMENT_FRAGMENT = graphql`
	fragment noteSourceElementRenderer_ElementFragment on LearnElement {
		id
		title
		elementType
		...orderLearnElement_OrderLearnElementFragment
		...textLearnElement_EnhancedTextLearnElementFragment
		...imageLearnElement_ImageLearnElementFragment
		...videoLearnElement_VideoLearnElementFragment
		...clozeTextLearnElement_ClozeTextLearnElementFragment
		...matrixLearnElementFragment_MatrixLearnElementFragment
		...markMistakesLearnElement_MarkMistakesLearnElementFragment
		...multipleChoiceLearnElement_MultipleChoiceLearnElementFragment
		...PodcastWithTimestamp_PodcastWithTimestampElementFragment
	}
`;
