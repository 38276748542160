import { H3Span } from "@thekeytechnology/epic-ui";
import { FC } from "react";
import { readInlineData, useFragment } from "react-relay";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { ReminderCard } from "@components/reminder-card";
import { reminderOverview_ReminderCardInlineFragment$key } from "@relay/reminderOverview_ReminderCardInlineFragment.graphql";
import { info100, onBrandMain } from "@themes/colors";
import { NOTE_CONNECTION_FRAGMENT, NOTE_INLINE_FRAGMENT } from "./reminder-overview.graphql";
import { listClass, listWrapperClass, wrapperClass } from "./reminder-overview.style";
import { ReminderOverviewProps } from "./reminder-overview.types";

export const ReminderOverviewComponent: FC<ReminderOverviewProps> = ({
	onDeleteReminder,
	reminderFragmentRef,
}) => {
	const query = useFragment(NOTE_CONNECTION_FRAGMENT, reminderFragmentRef);

	const reminders = query?.edges?.reduce<{
		complete: typeof query.edges;
		incomplete: typeof query.edges;
	}>(
		(acc, edge) => {
			const data = readInlineData<reminderOverview_ReminderCardInlineFragment$key>(
				NOTE_INLINE_FRAGMENT,
				edge!.node,
			);

			if (data?.reminder === null) return acc;

			if (data.reminder.isCompleted) {
				return {
					complete: [...acc.complete, edge],
					incomplete: acc.incomplete,
				};
			}

			return {
				complete: acc.complete,
				incomplete: [...acc.incomplete, edge],
			};
		},
		{
			complete: [],
			incomplete: [],
		},
	);

	const isEmpty = reminders?.complete.length === 0 && reminders?.incomplete.length === 0;

	return (
		<EmptyPlaceholder
			isVisible={isEmpty}
			iconName="email"
			iconColor={info100}
			iconBackgroundColor={onBrandMain}
			title="Hier findest du deine Erinnerungen"
			subtitle="Du kannst jederzeit Erinnerungen erstellen und einsehen. Du wirst dann nach Bedarf auf der Plattform und via Mail erinnert."
		>
			<div className={wrapperClass}>
				<div className={listWrapperClass}>
					<H3Span>Erinnerungen</H3Span>
					<div className={listClass}>
						{reminders?.incomplete.map((note) => (
							<ReminderCard
								key={note?.node.id}
								onDeleteReminder={onDeleteReminder}
								noteFragmentRef={note?.node}
							/>
						))}
					</div>
				</div>
				<div className={listWrapperClass}>
					<H3Span>Erledigt</H3Span>
					<div className={listClass}>
						{reminders?.complete.map((note) => (
							<ReminderCard
								key={note?.node.id}
								onDeleteReminder={onDeleteReminder}
								noteFragmentRef={note?.node}
							/>
						))}
					</div>
				</div>
			</div>
		</EmptyPlaceholder>
	);
};
