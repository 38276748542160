import { css } from "@styled-system/css";

export const wrapperClass = css({
	flex: "1",
	flexDirection: "column",
	paddingBottom: "0",
	maxHeight: "full",
	xl: {
		height: "full",
	},
});

export const titleClass = css({
	color: "shade.100",
});
