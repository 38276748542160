import {
	DialogTemplate,
	DialogButtonsDirection,
} from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { Divider } from "@thekeytechnology/academies-lib-webapp/components/divider";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { hasLicenseAttributes, needsLicenseAttributes } from "./license-modal.consts";
import { Wrapper } from "./license-modal.styles";
import { LicenseModalProps } from "./license-modal.types";
import { IconHeadlineButtonTemplate } from "../icon-headline-button-template";

export const LicenseModal = ({
	isVisible = false,
	onDismiss,
	onBuyClick,
	variant = "needsLicense",
}: LicenseModalProps) => {
	const props = variant === "needsLicense" ? needsLicenseAttributes : hasLicenseAttributes;
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={onDismiss}>
			<DialogTemplate
				buttonsDirection={DialogButtonsDirection.COLUMN}
				secondaryButtonLabel={props.secondaryButtonLabel}
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel={props.primaryButtonLabel}
				primaryButtonIconName={props.primaryButtonIconName}
				onPrimaryButtonClick={onBuyClick}
			>
				<Wrapper>
					<IconHeadlineButtonTemplate
						iconName={props.iconName}
						iconColor={props.iconColor}
						iconBackgroundColor={props.iconBackgroundColor}
						title={props.title}
						subtitle={props.subtitle}
					/>
					<Divider />
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
