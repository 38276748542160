import { graphql } from "react-relay";

export const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment eLearningContentOverview_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		... on LearnOpportunityV2 {
			instructorsV2 {
				...instructorsComponent_InstructorsConnectionFragment
			}
			indexPath
			...attachmentsCollection_AttachmentsCollectionFragment
			...contentElementsCollection_LearnOpportunityV2Fragment
			attachmentsV2 {
				id
			}
			root {
				structureDefinition {
					... on LearnOpportunityRootStructureDefinition {
						viewerTreeState {
							kind
							... on IsStartedViewerTreeState {
								headContentId
							}
						}
						extension {
							... on PublishedRootExtensionImpl {
								unlockInfo {
									kind
									... on UnlockInfoDemo {
										firstContentId
									}
								}
							}
						}
					}
				}
				typeDefinition {
					... on LearnOpportunityBranchTypeDefinition {
						children {
							typeDefinition {
								... on LearnOpportunityBranchTypeDefinition {
									children {
										id
										indexPath
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
