/**
 * @generated SignedSource<<e334c66f80317d41949e8adb27201412>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementStateKind = "Input" | "InputAndIsCorrect" | "ShowAnswer" | "Untouched" | "UntouchedAndPreviouslyIncorrect";
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "podcast" | "podcastWithTimestamp" | "text" | "video";
import { FragmentRefs } from "relay-runtime";
export type MarkMistakesElement_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly currentElementState?: {
      readonly checkedMistakeIndices?: ReadonlyArray<number>;
      readonly correctMistakeIndices?: ReadonlyArray<number>;
      readonly element: {
        readonly elementType: ElementTypeV2;
        readonly id: string;
        readonly snippets?: ReadonlyArray<{
          readonly content: string;
        }>;
        readonly title: string;
        readonly " $fragmentSpreads": FragmentRefs<"markMistakesLearnElement_MarkMistakesLearnElementFragment">;
      };
      readonly inputElementState?: {
        readonly checkedMistakeIndices?: ReadonlyArray<number>;
      };
      readonly isCorrect?: boolean;
      readonly kind: ElementStateKind;
      readonly lastCheckedMistakeIndices?: ReadonlyArray<number> | null;
    } | null;
  };
  readonly id: string;
  readonly " $fragmentType": "MarkMistakesElement_ContentSubmissionFragment";
};
export type MarkMistakesElement_ContentSubmissionFragment$key = {
  readonly " $data"?: MarkMistakesElement_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MarkMistakesElement_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkedMistakeIndices",
      "storageKey": null
    }
  ],
  "type": "MarkMistakesInputElementState",
  "abstractKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MarkMistakesElement_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCorrect",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "inputElementState",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "InputAndIsCorrectElementState",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "correctMistakeIndices",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastCheckedMistakeIndices",
                      "storageKey": null
                    }
                  ],
                  "type": "MarkMistakesShowAnswerElementState",
                  "abstractKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "elementType",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "MarkMistakes_Snippet",
                          "kind": "LinkedField",
                          "name": "snippets",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "content",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "MarkMistakesLearnElement",
                      "abstractKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "markMistakesLearnElement_MarkMistakesLearnElementFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "b532a19109a0893077d7a8c12c5bad95";

export default node;
