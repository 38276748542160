import { ButtonSkeleton } from "@thekeytechnology/academies-lib-webapp/components/button";
import { DividerList } from "@thekeytechnology/academies-lib-webapp/components/divider-list";
import { Skeleton } from "@thekeytechnology/epic-ui";
import {
	AvatarWrapper,
	CurrentUserWrapper,
	MenuWrapper,
	PointsWrapper,
} from "./profile-menu.styled";
import { ChipSkeleton } from "../chip/chip.skeleton";
import { ProfileMenuRowSkeleton } from "../profile-menu-row/profile-menu-row.skeleton";

// TODO: add-translations
export const ProfileMenuSkeleton = () => {
	return (
		<MenuWrapper>
			<CurrentUserWrapper>
				<AvatarWrapper>
					<Skeleton width="5rem" height="5rem" />
					<PointsWrapper>
						<ChipSkeleton />
					</PointsWrapper>
				</AvatarWrapper>
				<Skeleton height="2rem" width="15rem" />
			</CurrentUserWrapper>
			<ButtonSkeleton fillParent />
			<DividerList hasDivider gapRem={1}>
				<ProfileMenuRowSkeleton />
				<ProfileMenuRowSkeleton />
				<ProfileMenuRowSkeleton />
				<ProfileMenuRowSkeleton />
				<ProfileMenuRowSkeleton />
			</DividerList>
		</MenuWrapper>
	);
};
