import { useFragment } from "react-relay";
import { messagePreviewCard_MessageFragment$key } from "@relay/messagePreviewCard_MessageFragment.graphql";
import { MESSAGE_FRAGMENT } from "@screens/messages/parts/message-preview-card/message-preview-card.graphql";
import {
	messageImageClass,
	messagePreviewCardClass,
	unreadIndicatorInnerClass,
	unreadIndicatorOuterClass,
} from "@screens/messages/parts/message-preview-card/message-preview-card.styles";
import { MessagePreviewCardProps } from "@screens/messages/parts/message-preview-card/message-preview-card.types";

export const MessagePreviewCard = ({ messageFragmentRef, onClick }: MessagePreviewCardProps) => {
	const message = useFragment<messagePreviewCard_MessageFragment$key>(
		MESSAGE_FRAGMENT,
		messageFragmentRef,
	);
	const firstPage = message.messageDefinition.pages[0];
	const hasSeen = message.hasSeen;
	const firstPageImageUrl = firstPage.data.element?.image.url;

	const handleOnClick = () => {
		onClick(message);
	};

	if (!firstPageImageUrl) return null;

	return (
		<div className={messagePreviewCardClass} onClick={handleOnClick}>
			{firstPageImageUrl && (
				<>
					{!hasSeen && (
						<div className={unreadIndicatorOuterClass}>
							<div className={unreadIndicatorInnerClass} />
						</div>
					)}
					<img className={messageImageClass} src={firstPageImageUrl} alt={""} />
				</>
			)}
		</div>
	);
};
