import { Skeleton } from "@thekeytechnology/epic-ui";
import { useTheme } from "styled-components";

export const ChipSkeleton = () => {
	const theme = useTheme();

	return (
		<Skeleton width="3rem" borderRadius={theme.borderRadius?.borderRadius16} height="1.5rem" />
	);
};
