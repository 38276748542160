import { LabelSkeleton } from "@thekeytechnology/academies-lib-webapp/components/label";
import { ProgressBar } from "@thekeytechnology/academies-lib-webapp/components/progress-bar";
import { Skeleton } from "@thekeytechnology/epic-ui";
import { ImageWidth, ProgressUnit } from "./root-progress.const";
import { ImageTitle, MainWrapper, TaglineTitle } from "./root-progress.styles";

export const RootProgressSkeleton = () => {
	const progressValue = 0;
	return (
		<MainWrapper>
			<ImageTitle>
				<div>
					<Skeleton width={ImageWidth} height="100%" />
				</div>
				<TaglineTitle>
					<LabelSkeleton />
					<Skeleton width="10rem" />
				</TaglineTitle>
			</ImageTitle>
			<ProgressBar
				value={progressValue}
				colorVersion={"profile"}
				labelRight={`${progressValue} ${ProgressUnit}`}
			/>
		</MainWrapper>
	);
};
