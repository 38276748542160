import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { MultiDialogFlowContainer } from "@thekeytechnology/academies-lib-webapp/containers/multi-dialog-flow-container";
import { useContext, useEffect, useState } from "react";
import { useFragment, useMutation, useQueryLoader } from "react-relay";
import { useNavigate } from "react-router-dom";
// import { ProgressBar } from "@components/ui/progress-bar";
import { contentSubmissionNavigation_ContentSubmissionFragment$key } from "@relay/contentSubmissionNavigation_ContentSubmissionFragment.graphql";
import { contentSubmissionNavigation_RestartContentNodeAfterFailedMutation } from "@relay/contentSubmissionNavigation_RestartContentNodeAfterFailedMutation.graphql";
import { contentSubmissionNavigation_RestartContentNodeAfterPassedMutation } from "@relay/contentSubmissionNavigation_RestartContentNodeAfterPassedMutation.graphql";
import { Path } from "@router/paths";
import {
	CONTENT_SUBMISSION_FRAGMENT,
	QUERY,
	RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION,
	RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION,
} from "./content-submission-navigation.graphql";
import { ContentSubmissionNavigationProps } from "./content-submission-navigation.interface";
import {
	ButtonsWrapper,
	ContentWrapper,
	Progress,
	ProgressWrapper,
	Wrapper,
} from "./content-submission-navigation.styles";
import { VOCAB_CONTENT_NODE_ID } from "../../consts";
import { CanOrderIHKModal } from "../can-order-ihk-modal";
import { ContentSubmissionModalContext } from "../content-submission-modal-context-provider";
import { GoToNextElementButton } from "../go-to-next-element-button";
import { GoToPreviousElementButton } from "../go-to-previous-element-button";
import { LicenseModal } from "../license-modal";
import { OrderIhkFlow } from "../order-ihk-flow";

// TODO: add-translations

export const ContentSubmissionNavigation = ({
	contentSubmissionFragmentRef,
	...props
}: ContentSubmissionNavigationProps) => {
	const contentSubmission =
		useFragment<contentSubmissionNavigation_ContentSubmissionFragment$key>(
			CONTENT_SUBMISSION_FRAGMENT,
			contentSubmissionFragmentRef,
		);

	const [restartContentNodeAfterPassed, loadingRestartAfterPassed] =
		useMutation<contentSubmissionNavigation_RestartContentNodeAfterPassedMutation>(
			RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION,
		);

	const [restartContentNodeAfterFailed, loadingRestartAfterFailed] =
		useMutation<contentSubmissionNavigation_RestartContentNodeAfterFailedMutation>(
			RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION,
		);

	const [_, loadQuery] = useQueryLoader(QUERY);

	const navigate = useNavigate();

	const { backButtonHidden } = useContext(ContentSubmissionModalContext);

	const rootExtension = contentSubmission.learnOpportunity?.root?.structureDefinition.extension;

	const [modalIsVisible, setModalIsVisible] = useState(false);
	const [licenseModalShowed, setLicenseModalShowed] = useState(false);

	const [isOrderIHKModalVisible, setIsOderIHKModalVisible] = useState(false);
	const [orderIHKModalShowed, setOrderIHKModalShowed] = useState(
		rootExtension?.ihkState === "ReadyToOrder",
	);

	const contentId = contentSubmission.learnOpportunity?.id;
	const rootId = contentSubmission.learnOpportunity?.root?.id;

	const cartLink = rootExtension?.cartClickout?.relativeLink;
	const isFinishedRoot =
		contentSubmission.learnOpportunity?.root?.structureDefinition.viewerTreeState?.kind ===
		"IsFinished";

	const progressBarValue = contentSubmission.definition.progress?.percentage;

	const nextContentNode = contentSubmission.learnOpportunity?.nextContentNodeId;
	const hasNextContentNode = !!nextContentNode;
	const failedContentNode = contentSubmission.definition.status === "failed";
	const passedContentNode = contentSubmission.definition.status === "passed";
	const finishedContentNode = passedContentNode || failedContentNode;
	const contentNodeStatus =
		contentSubmission.learnOpportunity?.typeDefinition.contentNodeAdvancementResult?.status;
	const canBeRestartedAfterFailed = contentNodeStatus === "CanBeRestartedAfterFailed";
	const canBeRestartedAfterPassed = contentNodeStatus === "CanBeRestartedAfterPassed";

	const isVocabContent = contentSubmission.learnOpportunity?.id === VOCAB_CONTENT_NODE_ID;

	const canBeRestartet = canBeRestartedAfterFailed || canBeRestartedAfterPassed;

	const hasFullAccess = rootExtension?.unlockInfo?.kind === "FullAccess";
	const nextContentNodeNeedsLicense =
		contentSubmission.learnOpportunity?.nextContentNode?.typeDefinition.extension
			?.isUnlockNecessaryToStart && !hasFullAccess;

	const canOrderIHK = rootExtension?.ihkState === "ReadyToOrder";

	const handleFinishOnClick = () => {
		if (isVocabContent) {
			return navigate(Path.profileMenu.vocabulary.path);
		}
		if (!rootId) return;
		if (canOrderIHK && !orderIHKModalShowed) {
			setIsOderIHKModalVisible(true);
			setOrderIHKModalShowed(true);
			return;
		}
		if (nextContentNodeNeedsLicense && !licenseModalShowed && passedContentNode) {
			setModalIsVisible(true);
			setLicenseModalShowed(true);
			return;
		}
		if (contentId && (failedContentNode || !hasNextContentNode)) {
			return navigate(Path.root.withId(rootId).branchNodes.withId(contentId).path, {
				replace: true,
			});
		}
		if (hasNextContentNode) {
			navigate(Path.root.withId(rootId).branchNodes.withId(nextContentNode).path, {
				replace: true,
			});
		}
	};

	const handleRepeatOnClick = () => {
		if (!contentId) return;
		if (canBeRestartedAfterFailed) {
			restartContentNodeAfterFailed({
				variables: {
					input: {
						contentNodeId: contentId,
					},
				},
				onCompleted: (response) => {
					const id = response.LearnV2.restartContentNodeAfterFailed?.contentSubmission.id;
					id && navigate(Path.contentSubmission.withId(id).path, { replace: true });
				},
			});
		} else if (canBeRestartedAfterPassed) {
			restartContentNodeAfterPassed({
				variables: {
					input: {
						contentNodeId: contentId,
					},
				},
				onCompleted: (response) => {
					const id = response.LearnV2.restartContentNodeAfterPassed?.contentSubmission.id;
					id && navigate(Path.contentSubmission.withId(id).path, { replace: true });
				},
			});
		}
	};

	const handleModalOnDismiss = () => {
		setModalIsVisible(false);
		return rootId && navigate(Path.root.withId(rootId).overview.path);
	};

	const handleOderIHKOnDismiss = () => {
		setIsOderIHKModalVisible(false);
	};

	const handleBuyLicenseOnClick = () => {
		setModalIsVisible(false);
		cartLink && navigate(cartLink);
	};

	useEffect(() => {
		if (finishedContentNode) {
			loadQuery({}, { fetchPolicy: "store-and-network" });
		}
	}, [finishedContentNode]);

	const finishedContentNodeButtonLabel = failedContentNode
		? "Zur Übersicht"
		: isVocabContent
		? "Fertig"
		: "Nächste Lektion";

	const finishedButtonLabel =
		!hasNextContentNode && isFinishedRoot ? "Abschließen" : finishedContentNodeButtonLabel;

	const isLoadingRestart = loadingRestartAfterPassed || loadingRestartAfterFailed;
	return (
		<Wrapper {...props}>
			{/* <ProgressBar colorVersion={"course"} value={progressBarValue || 0} /> */}
			<ProgressWrapper>
				<Progress prgs={progressBarValue?.toString() || "0"} />
			</ProgressWrapper>
			<ContentWrapper>
				<ButtonsWrapper>
					{finishedContentNode ? (
						<>
							{canBeRestartet && (
								<Button
									loading={isLoadingRestart}
									iconName="synchronizeArrowClock"
									colorVersion="tertiary"
									onClick={handleRepeatOnClick}
								/>
							)}
							<Button
								disabled={isLoadingRestart}
								label={finishedButtonLabel}
								iconName="arrowRight"
								onClick={handleFinishOnClick}
							/>
						</>
					) : (
						<>
							{!backButtonHidden && (
								<GoToPreviousElementButton
									contentSubmissionFragmentRef={contentSubmission}
								/>
							)}
							<GoToNextElementButton
								contentSubmissionFragmentRef={contentSubmission}
							/>
						</>
					)}
				</ButtonsWrapper>
				<LicenseModal
					isVisible={modalIsVisible}
					onDismiss={handleModalOnDismiss}
					onBuyClick={handleBuyLicenseOnClick}
					variant="needsLicense"
				/>
				{rootId && (
					<MultiDialogFlowContainer
						isVisible={isOrderIHKModalVisible}
						onDismiss={handleOderIHKOnDismiss}
					>
						{({ onNext, onCancel, onClose }) => (
							<>
								<CanOrderIHKModal
									isVisible
									onAccept={onNext}
									onDismiss={onCancel}
								/>
								<OrderIhkFlow rootId={rootId} isVisible onDismiss={onClose} />
							</>
						)}
					</MultiDialogFlowContainer>
				)}
			</ContentWrapper>
		</Wrapper>
	);
};
