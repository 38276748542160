import { ScrollPanel } from "primereact/scrollpanel";
import styled from "styled-components";
import tw from "twin.macro";
import { TkaP3Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	relative
	w-full	
	h-full
	flex
	flex-col
	overflow-y-hidden
`;

export const ContentWrapper = tw.div`
	flex-1
	flex
	flex-col
	overflow-y-scroll
`;

export const TitleWrapper = tw.div``;

export const Spacing12 = tw.div`h-12`;
export const Spacing40 = tw.div`h-40`;

export const FixedBottomWrapper = styled.div<{ bottomPx: number }>`
	${tw`
		xl:hidden
		fixed
		w-full
		z-20
	`}
	bottom: ${({ bottomPx }) => bottomPx}px;
`;

export const RelativeBottomWrapper = tw.div`
	invisible
	xl:visible
`;

export const AnswersComponentWrapper = tw.div`
	bg-shade-10 
	p-24
	pb-8
	flex 
	flex-col 
	gap-12
`;

export const StyledScrollPanel = styled(ScrollPanel)`
	&.p-scrollpanel .p-scrollpanel-content {
		${tw`
			no-scrollbar
			flex
			flex-row
			flex-nowrap
			items-start
			gap-8
		`};
	}
`;

export const AnswersTitle = tw(TkaP3Span)``;
