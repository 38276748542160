/**
 * @generated SignedSource<<98806d941dd858f81a14d770c289983c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RootProgress_LearnOpportunityV2Fragment$data = {
  readonly image: {
    readonly url: string | null;
  } | null;
  readonly structureDefinition: {
    readonly extension?: {
      readonly cartClickout?: {
        readonly netPrice: number;
      } | null;
      readonly receivedGamificationPoints?: number;
      readonly reducedData?: {
        readonly receivableGamificationPoints: number;
      } | null;
    };
    readonly progressPercentage?: number | null;
    readonly title: string;
  };
  readonly " $fragmentType": "RootProgress_LearnOpportunityV2Fragment";
};
export type RootProgress_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: RootProgress_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RootProgress_LearnOpportunityV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RootProgress_LearnOpportunityV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "progressPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "extension",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "receivedGamificationPoints",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ReducedData",
                      "kind": "LinkedField",
                      "name": "reducedData",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "receivableGamificationPoints",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CartClickout",
                      "kind": "LinkedField",
                      "name": "cartClickout",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "netPrice",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "PublishedRootExtensionImpl",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "LearnOpportunityRootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LearnOpportunityV2",
  "abstractKey": null
};

(node as any).hash = "4fadb7e7dcadbd0f0ddc2ddeebb58896";

export default node;
