import { BranchNodesTreeSkeleton } from "@thekeytechnology/academies-lib-webapp/components/branch-nodes-tree";
import { TabWrapper } from "./branch-nodes-tab.styles";

export const BranchNodeTabSkeleton = () => {
	return (
		<TabWrapper>
			<BranchNodesTreeSkeleton />
		</TabWrapper>
	);
};
