import { graphql } from "react-relay";

export const CLOZE_TEXT_LEARN_ELEMENT_FRAGMENT = graphql`
	fragment clozeTextLearnElement_ClozeTextLearnElementFragment on ClozeTextLearnElement {
		id
		title
		parts {
			kind
			id
			... on TextPart {
				text
			}
		}
		shuffledAnswers {
			text
			id
		}
	}
`;
