import { MaterialCardSkeleton } from "@thekeytechnology/academies-lib-webapp/components/material-card";
import { MainWrapper } from "./material-cards-collection.styles";

export const MaterialCardsCollectionSkeleton = ({ rows = 2 }: { rows?: number }) => {
	return (
		<MainWrapper rows={rows}>
			<MaterialCardSkeleton />
			<MaterialCardSkeleton />
			<MaterialCardSkeleton />
			<MaterialCardSkeleton />
		</MainWrapper>
	);
};
