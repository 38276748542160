import { graphql } from "react-relay";

export const NOTE_CONNECTION_FRAGMENT = graphql`
	fragment reminderOverview_ReminderQueryFragment on NoteConnection {
		edges {
			node {
				id
				...reminderCard_ReminderNoteFragment
				...reminderOverview_ReminderCardInlineFragment
			}
		}
	}
`;

export const NOTE_INLINE_FRAGMENT = graphql`
	fragment reminderOverview_ReminderCardInlineFragment on Note @inline {
		id
		content
		reminder {
			kind
			isCompleted
			remindAt
		}
	}
`;
