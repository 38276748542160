/**
 * @generated SignedSource<<41c852c24eb812076f76e3cd4cacb19d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "podcast" | "podcastWithTimestamp" | "text" | "video";
import { FragmentRefs } from "relay-runtime";
export type noteSourceElementRenderer_ElementFragment$data = {
  readonly elementType: ElementTypeV2;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"PodcastWithTimestamp_PodcastWithTimestampElementFragment" | "clozeTextLearnElement_ClozeTextLearnElementFragment" | "imageLearnElement_ImageLearnElementFragment" | "markMistakesLearnElement_MarkMistakesLearnElementFragment" | "matrixLearnElementFragment_MatrixLearnElementFragment" | "multipleChoiceLearnElement_MultipleChoiceLearnElementFragment" | "orderLearnElement_OrderLearnElementFragment" | "textLearnElement_EnhancedTextLearnElementFragment" | "videoLearnElement_VideoLearnElementFragment">;
  readonly " $fragmentType": "noteSourceElementRenderer_ElementFragment";
};
export type noteSourceElementRenderer_ElementFragment$key = {
  readonly " $data"?: noteSourceElementRenderer_ElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"noteSourceElementRenderer_ElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "noteSourceElementRenderer_ElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "orderLearnElement_OrderLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "textLearnElement_EnhancedTextLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "imageLearnElement_ImageLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "videoLearnElement_VideoLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "clozeTextLearnElement_ClozeTextLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "matrixLearnElementFragment_MatrixLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "markMistakesLearnElement_MarkMistakesLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "multipleChoiceLearnElement_MultipleChoiceLearnElementFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PodcastWithTimestamp_PodcastWithTimestampElementFragment"
    }
  ],
  "type": "LearnElement",
  "abstractKey": "__isLearnElement"
};

(node as any).hash = "78ee53384fae54f489cbf02ca536b744";

export default node;
