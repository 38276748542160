/**
 * @generated SignedSource<<e419d23b2db0429daf138816f90026e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ClozeTextPartKind = "cloze" | "text";
export type ElementStateKind = "Input" | "InputAndIsCorrect" | "ShowAnswer" | "Untouched" | "UntouchedAndPreviouslyIncorrect";
import { FragmentRefs } from "relay-runtime";
export type ClozeTextElement_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly currentElementState?: {
      readonly checkedAnswers?: ReadonlyArray<string>;
      readonly correctAnswers?: ReadonlyArray<string>;
      readonly element: {
        readonly id?: string;
        readonly parts?: ReadonlyArray<{
          readonly id: string;
          readonly kind: ClozeTextPartKind;
          readonly text?: string;
        }>;
        readonly shuffledAnswers?: ReadonlyArray<{
          readonly id: string;
          readonly text: string;
        }>;
        readonly title?: string;
        readonly " $fragmentSpreads": FragmentRefs<"clozeTextLearnElement_ClozeTextLearnElementFragment">;
      };
      readonly inputElementState?: {
        readonly checkedAnswers?: ReadonlyArray<string>;
      };
      readonly isCorrect?: boolean;
      readonly kind: ElementStateKind;
      readonly lastCheckedAnswers?: ReadonlyArray<string>;
    } | null;
  };
  readonly id: string;
  readonly " $fragmentType": "ClozeTextElement_ContentSubmissionFragment";
};
export type ClozeTextElement_ContentSubmissionFragment$key = {
  readonly " $data"?: ClozeTextElement_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClozeTextElement_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkedAnswers",
      "storageKey": null
    }
  ],
  "type": "ClozeTextInputElementState",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClozeTextElement_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCorrect",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "inputElementState",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "InputAndIsCorrectElementState",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "correctAnswers",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastCheckedAnswers",
                      "storageKey": null
                    }
                  ],
                  "type": "ClozeTextShowAnswerElementState",
                  "abstractKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "parts",
                          "plural": true,
                          "selections": [
                            (v1/*: any*/),
                            (v0/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v3/*: any*/)
                              ],
                              "type": "TextPart",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ClozeAnswer",
                          "kind": "LinkedField",
                          "name": "shuffledAnswers",
                          "plural": true,
                          "selections": [
                            (v3/*: any*/),
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "clozeTextLearnElement_ClozeTextLearnElementFragment"
                        }
                      ],
                      "type": "ClozeTextLearnElement",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "d8d2a2424eee880800c400755eea063f";

export default node;
