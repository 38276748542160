/**
 * @generated SignedSource<<8a9c1f0141b09d9572b37f719c98e320>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type notes_NotesFragment$data = {
  readonly Note: {
    readonly Note: {
      readonly " $fragmentSpreads": FragmentRefs<"notesOverview_NotesQueryFragment" | "reminderOverview_ReminderQueryFragment">;
    };
  };
  readonly " $fragmentType": "notes_NotesFragment";
};
export type notes_NotesFragment$key = {
  readonly " $data"?: notes_NotesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"notes_NotesFragment">;
};

import notes_RefetchQuery_graphql from './notes_RefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByText"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeNoteColors"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeNoteSourceKinds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "noteColorToSortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": notes_RefetchQuery_graphql
    }
  },
  "name": "notes_NotesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NoteQueries",
      "kind": "LinkedField",
      "name": "Note",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterByText",
              "variableName": "filterByText"
            },
            {
              "kind": "Variable",
              "name": "includeNoteColors",
              "variableName": "includeNoteColors"
            },
            {
              "kind": "Variable",
              "name": "includeNoteSourceKinds",
              "variableName": "includeNoteSourceKinds"
            },
            {
              "kind": "Variable",
              "name": "noteColorToSortBy",
              "variableName": "noteColorToSortBy"
            }
          ],
          "concreteType": "NoteConnection",
          "kind": "LinkedField",
          "name": "Note",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "notesOverview_NotesQueryFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "reminderOverview_ReminderQueryFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2f86835e9d66cb04047cba4860457f99";

export default node;
