import { ImageComponentWithLightbox } from "@thekeytechnology/academies-lib-webapp/components/image-with-lightbox";
import { useFragment } from "react-relay";
import { FILE_LEARN_ELEMENT_FRAGMENT } from "./image-learn-element.graphql";
import type { ImageLearnElementProps } from "./image-learn-element.types";

export const ImageLearnElement = ({ learnElementFragmentRef }: ImageLearnElementProps) => {
	const element = useFragment(FILE_LEARN_ELEMENT_FRAGMENT, learnElementFragmentRef ?? null);

	const url = element?.file?.url;

	if (!url) return null;
	return <ImageComponentWithLightbox src={url} alt={element.title} />;
};
