import { AuthTemplate } from "@thekeytechnology/academies-lib-webapp/components/auth-template";
import { SignUpSteps } from "@thekeytechnology/academies-lib-webapp/screens/sign-up";
import { LoginMarketing } from "../login/parts/login-marketing";

export const SignUpScreen = () => {
	return (
		<AuthTemplate rightSide={<LoginMarketing />}>
			<SignUpSteps />
		</AuthTemplate>
	);
};
