/**
 * @generated SignedSource<<45c4d0e8f8a28424f04e6ebaef371e62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NoteColor = "blue" | "default" | "green" | "red" | "yellow";
export type NoteReminderKind = "NonRecurring" | "Recurring";
export type ReminderIntervalKind = "Daily" | "Monthly" | "Weekly";
export type EditNoteInput = {
  clientMutationId?: string | null;
  color?: NoteColor | null;
  content?: string | null;
  noteId: string;
  reminder?: NoteReminderInput | null;
};
export type NoteReminderInput = {
  NonRecurring?: NonRecurringNoteReminderInput | null;
  Recurring?: RecurringNoteReminderInput | null;
};
export type RecurringNoteReminderInput = {
  interval: ReminderIntervalInput;
  isCompleted: boolean;
  kind: NoteReminderKind;
  remindAt: string;
  remindUntil?: string | null;
};
export type ReminderIntervalInput = {
  Daily?: DailyIntervalInput | null;
  Monthly?: MonthlyIntervalInput | null;
  Weekly?: WeeklyIntervalInput | null;
};
export type DailyIntervalInput = {
  kind: ReminderIntervalKind;
  value: number;
};
export type WeeklyIntervalInput = {
  kind: ReminderIntervalKind;
  value: number;
};
export type MonthlyIntervalInput = {
  kind: ReminderIntervalKind;
  value: number;
};
export type NonRecurringNoteReminderInput = {
  isCompleted: boolean;
  kind: NoteReminderKind;
  remindAt: string;
};
export type reminderForm_editNoteMutation$variables = {
  input: EditNoteInput;
};
export type reminderForm_editNoteMutation$data = {
  readonly Note: {
    readonly editNote: {
      readonly note: {
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"reminderForm_QueryFragment">;
        };
      };
    } | null;
  };
};
export type reminderForm_editNoteMutation = {
  response: reminderForm_editNoteMutation$data;
  variables: reminderForm_editNoteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reminderForm_editNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NoteMutations",
        "kind": "LinkedField",
        "name": "Note",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditNotePayload",
            "kind": "LinkedField",
            "name": "editNote",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NoteEdge",
                "kind": "LinkedField",
                "name": "note",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Note",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "reminderForm_QueryFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reminderForm_editNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NoteMutations",
        "kind": "LinkedField",
        "name": "Note",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "EditNotePayload",
            "kind": "LinkedField",
            "name": "editNote",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NoteEdge",
                "kind": "LinkedField",
                "name": "note",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Note",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "reminder",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "remindAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCompleted",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc663e8abe89933663ed1372ddc4ff25",
    "id": null,
    "metadata": {},
    "name": "reminderForm_editNoteMutation",
    "operationKind": "mutation",
    "text": "mutation reminderForm_editNoteMutation(\n  $input: EditNoteInput!\n) {\n  Note {\n    editNote(input: $input) {\n      note {\n        node {\n          ...reminderForm_QueryFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment reminderForm_QueryFragment on Note {\n  id\n  content\n  reminder {\n    __typename\n    kind\n    remindAt\n    isCompleted\n  }\n}\n"
  }
};
})();

(node as any).hash = "b85fb102d235d6314c135dcf852039b3";

export default node;
