import { OfferCard } from "@thekeytechnology/academies-lib-webapp/components/offer-card";
import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import React, { useContext, useMemo } from "react";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { PUBLISHED_COURSE_LEARNABLE_FRAGMENT } from "@components/educational-offer-card/educational-offer-card.graphql";
import { educationalOfferCard_PublishedCourseLearnableFragment$key } from "@relay/educationalOfferCard_PublishedCourseLearnableFragment.graphql";
import { Path } from "@router/paths";
import { EducationalOfferCardProps } from "./educational-offer-card.types";
import { OffersScreenContext } from "../../screens/offers/offers.context";

export const EducationalOfferCard = ({
	publishedCourseLearnableFragmentRef,
}: EducationalOfferCardProps) => {
	const learnable = useFragment<educationalOfferCard_PublishedCourseLearnableFragment$key>(
		PUBLISHED_COURSE_LEARNABLE_FRAGMENT,
		publishedCourseLearnableFragmentRef,
	);

	const { productIds } = useContext(OffersScreenContext);

	const navigate = useNavigate();
	const handleOnClick = () => {
		if (!learnable?.root?.id) return;
		navigate(Path.root.withId(learnable!.root!.id).path, {
			state: { productIds: productIds },
		});
	};
	const shortDescription = useMemo(
		() => stripHtml(learnable.root?.shortDescription ?? ""),
		[learnable.root?.shortDescription],
	);

	const tags = useMemo(
		() => learnable.root?.structureDefinition.extension?.tags?.map((t) => t.data.name),
		[learnable.root?.structureDefinition.extension?.tags],
	);
	if (learnable.kind !== "Course") return <React.Fragment />;
	const progress = learnable.root?.structureDefinition?.progressPercentage || 0;

	const isInDemo = learnable.root?.structureDefinition?.extension?.unlockInfo?.kind === "Demo";

	const needsUnlockNextNode =
		learnable.root?.structureDefinition.viewerTreeState?.headContentId ===
			learnable.root?.structureDefinition.extension?.unlockInfo?.firstContentId && isInDemo;

	const availableLicensesCount = 0; // learnable.root?.structureDefinition.extension?.licenseAvailability?.numAvailable ?? 0;

	const showLicensesCount = !needsUnlockNextNode && availableLicensesCount;
	return (
		<OfferCard
			src={learnable.root?.image?.url ?? ""}
			title={learnable.root?.structureDefinition.title ?? ""}
			shortDescription={shortDescription}
			tags={tags}
			progress={progress}
			isLocked={needsUnlockNextNode}
			{...(showLicensesCount ? { availableLicenseCount: availableLicensesCount } : {})}
			showLicenseNeeded={needsUnlockNextNode}
			diploma={learnable.root?.structureDefinition.extension?.diploma}
			onClick={handleOnClick}
			price={
				learnable.root?.structureDefinition?.extension?.licenseProduct?.price.grossPrice ??
				0
			}
		/>
	);
};
