import { graphql } from "react-relay";

export const VIDEO_LEARN_ELEMENT_FRAGMENT = graphql`
	fragment videoLearnElement_VideoLearnElementFragment on VideoLearnElement {
		id
		streamFile {
			url
		}
		subtitles {
			language
			subtitles {
				startTimeInSec
				endTimeInSec
				text
			}
		}
		thumbnailFile {
			url
		}
	}
`;
