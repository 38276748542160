import { css, cva } from "@styled-system/css";

export const cardClass = cva({
	base: {
		display: "flex",
		justifyContent: "space-between",
		gap: "8",
		width: "full",
		paddingX: "16",
		paddingY: "12",
		border: "1px solid",
		borderColor: "shade.20",
		borderRadius: "12",
		boxShadow: "card",
		appearance: "none",
		textAlign: "left",
	},
	variants: {
		isCompleted: {
			true: {
				backgroundColor: "shade.5",
				borderColor: "shade.20",
				boxShadow: "[none]",
			},
		},
	},
	defaultVariants: {
		isCompleted: false,
	},
});

export const contentWrapperClass = css({
	display: "flex",
	gap: "12",
	alignItems: "center",
});

export const checkboxClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "24",
		height: "24",
		borderRadius: "full",
		border: "1px solid",
	},
	variants: {
		isCompleted: {
			true: {
				borderColor: "shade.40",
				backgroundColor: "shade.40",
			},
			false: {
				borderColor: "shade.100",
				backgroundColor: "transparent",
			},
		},
	},
});

export const contentClass = css({
	display: "flex",
	flexDirection: "column",
});

export const titleClass = css({
	color: "shade.100",
});

export const srOnlyClass = css({
	srOnly: true,
});

export const checkIconClass = cva({
	variants: {
		isCompleted: {
			true: {
				color: "shade.0",
			},
		},
	},
});

export const contextMenuButtonClass = css({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	paddingX: "16",
	paddingY: "12",
	backgroundColor: "transparent",
});

export const contextMenuContentClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "4",
	width: "full",
	paddingY: "8",
});

export const contextMenuItemClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		gap: "12",
		paddingX: "16",
		paddingY: "8",
		backgroundColor: "transparent",
		transition: "opacity",
		transitionDuration: "0.1s",
		transitionTimingFunction: "ease-in-out",
		_hover: {
			opacity: 0.75,
		},
	},
	variants: {
		color: {
			default: {
				color: "shade.100",
			},
			warning: {
				color: "warning.100",
			},
			success: {
				color: "success.100",
			},
			info: {
				color: "info.100",
			},
			error: {
				color: "error.100",
			},
		},
	},
});
