import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { TabBar } from "@thekeytechnology/academies-lib-webapp/components/tab-bar";
import { H1Span } from "@thekeytechnology/epic-ui";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout";
import { bottonBarWrapper, header } from "./notes.styles";
import { TabPanel } from "primereact/tabview";
import { NotesOverviewSkeleton } from "./parts/notes-overview/notes-overview.skeleton";

export const NotesScreenSkeleton = () => {
	return (
		<ScreenWithProfileMenuLayout
			bottomContent={
				<div className={bottonBarWrapper}>
					<Button label="Neue Notiz" iconName="add" />
				</div>
			}
		>
			<div className={header}>
				<H1Span>Notizen</H1Span>
				<TabBar disableMaxParentHeight>
					<TabPanel header="Notizen">
						<NotesOverviewSkeleton />
					</TabPanel>
					<TabPanel header="Erinnerungen">
						<NotesOverviewSkeleton />
					</TabPanel>
				</TabBar>
			</div>
		</ScreenWithProfileMenuLayout>
	);
};
