import { createClient, Entry } from "contentful";
import { BackendModule, ReadCallback } from "i18next";
import { TOKEN, SPACE_ID } from "../CONTENTFUL";
const client = createClient({
	// This is the space ID. A space is like a project folder in Contentful terms
	space: SPACE_ID,
	// This is the access token for this space. Normally you get both ID and the token in the Contentful web app
	accessToken: TOKEN!,
});

export class ContentfulI18nBackend implements BackendModule {
	type = "backend" as const;

	private readPromise: Promise<Entry<any>[]> = Promise.resolve([]);

	init() {
		this.readPromise = client
			.sync({ initial: true })
			.then((e) => {
				return e.entries;
			})
			.catch(() => {
				return fetch("/fallback.json")
					.then((e) => e.json() as Promise<Entry<any>[]>)
					.catch(() => {
						throw new Error("fallback.json not available.");
					});
			});
	}

	read(language: string, namespace: string, callback: ReadCallback): void {
		this.readPromise.then((entries) => {
			const valuesInSpace = entries
				.filter((x) => x.sys.contentType.sys.id.toLowerCase() === namespace.toLowerCase())
				?.map((entry) => {
					return Object.entries(entry.fields)
						.filter((field) => field[0] !== "key")
						.map((field) => {
							const fieldName = field[0];
							const translatedValues = field[1] as any;
							const translationForLanguage = translatedValues[language] as
								| string
								| undefined;
							return translationForLanguage
								? {
										[entry.fields["key"]["de-DE"] + "." + fieldName]:
											translationForLanguage,
								  }
								: {};
						})
						.reduce((x, y) => {
							return {
								...x,
								...y,
							};
						}, {});
				})
				.reduce((x, y) => ({ ...x, ...y }), {});

			callback(null, valuesInSpace);
		});
	}
}
