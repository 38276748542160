import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useTheme } from "@thekeytechnology/academies-lib-webapp/theme";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { AvatarWithAccountSwitch } from "@components/avatar-with-account-switch";
import { header_AuthViewerSchemaFragment$key } from "@relay/header_AuthViewerSchemaFragment.graphql";
import { Path } from "@router/paths";
import { shade0, shade100 } from "@themes/colors";
import { AUTH_VIEWER_SCHEMA_FRAGMENT } from "./header.graphql";
import { HeaderWrapper, LeftItem, RightItem } from "./header.styles";
import { HeaderProps } from "./header.types";

export const Header = ({
	canGoBack,
	leftIcon,
	leftItem,
	onLeftItemClick,
	showAvatar,
	authViewerSchemaFragmentRef,
}: HeaderProps) => {
	const theme = useTheme();

	const auth = useFragment<header_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		authViewerSchemaFragmentRef ?? null,
	);
	const naviagte = useNavigate();

	const handleLeftItemOnClick = () => {
		if (onLeftItemClick) {
			onLeftItemClick();
		} else {
			naviagte(-1);
		}
	};

	const handleLogoOnClick = () => {
		naviagte(Path.dashboard.path);
	};

	const showLeftItem =
		(canGoBack || leftIcon || leftItem || onLeftItemClick) && canGoBack !== false;
	return (
		<HeaderWrapper>
			{showLeftItem && (
				<LeftItem>
					{leftItem ?? (
						<Icon
							icon={leftIcon ?? "arrowLeft"}
							color={shade100}
							sizeRem={1}
							circleOutlineColor={shade0}
							onClick={handleLeftItemOnClick}
						/>
					)}
				</LeftItem>
			)}
			{<theme.LogoComponent onClick={handleLogoOnClick} />}
			{showAvatar && auth && (
				<RightItem>
					<AvatarWithAccountSwitch authViewerSchemaFragmentRef={auth} />
				</RightItem>
			)}
		</HeaderWrapper>
	);
};
