import { graphql } from "react-relay";

export const NOTE_FRAGMENT = graphql`
	fragment reminderCard_ReminderNoteFragment on Note
	@refetchable(queryName: "reminderCard_ReminderNoteFragmentRefetch") {
		__typename
		id
		content
		reminder {
			__typename
			kind
			isCompleted
			remindAt
		}
		...upsertReminderModal_ReminderNoteFragment
	}
`;

export const DELETE_NOTE_MUTATION = graphql`
	mutation reminderCard_DeleteReminderNoteMutation($input: DeleteNoteInput!) {
		Note {
			deleteNote(input: $input) {
				clientMutationId
			}
		}
	}
`;

export const SET_NOTE_IS_COMPLETED_MUTATION = graphql`
	mutation reminderCard_CheckReminderNoteMutation($input: SetNoteIsCompletedInput!) {
		Note {
			setNoteIsCompleted(input: $input) {
				edge {
					node {
						...reminderCard_ReminderNoteFragment
					}
				}
			}
		}
	}
`;
