/**
 * @generated SignedSource<<653fc2229d483d4fd848ab437a670073>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type reminderOverview_ReminderQueryFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"reminderCard_ReminderNoteFragment" | "reminderOverview_ReminderCardInlineFragment">;
    };
  } | null> | null;
  readonly " $fragmentType": "reminderOverview_ReminderQueryFragment";
};
export type reminderOverview_ReminderQueryFragment$key = {
  readonly " $data"?: reminderOverview_ReminderQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"reminderOverview_ReminderQueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "reminderOverview_ReminderQueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NoteEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Note",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "reminderCard_ReminderNoteFragment"
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "reminderOverview_ReminderCardInlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "content",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "reminder",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "kind",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCompleted",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "remindAt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "NoteConnection",
  "abstractKey": null
};
})();

(node as any).hash = "91b0c1c3ff7c9acf78b8667c34c87bb4";

export default node;
