import { css, cva } from "@styled-system/css";

export const listClass = css({
	display: "flex",
	flexDirection: "column",
	marginTop: "8",
});

export const draggableWrapperClass = css({
	marginBottom: "8",
});

export const cardItemWrapperClass = css({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: "16",
});

export const numberClass = cva({
	base: {
		color: "shade.20",
	},
	variants: {
		status: {
			correct: {
				color: "shade.0",
			},
		},
		darker: {
			true: {
				color: "shade.100",
			},
		},
	},
	defaultVariants: {
		darker: false,
	},
});

export const textClass = cva({
	base: {
		color: "shade.80",
	},
	variants: {
		status: {
			correct: {
				color: "shade.0",
			},
			actuallyCorrect: {
				color: "shade.100",
			},
		},
		darker: {
			true: {
				color: "shade.100",
			},
		},
	},
	defaultVariants: {
		darker: false,
	},
});
