import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { PathParams } from "@thekeytechnology/epic-ui";
import { graphql } from "react-relay";
import { useCallback, useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { ContentSubmissionScreen_ContentSubmissionFragment$key } from "@relay/ContentSubmissionScreen_ContentSubmissionFragment.graphql";
import { ContentSubmissionScreen_NodeQuery } from "@relay/ContentSubmissionScreen_NodeQuery.graphql";
import { ContentSubmissionPath } from "@router/paths";
import { AsyncContentSubmission } from "@screens/async-content-submission";
import { ELearningContentSubmission } from "@screens/elearning-content-submission";
import { ContentSubmissionSkeleton } from "./content-submission.skeleton";

const QUERY = graphql`
	query ContentSubmissionScreen_NodeQuery($id: ID!) {
		node(id: $id) {
			... on ContentSubmission {
				id
				definition {
					contentKind
				}
				...ContentSubmissionScreen_ContentSubmissionFragment
			}
		}
	}
`;

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ContentSubmissionScreen_ContentSubmissionFragment on ContentSubmission {
		...ELearningContentSubmissionScreen_ContentSubmissionFragment
		...asyncContentSubmissionScreen_ContentSubmissionFragment
	}
`;

export const ContentSubmissionComponent = () => {
	const { rootId } = useParams<PathParams<typeof ContentSubmissionPath>>();
	const [fetchKey, setFetchKey] = useState(1);

	const query = useLazyLoadQuery<ContentSubmissionScreen_NodeQuery>(
		QUERY,
		{ id: rootId || "" },
		{ UNSTABLE_renderPolicy: "partial", fetchPolicy: "network-only", fetchKey },
	);

	const contentSubmission = useFragment<ContentSubmissionScreen_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		query.node,
	);

	const handleRefetch = useCallback(() => {
		setFetchKey((prev) => prev + 1);
	}, []);

	if (!contentSubmission) return null;
	switch (query.node?.definition?.contentKind) {
		case "Async":
			return (
				<AsyncContentSubmission
					contentSubmissionFragmentRef={contentSubmission}
					refetch={handleRefetch}
				/>
			);
		case "ELearning":
		default:
			return <ELearningContentSubmission contentSubmissionFragmentRef={contentSubmission} />;
	}
};

export const ContentSubmission = withSuspense(
	ContentSubmissionComponent,
	ContentSubmissionSkeleton,
);
