import { CircularProgressbar } from "@thekeytechnology/academies-lib-webapp/components/circular-progressbar";
import { formatCurrency } from "@thekeytechnology/academies-lib-webapp/utils";
import { graphql, useFragment } from "react-relay";
import { useTheme } from "styled-components";
import { RootProgress_LearnOpportunityV2Fragment$key } from "@relay/RootProgress_LearnOpportunityV2Fragment.graphql";
import { shade100 } from "@themes/colors";
import { TkaH1Span, TkaH3Span } from "@themes/font-tags";
import { ProgressUnit } from "./root-progress.const";
import { RootProgressProps } from "./root-progress.interface";
import {
	GamificationPointsProgress,
	GamificationPointsProgressDivider,
	ImageTitle,
	ImageWrapper,
	MainWrapper,
	PriceSubtitle,
	PriceWrapper,
	ProgressWrapper,
	StyledImage,
	TaglineTitle,
} from "./root-progress.styles";
import { ReactComponent as KeyMedalSvg } from "../../assets/key-medal-points.svg";

const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment RootProgress_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		image {
			url
		}
		structureDefinition {
			title
			... on LearnOpportunityRootStructureDefinition {
				progressPercentage
				extension {
					... on PublishedRootExtensionImpl {
						receivedGamificationPoints
						reducedData {
							receivableGamificationPoints
						}
						cartClickout {
							netPrice
						}
					}
				}
			}
		}
	}
`;

export const RootProgress = ({ hideImage, learnOpportunityV2FragmentRef }: RootProgressProps) => {
	const theme = useTheme();

	const rootData = useFragment<RootProgress_LearnOpportunityV2Fragment$key>(
		LEARN_OPPORTUNITY_V2_FRAGMENT,
		learnOpportunityV2FragmentRef,
	);

	const progressValue = rootData?.structureDefinition?.progressPercentage ?? 0;
	const cartClickout = rootData?.structureDefinition?.extension?.cartClickout;
	const receivedGamificationPoints =
		rootData?.structureDefinition?.extension?.receivedGamificationPoints;
	const receivableGamificationPoints =
		rootData?.structureDefinition?.extension?.reducedData?.receivableGamificationPoints;

	return (
		<MainWrapper>
			<ImageTitle>
				<TaglineTitle>
					<TkaH1Span tkaColor={shade100}>
						{rootData?.structureDefinition?.title}
					</TkaH1Span>
				</TaglineTitle>
				{!hideImage && rootData?.image?.url && (
					<ImageWrapper>
						<ProgressWrapper>
							<CircularProgressbar
								value={progressValue}
								width={theme.spacing.spacing20}
								height={theme.spacing.spacing20}
								strokeWidth={20}
							/>
							<TkaH3Span>
								{progressValue} {ProgressUnit}
							</TkaH3Span>
							<GamificationPointsProgressDivider aria-hidden="true" />
							<GamificationPointsProgress>
								<TkaH3Span tkaColor={theme.colors.warning100}>
									{receivedGamificationPoints} / {receivableGamificationPoints}
								</TkaH3Span>
								<KeyMedalSvg />
							</GamificationPointsProgress>
						</ProgressWrapper>
						{cartClickout?.netPrice && (
							<PriceWrapper>
								<TkaH1Span tkaColor={theme.colors.primary100}>
									{formatCurrency(cartClickout.netPrice)}
								</TkaH1Span>
								<PriceSubtitle>incl. MwSt.</PriceSubtitle>
							</PriceWrapper>
						)}
						<StyledImage
							imageUrl={rootData.image.url}
							imageWidth={"100%"}
							borderRadiusRem={theme.spacing.spacing16}
						/>
					</ImageWrapper>
				)}
			</ImageTitle>
		</MainWrapper>
	);
};
