import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BranchNodesPath, MaterialsPath, Path, RootOverviewPath } from "@router/paths";
import { RootScreenContext } from "./root.context";

export const TabRoutesMap = {
	[RootOverviewPath.pathName]: 0,
	[BranchNodesPath.pathName]: 1,
	[MaterialsPath.pathName]: 2,
};

export const getPathForTabIndex = (index: number) =>
	Object.keys(TabRoutesMap).find((key) => TabRoutesMap[key] === index) ??
	RootOverviewPath.pathName;

export const getTabIndexForPath = (path?: string) => {
	const route = Object.keys(TabRoutesMap).find((route) => path?.startsWith(route));
	return route ? TabRoutesMap[route] : 0;
};

export const useRootNavigation = () => {
	const navigate = useNavigate();
	const { branchNodeId, rootId } = useParams();
	const { lastContentId, setLastContentId } = useContext(RootScreenContext);
	const { isXLargeUp } = useWindowSize();

	const navigateToContentId = useCallback(
		(contentId: string) => {
			setLastContentId(contentId);
			rootId &&
				navigate(Path.root.withId(rootId).branchNodes.withId(contentId).path, {
					replace: true,
				});
		},
		[navigate, setLastContentId, rootId],
	);

	const navigateToTab = useCallback(
		(index: number) => {
			const tabPath = getPathForTabIndex(index);
			const path = index === 1 && lastContentId ? `${tabPath}/${lastContentId}` : tabPath;
			navigate(path, { replace: true });
		},
		[navigate, branchNodeId, lastContentId],
	);

	const handleContentNodeOnClick = useCallback(
		(contentId: string) => {
			navigateToContentId(contentId);
			!isXLargeUp &&
				rootId &&
				navigate(
					Path.root.withId(rootId).branchNodes.withId(contentId).lessonOverview.path,
				);
		},
		[navigate, navigateToContentId, isXLargeUp, rootId],
	);

	return { navigateToContentId, navigateToTab, handleContentNodeOnClick };
};
