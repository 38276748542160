import styled from "styled-components";
import tw from "twin.macro";
import { AlternativeNavigationHeightRem } from "./alternative-navigation-organism.consts";

export const Wrapper = styled.div`
	height: ${AlternativeNavigationHeightRem}rem;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.shade0};
	display: flex;
	flex-direction: column;
	flex-grow: 0;
	box-shadow: 0px -6px 10px rgba(9, 46, 88, 0.1);
	padding: 0;

	position: fixed;
	bottom: 0;
	left: 0;
	@media screen and (min-width: ${({ theme }) => theme.breakpoints.xlarge}px) {
		position: relative;
		bottom: unset;
	}
`;

export const Flex = styled.div`
	flex: 1;
	display: flex;
	gap: 1rem;
	justify-content: space-around;
	align-items: center;
	padding: 0 1rem;

	@media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}px) {
		justify-content: flex-end;
	}
`;

export const ArrowLeftWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.shade10};
	border-radius: 100%;
	padding: 0.75rem;
	cursor: pointer;
`;

export const ButtonWrapper = tw.div`
	flex-1
	md:flex-none
	min-w-[10rem]
`;
