import { SideMenuContentPadding } from "@thekeytechnology/academies-lib-webapp/containers/side-menu-content-padding";
import tw from "twin.macro";

export const Wrapper = tw(SideMenuContentPadding)`
	min-h-full
	flex
	flex-col
	items-center
	justify-center
`;

export const PlaceholderWrapper = tw.div`
	mt-[-2rem]
`;
