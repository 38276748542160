import { graphql } from "react-relay";

export const QUERY = graphql`
	query notes_NotesQuery(
		$includeNoteSourceKinds: [NoteSourceKind!]
		$includeNoteColors: [NoteColor!]
		$noteColorToSortBy: NoteColor
		$filterByText: String
	) {
		...notes_NotesFragment
			@arguments(
				includeNoteSourceKinds: $includeNoteSourceKinds
				includeNoteColors: $includeNoteColors
				noteColorToSortBy: $noteColorToSortBy
				filterByText: $filterByText
			)
	}
`;

export const QUERY_FRAGMENT = graphql`
	fragment notes_NotesFragment on Query
	@argumentDefinitions(
		includeNoteSourceKinds: { type: "[NoteSourceKind!]", defaultValue: null }
		includeNoteColors: { type: "[NoteColor!]", defaultValue: null }
		noteColorToSortBy: { type: "NoteColor" }
		filterByText: { type: "String" }
	)
	@refetchable(queryName: "notes_RefetchQuery") {
		Note {
			Note(
				includeNoteSourceKinds: $includeNoteSourceKinds
				includeNoteColors: $includeNoteColors
				noteColorToSortBy: $noteColorToSortBy
				filterByText: $filterByText
			) {
				...notesOverview_NotesQueryFragment
				...reminderOverview_ReminderQueryFragment
			}
		}
	}
`;
