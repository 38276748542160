import type { SubmitMatrixIndexCellInput } from "@relay/MatrixElement_SubmitMatrixElementMutation.graphql";
import type { CellCords, ElementCell } from "./matrix-learn-element.types";

export const getIndexKey = (x: number, y: number) => `${x}:${y}`;

export const get2DArray = (cells: readonly ElementCell[]): ElementCell[][] =>
	cells.reduce((array2D: ElementCell[][], cell) => {
		array2D[cell.yIdx] = array2D[cell.yIdx] || [];
		array2D[cell.yIdx][cell.xIdx] = cell;
		return array2D;
	}, []);

export const convertToMap = (input: SubmitMatrixIndexCellInput[]) =>
	input.reduce((prev, current) => {
		const key = getIndexKey(current.xIdx, current.yIdx);
		return { ...prev, [key]: current };
	}, {});

export const equalCords = (cordsA?: CellCords, cordsB?: CellCords) =>
	cordsA && cordsA.xIdx === cordsB?.xIdx && cordsA.yIdx === cordsB.yIdx;
