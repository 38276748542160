/**
 * @generated SignedSource<<834ad4ed59624f8b06d434450663d1dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementStateKind = "Input" | "InputAndIsCorrect" | "ShowAnswer" | "Untouched" | "UntouchedAndPreviouslyIncorrect";
export type MatrixElementCellKind = "Answer" | "Display";
import { FragmentRefs } from "relay-runtime";
export type MatrixElement_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly currentElementState?: {
      readonly checkedIndices?: ReadonlyArray<{
        readonly index: number;
        readonly xIdx: number;
        readonly yIdx: number;
      }>;
      readonly correctIndices?: ReadonlyArray<{
        readonly index: number;
        readonly xIdx: number;
        readonly yIdx: number;
      }>;
      readonly element: {
        readonly cells?: ReadonlyArray<{
          readonly answers?: ReadonlyArray<{
            readonly content: string;
          }>;
          readonly content?: string;
          readonly kind?: MatrixElementCellKind;
          readonly xIdx: number;
          readonly yIdx: number;
        }>;
        readonly id: string;
        readonly title: string;
        readonly " $fragmentSpreads": FragmentRefs<"matrixLearnElementFragment_MatrixLearnElementFragment">;
      };
      readonly inputElementState?: {
        readonly checkedIndices?: ReadonlyArray<{
          readonly index: number;
          readonly xIdx: number;
          readonly yIdx: number;
        }>;
      };
      readonly isCorrect?: boolean;
      readonly kind: ElementStateKind;
      readonly lastCheckedIndices?: ReadonlyArray<{
        readonly index: number;
        readonly xIdx: number;
        readonly yIdx: number;
      }> | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment">;
  };
  readonly id: string;
  readonly " $fragmentType": "MatrixElement_ContentSubmissionFragment";
};
export type MatrixElement_ContentSubmissionFragment$key = {
  readonly " $data"?: MatrixElement_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MatrixElement_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xIdx",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yIdx",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MatrixElement_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "cells",
                          "plural": true,
                          "selections": [
                            (v2/*: any*/),
                            (v3/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v4/*: any*/),
                                (v1/*: any*/)
                              ],
                              "type": "DisplayMatrixLearnElementCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "MatrixLearnElementAnswer",
                                  "kind": "LinkedField",
                                  "name": "answers",
                                  "plural": true,
                                  "selections": [
                                    (v4/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                (v1/*: any*/)
                              ],
                              "type": "AnswerMatrixLearnElementCell",
                              "abstractKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "matrixLearnElementFragment_MatrixLearnElementFragment"
                        }
                      ],
                      "type": "MatrixLearnElement",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MatrixLearnElementIndexCell",
                      "kind": "LinkedField",
                      "name": "lastCheckedIndices",
                      "plural": true,
                      "selections": (v6/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MatrixLearnElementIndexCell",
                      "kind": "LinkedField",
                      "name": "correctIndices",
                      "plural": true,
                      "selections": (v6/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "MatrixShowAnswerElementState",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isCorrect",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "inputElementState",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "MatrixLearnElementIndexCell",
                              "kind": "LinkedField",
                              "name": "checkedIndices",
                              "plural": true,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "type": "MatrixInputElementState",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "InputAndIsCorrectElementState",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MatrixLearnElementIndexCell",
                      "kind": "LinkedField",
                      "name": "checkedIndices",
                      "plural": true,
                      "selections": [
                        (v5/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "MatrixInputElementState",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "86648c9ca269e41ffad300fe5ebd50a7";

export default node;
