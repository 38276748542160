import { Spacing } from "@thekeytechnology/epic-ui";
import { type Recursive, type Token } from "@styled-system/types/composition";

/**
 * @deprecated This spacing should not be used
 */
export const spacing0: Spacing = new Spacing(0);

/**
 * @deprecated This spacing should not be used
 */
export const spacing2: Spacing = new Spacing(2);

/**
 * @deprecated This spacing should not be used
 */
export const spacing4: Spacing = new Spacing(4);

/**
 * @deprecated This spacing should not be used
 */
export const spacing8: Spacing = new Spacing(8);

/**
 * @deprecated This spacing should not be used
 */
export const spacing10: Spacing = new Spacing(10);

/**
 * @deprecated This spacing should not be used
 */
export const spacing12: Spacing = new Spacing(12);

/**
 * @deprecated This spacing should not be used
 */
export const spacing16: Spacing = new Spacing(16);

/**
 * @deprecated This spacing should not be used
 */
export const spacing20: Spacing = new Spacing(20);

/**
 * @deprecated This spacing should not be used
 */
export const spacing24: Spacing = new Spacing(24);

/**
 * @deprecated This spacing should not be used
 */
export const spacing32: Spacing = new Spacing(32);

/**
 * @deprecated This spacing should not be used
 */
export const spacing40: Spacing = new Spacing(40);

/**
 * @deprecated This spacing should not be used
 */
export const spacing64: Spacing = new Spacing(64);

/**
 * @deprecated This spacing should not be used
 */
export const spacing80: Spacing = new Spacing(80);

/**
 * @deprecated This spacing should not be used
 */
export const spacing96: Spacing = new Spacing(96);

export const spacing: Recursive<Token<string | number>> = {
	0: { value: "0rem" },
	2: { value: "0.125rem" },
	4: { value: "0.25rem" },
	8: { value: "0.5rem" },
	10: { value: "0.625rem" },
	12: { value: "0.75rem" },
	16: { value: "1rem" },
	20: { value: "1.25rem" },
	24: { value: "1.5rem" },
	32: { value: "2rem" },
	40: { value: "2.5rem" },
	64: { value: "4rem" },
	80: { value: "5rem" },
	96: { value: "6rem" },
	"50%": { value: "50%" },
	"100%": { value: "100%" },
};
