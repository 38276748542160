import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { parseHtml } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import { graphql } from "react-relay";
import { useFragment } from "react-relay";
import { AsyncContentSubmissionFilePreview } from "@components/async-content-submission-file-preview";
import { UploadAsyncContentOverview_AsyncElementTaskFragment$key } from "@relay/UploadAsyncContentOverview_AsyncElementTaskFragment.graphql";
import { shade100 } from "@themes/colors";
import { TkaH1Span, TkaH2Span, TkaP2Span } from "@themes/font-tags";
import { UploadAsyncContentOverviewProps } from "./upload-async-content-overview.interface";
import { ContentWrapper, Divider } from "./upload-async-content-overview.styles";

export const ASYNC_ELEMENT_TASK_FRAGMENT = graphql`
	fragment UploadAsyncContentOverview_AsyncElementTaskFragment on AsyncElementTask {
		status
		... on FreshUploadAsyncElementTask {
			file {
				url
				name
			}
		}
		... on EvaluatedUploadAsyncElementTask {
			file {
				url
				name
			}
			evaluation {
				... on UploadEvaluation {
					file {
						url
						name
					}
					text
				}
			}
		}
	}
`;

export const UploadAsyncContentOverview = ({
	asyncElementTaskFragmentRef,
}: UploadAsyncContentOverviewProps) => {
	const { t } = useTkaTranslation("asyncContentOverview");

	const task = useFragment<UploadAsyncContentOverview_AsyncElementTaskFragment$key>(
		ASYNC_ELEMENT_TASK_FRAGMENT,
		asyncElementTaskFragmentRef,
	);

	return (
		<>
			<ContentWrapper>
				<AsyncContentSubmissionFilePreview
					title={t("async_content_overview.fileSubmissionTitle")}
					fileName={task.file?.name}
					url={task.file?.url ?? undefined}
				/>
			</ContentWrapper>
			{task.evaluation && (
				<>
					<ContentWrapper>
						<Divider />
					</ContentWrapper>
					<ContentWrapper>
						<TkaH1Span tkaColor={shade100}>
							{t("async_content_overview.evaluationTitle")}
						</TkaH1Span>
					</ContentWrapper>
					<ContentWrapper>
						<AsyncContentSubmissionFilePreview
							title={t("async_content_overview.evaluationFileTitle")}
							fileName={task.evaluation.file?.name}
							url={task.evaluation.file?.url ?? undefined}
						/>
					</ContentWrapper>
					<ContentWrapper>
						<TkaH2Span tkaColor={shade100}>
							{t("async_content_overview.evaluationTextTitle")}
						</TkaH2Span>
						<TkaP2Span tkaColor={shade100}>
							{parseHtml(task.evaluation.text || "")}
						</TkaP2Span>
					</ContentWrapper>
				</>
			)}
		</>
	);
};
