import { IconMap } from "@thekeytechnology/epic-ui";
import React from "react";
import { TkaColor } from "@themes/colors";

export interface MultipleChoiceGapTextProps {
	text: string;
	isRadio?: boolean;
	answerLetter?: string;
	status: MultipleChoiceGapTextStatus;
	checked: boolean;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export enum MultipleChoiceGapTextStatus {
	Default,
	Correct,
	Wrong,
	ActuallyCorrect,
	Disabled,
}

export interface MultipleChoiceGapTextStyle {
	backgroundColor: TkaColor;
	borderColor: TkaColor;
	textColor: TkaColor;
}

export interface MultipleChoiceGapTextIconStyle {
	icon?: keyof IconMap;
	iconColor?: TkaColor;
	iconBackgroundColor?: TkaColor;
}
