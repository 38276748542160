import { graphql } from "react-relay";

export const MESSAGE_FRAGMENT = graphql`
	fragment messagePreviewCard_MessageFragment on Message {
		...messageModal_MessageFragment
		hasSeen
		messageDefinition {
			id
			pages {
				data {
					kind
					... on ImagePage {
						element {
							image {
								url
							}
						}
					}
					... on ImageWithButtonPage {
						element {
							image {
								url
							}
						}
					}
				}
			}
		}
	}
`;
