import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { useCallback } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { goToPreviousElementButton_ContentSubmissionFragment$key } from "@relay/goToPreviousElementButton_ContentSubmissionFragment.graphql";
import { goToPreviousElementButton_GoToPreviousElementMutation } from "@relay/goToPreviousElementButton_GoToPreviousElementMutation.graphql";
import { GoToPreviousElementButtonProps } from "./go-to-previous-element-button.types";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment goToPreviousElementButton_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						id
						elementType
					}
				}
				canGoToPrevious
			}
		}
	}
`;

const GO_TO_PREVIOUS_ELEMENT_MUTATION = graphql`
	mutation goToPreviousElementButton_GoToPreviousElementMutation(
		$input: GoToPreviousElementInput!
	) {
		ELearning {
			goToPreviousElement(input: $input) {
				clientMutationId
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

export const GoToPreviousElementButton = ({
	contentSubmissionFragmentRef,
}: GoToPreviousElementButtonProps) => {
	const [dispatchGoToPreviousElement] =
		useMutation<goToPreviousElementButton_GoToPreviousElementMutation>(
			GO_TO_PREVIOUS_ELEMENT_MUTATION,
		);

	const contentSubmission = useFragment<goToPreviousElementButton_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const contentSubmissionId = contentSubmission.id;
	const canGoToPrevious = contentSubmission.definition.canGoToPrevious || false;

	const onClick = useCallback(() => {
		if (!canGoToPrevious) return;
		dispatchGoToPreviousElement({
			variables: {
				input: {
					contentSubmissionId,
				},
			},
		});
	}, [canGoToPrevious]);

	return (
		<Button
			colorVersion="tertiary"
			iconName="arrowLeft"
			disabled={!canGoToPrevious}
			onClick={onClick}
		/>
	);
};
