import { graphql } from "react-relay";

export const NOTE_FRAGMENT = graphql`
	fragment noteCard_NoteFragment on Note @refetchable(queryName: "noteCard_NoteFragmentRefetch") {
		id
		content
		markedText
		color
		source {
			... on CourseNoteSource {
				publishedRootNode {
					id
					structureDefinition {
						title
					}
				}
			}
			... on ElementNoteSourceType {
				publishedRootNode {
					id
					structureDefinition {
						title
					}
				}
				element {
					...upsertNoteModal_ElementFragment
				}
			}
			... on ModuleNoteSource {
				publishedRootNode {
					id
					structureDefinition {
						title
					}
				}
			}
		}
		...upsertNoteModal_NoteFragment
	}
`;

export const DELETE_NOTE_MUTATION = graphql`
	mutation noteCard_DeleteNoteMutation($input: DeleteNoteInput!) {
		Note {
			deleteNote(input: $input) {
				clientMutationId
			}
		}
	}
`;
