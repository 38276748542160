import { shade20 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { Spacer, StyledDropdown, Wrapper } from "./gap-input-dropdown.styles";
import { GapInputDropdownProps } from "./gap-input-dropdown.types";

import { GapTextStatus } from "../gap-text/gap-text.interface";
import { getBorderColor, getBackgroundColor, getTextColor } from "../gap-text/gap-text.util";

export const GapInputDropdown = ({
	text,
	status = GapTextStatus.Default,
	selected = false,
	useMinWidth = false,
	onlyBoldIfSelected,
	onClick,
	availableAnswers,
	onAnswerSelect,
	onKeyDown,
	isOver,
	drop,
}: GapInputDropdownProps) => {
	const hasText = !!text;
	const backgroundColor = isOver ? shade20 : getBackgroundColor(status, hasText);
	const borderColor = getBorderColor(status, hasText, selected);
	const textColor = getTextColor(status);

	const bold = onlyBoldIfSelected ? selected : true;

	const selectedItemTemplate = () => {
		return (
			<div>
				<TkaP2Span tkaColor={textColor} bold={bold}>
					{text}
				</TkaP2Span>
			</div>
		);
	};
	return (
		<Wrapper
			backgroundColor={backgroundColor}
			borderColor={borderColor}
			onClick={onClick}
			ref={drop}
		>
			<StyledDropdown
				options={availableAnswers}
				optionLabel="text"
				optionValue="id"
				value={text}
				onChange={(e) => {
					onAnswerSelect && onAnswerSelect(e.value);
				}}
				onKeyDown={(e) => onKeyDown && onKeyDown(e)}
				isOver={isOver}
				valueTemplate={selectedItemTemplate}
			/>
			{useMinWidth && <Spacer />}
		</Wrapper>
	);
};
