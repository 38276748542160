/**
 * @generated SignedSource<<b8e6d76b444f8ffb00227e3e78e65306>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AnsweringTypeV2 = "allowMultipleAnswersAllCorrectMustBeGiven" | "allowMultipleAnswersOnlySomeCorrectMustBeGiven" | "allowOnlyOneAnswer";
import { FragmentRefs } from "relay-runtime";
export type multipleChoiceLearnElement_MultipleChoiceLearnElementFragment$data = {
  readonly answerOptions: ReadonlyArray<{
    readonly answer: string;
    readonly image: {
      readonly url: string | null;
    } | null;
  }>;
  readonly answeringType: AnsweringTypeV2;
  readonly answeringTypeText: string | null;
  readonly question: string;
  readonly " $fragmentType": "multipleChoiceLearnElement_MultipleChoiceLearnElementFragment";
};
export type multipleChoiceLearnElement_MultipleChoiceLearnElementFragment$key = {
  readonly " $data"?: multipleChoiceLearnElement_MultipleChoiceLearnElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"multipleChoiceLearnElement_MultipleChoiceLearnElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "multipleChoiceLearnElement_MultipleChoiceLearnElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "question",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answeringType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answeringTypeText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerOptionWithoutCorrect",
      "kind": "LinkedField",
      "name": "answerOptions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MultipleChoiceLearnElement",
  "abstractKey": null
};

(node as any).hash = "98138f411ae95ed9dcb2153b11c114d5";

export default node;
