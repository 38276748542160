import { graphql } from "react-relay";

export const QUERY = graphql`
	query dashboard_Query(
		$tagIds: [ID!]!
		$kinds: [LearnableKind!]!
		$first: Int
		$last: Int
		$before: String
		$after: String
		$text: String
	) {
		Viewer {
			Auth {
				currentUser {
					user {
						extension {
							... on UserExtensionImpl {
								firstName
								lastName
								level
								progressInPercent
								sumGamificationPoints
							}
						}
						...userAvatar_UserFragment
					}
				}
			}
		}

		...dashboard_QueryFragment
			@arguments(
				kinds: $kinds
				tagIds: $tagIds
				first: $first
				last: $last
				before: $before
				after: $after
				text: $text
			)
	}
`;
export const QUERY_FRAGMENT = graphql`
	fragment dashboard_QueryFragment on Query
	@refetchable(queryName: "dashboardRefetchQuery")
	@argumentDefinitions(
		tagIds: { type: "[ID!]!" }
		kinds: { type: "[LearnableKind!]!" }
		first: { type: "Int", defaultValue: null }
		last: { type: "Int", defaultValue: null }
		before: { type: "String", defaultValue: null }
		after: { type: "String", defaultValue: null }
		text: { type: "String", defaultValue: null }
	) {
		Learnable {
			PublishedLearnables(
				before: $before
				after: $after
				first: $first
				last: $last
				tagIds: $tagIds
				text: $text
				kinds: $kinds
			) @connection(key: "dashboard_PublishedLearnables") {
				edges {
					node {
						id
						kind
						...dashboard_PublishedLearnableFragment
					}
				}
			}
		}
	}
`;

export const PUBLISHED_LEARNABLE_INLINE_FRAGMENT = graphql`
	fragment dashboard_PublishedLearnableFragment on PublishedLearnable @inline {
		kind
		id

		...educationalOfferCard_PublishedCourseLearnableFragment
	}
`;
