import { graphql } from "react-relay";

export const MATERIAL_OVERVIEW_QUERY = graphql`
	query materialOverview_Query($id: ID!) {
		node(id: $id) {
			... on LearnOpportunityV2 {
				...materialOverview_LearnOpportunityV2Fragment
			}
		}
	}
`;

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment materialOverview_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		typeDefinition {
			... on LearnOpportunityBranchTypeDefinition {
				children {
					typeDefinition {
						... on LearnOpportunityBranchTypeDefinition {
							children {
								indexPath
								attachmentsV2 {
									id
									title
									description
									... on DefaultFileAttachment {
										file {
											url
											fileSize
											name
										}
									}
								}
							}
						}
					}
				}
			}
		}
		...materialsTab_LearnOpportunityV2Fragment
	}
`;
