import { Icon } from "@thekeytechnology/academies-lib-webapp";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useState, type FC } from "react";
import { useMutation, useRefetchableFragment } from "react-relay";
import { UpsertNoteModal } from "@components/upsert-note-modal";
import { useToast } from "@hooks/useToast";
import type { noteCard_DeleteNoteMutation } from "@relay/noteCard_DeleteNoteMutation.graphql";
import type { noteCard_NoteFragment$key } from "@relay/noteCard_NoteFragment.graphql";
import { noteCard_NoteFragmentRefetch } from "@relay/noteCard_NoteFragmentRefetch.graphql";
import { L1Span, P2Span } from "@themes/font-tags";
import { DELETE_NOTE_MUTATION, NOTE_FRAGMENT } from "./note-card.graphql";
import { NoteCardSkeleton } from "./note-card.skeleton";
import {
	cardClass,
	contentClass,
	deleteButtonClass,
	headerClass,
	markedTextClass,
	tagClass,
} from "./note-card.styles";
import { NoteCardProps } from "./note-card.types";

const NoteCardComponent: FC<NoteCardProps> = ({ onNoteCardClick, noteFragmentRef }) => {
	const toast = useToast();
	const [isUpsertNoteModalOpen, setIsUpsertNoteModalOpen] = useState(false);

	const [data, refetch] = useRefetchableFragment<
		noteCard_NoteFragmentRefetch,
		noteCard_NoteFragment$key
	>(NOTE_FRAGMENT, noteFragmentRef ?? null);
	const [deleteNote] = useMutation<noteCard_DeleteNoteMutation>(DELETE_NOTE_MUTATION);

	if (!data) {
		return null;
	}

	const handleUpsertNoteModalDismiss = () => {
		setIsUpsertNoteModalOpen(false);
	};

	const handleNoteCardClick = () => {
		setIsUpsertNoteModalOpen(true);
		onNoteCardClick?.();
	};

	const handleUpsertNoteSuccess = () => {
		setIsUpsertNoteModalOpen(false);
	};

	const handleNoteSourceRemoved = () => {
		refetch(
			{},
			{
				fetchPolicy: "store-and-network",
			},
		);
	};

	const handleDeleteNote = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();

		deleteNote({
			variables: {
				input: {
					ids: [data.id],
				},
			},
			onCompleted: () => {
				toast.showSuccess({
					summary: "Notiz wurde gelöscht",
				});
			},
			onError: () => {
				toast.showError({
					summary: "Fehler beim Löschen der Notiz",
				});
			},
		});
	};

	const color = data.color ?? "default";
	const rootNodeTitle = data.source?.publishedRootNode?.structureDefinition.title;

	return (
		<>
			{isUpsertNoteModalOpen && (
				<UpsertNoteModal
					noteId={data.id}
					onDismiss={handleUpsertNoteModalDismiss}
					onSuccess={handleUpsertNoteSuccess}
					onRemoveNoteSource={handleNoteSourceRemoved}
					noteFragmentRef={data}
					elementFragmentRef={data.source?.element ?? null}
				/>
			)}
			<button
				role="group"
				className={cardClass({
					color,
				})}
				onClick={handleNoteCardClick}
			>
				{rootNodeTitle && (
					<div className={headerClass}>
						<div
							className={tagClass({
								color,
							})}
						>
							<L1Span>{rootNodeTitle}</L1Span>
						</div>
					</div>
				)}
				<button type="button" onClick={handleDeleteNote} className={deleteButtonClass}>
					<Icon icon="trash" />
				</button>
				<div
					className={contentClass({
						color,
					})}
				>
					<P2Span>{data.content}</P2Span>
				</div>
				{data.markedText && (
					<div
						className={markedTextClass({
							color,
						})}
					>
						<P2Span>“{data.markedText}”</P2Span>
					</div>
				)}
			</button>
		</>
	);
};

export const NoteCard = withSuspense(NoteCardComponent, NoteCardSkeleton);
