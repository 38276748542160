import { DATASET_SELECTION_MENU_ELEMENT_NAME } from "./selection-menu.consts";

export function hasParentWithMatchingSelector(target: HTMLElement, selector: string) {
	return Array.from(document.querySelectorAll(selector)).some(
		(el) => el !== target && el.contains(target),
	);
}

export function getSelectorValueFromParent(target: HTMLElement, selector: string) {
	const parent = target.closest(selector);
	if (!parent) return null;
	return parent.getAttribute(`data-${DATASET_SELECTION_MENU_ELEMENT_NAME}`);
}
