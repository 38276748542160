import { TkaH3Span } from "@themes/font-tags";
import { LinkContent, LinkWrapper, ListWrapper, StyledLink } from "./router-content-menu.styles";
import { RouterContentMenuProps } from "./router-content-menu.types";

export const RouterContentMenu = ({ items }: RouterContentMenuProps) => {
	const links = items.map((item) => (
		<LinkWrapper key={item.id}>
			<StyledLink to={item.id}>
				<TkaH3Span>{item.label}</TkaH3Span>
			</StyledLink>
			{item.children && <LinkContent>{item.children}</LinkContent>}
		</LinkWrapper>
	));

	return <ListWrapper>{links}</ListWrapper>;
};
