import { Skeleton } from "@thekeytechnology/epic-ui";
import { Wrapper } from "./navbar-item.styles";

export const NavbarItemSkeleton = () => {
	return (
		<Wrapper>
			<Skeleton width="1rem" height="1rem" />
			<Skeleton width="3rem" />
		</Wrapper>
	);
};
