import { SideMenu } from "@thekeytechnology/academies-lib-webapp/containers/side-menu";
import { SideMenuContent } from "@thekeytechnology/academies-lib-webapp/containers/side-menu-content";
import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div<{ bottomPadding?: number }>`
	${tw`
		flex-1
		flex
		flex-col
		xl:overflow-hidden
		xl:pb-0
	`}
	${({ bottomPadding }) => (bottomPadding ? `padding-bottom: ${bottomPadding}px` : "")}
`;

export const StyledSideMenu = styled(SideMenu)<{ noPadding: boolean }>`
	${tw`
		flex-1
		xl:overflow-hidden
		pb-24
		md:pb-40
		xl:pb-0
		grid-rows-[1fr_min-content]
		xl:mb-0
	`}
	${({ noPadding }) => (noPadding ? tw`p-0 md:p-0 xl:p-0` : "")}
`;

export const StyledSideMenuContent = styled(SideMenuContent)<{
	noPadding: boolean;
	hasBottomContent: boolean;
}>`
	${tw`
		xl:overflow-y-scroll
		xl:p-24
	`}
	${({ noPadding }) => (noPadding ? tw`p-0 md:p-0 xl:p-0` : "")}
	${({ hasBottomContent }) => (hasBottomContent ? tw`xl:row-start-1 xl:row-end-3` : "")}
`;

export const ChildrenWrapper = styled(SideMenuContent)<{
	noPadding: boolean;
	hasBottomContent: boolean;
}>`
	${tw`
		xl:overflow-y-scroll
		xl:pb-40
	`}
	${({ noPadding }) => (noPadding ? tw`p-0 md:p-0 xl:p-0 max-w-none` : "")}
	${({ hasBottomContent }) => (hasBottomContent ? tw`xl:row-start-1 xl:row-end-2` : "")}
`;

export const BottomContentWrapper = tw(SideMenuContent)`
	relative
	p-0
	max-w-none
	xl:row-start-2
	xl:row-end-3
	z-40
	fixed
	xl:relative
	bottom-0
	left-0
	right-0
	shadow-brand-main-100/10
	shadow-[0rem_-0.125rem_0.625rem_0rem]
	bg-shade-0
`;
