import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useState } from "react";
import { brandMain20, onBrandStrong } from "@themes/colors";
import { TkaH4Span, TkaP3Span } from "@themes/font-tags";
import { CertificateCardProps } from "./certificate-card.interface";
import {
	Flex,
	Header,
	HeaderTitle,
	OrderWrapper,
	StyledLine,
	Wrapper,
} from "./certificate-card.styles";
import { OrderIhkFlow } from "../order-ihk-flow/order-ihk-flow.component";

export const CertificateCard = ({ rootId, rootName, canOrder, onOrder }: CertificateCardProps) => {
	const { t } = useTkaTranslation("courseScreen");

	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleOrderOnClick = () => {
		if (onOrder) return onOrder();
		setIsModalVisible(true);
	};

	const handleOnDismiss = () => {
		setIsModalVisible(false);
	};

	return (
		<Wrapper noMaxWidth={canOrder}>
			<Card bodyPaddingInRem={1.5} backgroundColor={brandMain20} showBorder={false}>
				<Header>
					<StyledLine />
					<HeaderTitle>Zertifikat</HeaderTitle>
					<StyledLine reverse />
				</Header>
				<Flex>
					<Icon icon="certifiedCertificate" color={onBrandStrong} sizeRem={3} />
					{canOrder ? (
						<OrderWrapper>
							<TkaP3Span>
								Du hast dir dein Zertifikat “<TkaP3Span bold>{rootName}</TkaP3Span>”
								redlich verdient.
							</TkaP3Span>
							<Button label="Zertifikat anfordern" onClick={handleOrderOnClick} />
						</OrderWrapper>
					) : (
						<TkaP3Span tkaColor={onBrandStrong}>
							{t("course_screen.ihkCertificatePrefix").trim()}&nbsp;
							<TkaH4Span tkaColor={onBrandStrong}>{rootName}</TkaH4Span>
						</TkaP3Span>
					)}
				</Flex>
			</Card>
			<OrderIhkFlow rootId={rootId} isVisible={isModalVisible} onDismiss={handleOnDismiss} />
		</Wrapper>
	);
};
