/**
 * @generated SignedSource<<d701b1b138cf720a79f64f75bb54c446>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ButtonKind = "confirm" | "link" | "textCopy";
export type PageDataKind = "image" | "imageWithButton";
import { FragmentRefs } from "relay-runtime";
export type messageModalPage_PageFragment$data = {
  readonly data: {
    readonly button?: {
      readonly kind: ButtonKind;
      readonly text: string;
      readonly url?: string;
    };
    readonly element?: {
      readonly image: {
        readonly id: string;
        readonly url: string | null;
      };
    };
    readonly kind?: PageDataKind;
  };
  readonly internalTitle: string;
  readonly " $fragmentType": "messageModalPage_PageFragment";
};
export type messageModalPage_PageFragment$key = {
  readonly " $data"?: messageModalPage_PageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messageModalPage_PageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ImageElement",
  "kind": "LinkedField",
  "name": "element",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "messageModalPage_PageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "type": "ImagePage",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "button",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "type": "LinkButton",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "type": "ImageWithButtonPage",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MessageDefinitionPage",
  "abstractKey": null
};
})();

(node as any).hash = "0f9ecc00fb2d910ac5b7d030e7ea229e";

export default node;
