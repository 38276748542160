import { useFragment } from "react-relay";
import { GapText } from "@components/gap-text";
import { GapTextStatus } from "@components/gap-text/gap-text.interface";
import { MARK_MISTAKES_LEARN_ELEMENT_FRAGMENT } from "./mark-mistakes-learn-element.graphql";
import { MarkMistakesLearnElementProps } from "./mark-mistakes-learn-element.types";

export const MarkMistakesLearnElement = ({
	isUntouched,
	isInput,
	checkedIndices,
	onSnippetClick,
	getStatusForSnippetByIndex,
	learnElementFragmentRef,
}: MarkMistakesLearnElementProps) => {
	const element = useFragment(
		MARK_MISTAKES_LEARN_ELEMENT_FRAGMENT,
		learnElementFragmentRef ?? null,
	);

	const snippets = element?.snippets || [];

	const handleSnippetClick = (index: number) => {
		onSnippetClick?.(index);
	};

	const createGapText = (snippet: typeof snippets[0], index: number) => {
		const selected = checkedIndices?.includes(index) || false;
		const status = getStatusForSnippetByIndex
			? (getStatusForSnippetByIndex(index) as any)
			: GapTextStatus.Default;

		return (
			<GapText
				key={`${snippet.content}_${index}`}
				onlyBoldIfSelected
				text={snippet.content}
				status={status}
				selected={selected && (isUntouched || isInput)}
				onClick={() => handleSnippetClick(index)}
			/>
		);
	};

	return <>{snippets?.map((snippet, index) => createGapText(snippet, index))}</>;
};
