import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { AvatarWithProfileMenu } from "@components/avatar-with-profile-menu";
import { navbar_AuthViewerSchemaFragment$key } from "@relay/navbar_AuthViewerSchemaFragment.graphql";
import { navbar_Query } from "@relay/navbar_Query.graphql";
import { Path } from "@router/paths";
import { AUTH_VIEWER_SCHEMA_FRAGMENT, QUERY } from "./navbar.graphql";
import { NavbarSkeleton } from "./navbar.skeleton";
import { CenterWrapper, HideXLUp, ShowXLUp, Wrapper } from "./navbar.styles";
import { NavbarItem } from "../navbar-item";

// TODO: add-translations
export const NavbarComponent = () => {
	const theme = useTheme();

	const query = useLazyLoadQuery<navbar_Query>(QUERY, {}, { fetchPolicy: "store-or-network" });

	const auth = useFragment<navbar_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		query.Viewer.Auth,
	);

	const navigate = useNavigate();

	const handleLogoOnClick = () => {
		navigate(Path.dashboard.path);
	};

	return (
		<Wrapper>
			<button type="button" onClick={handleLogoOnClick}>
				<theme.LogoSquareComponent />
			</button>
			<CenterWrapper>
				<NavbarItem iconName="home" label="Home" path={Path.dashboard.path} />
				<NavbarItem iconName="compass" label="Entdecken" path={Path.progression.path} />
				<NavbarItem iconName="note" label="Notizen" path={Path.notes.path} />
				<HideXLUp>
					<AvatarWithProfileMenu authViewerSchemaFragmentRef={auth} />
				</HideXLUp>
			</CenterWrapper>
			<ShowXLUp>
				<AvatarWithProfileMenu authViewerSchemaFragmentRef={auth} />
			</ShowXLUp>
		</Wrapper>
	);
};

export const Navbar = withSuspense(NavbarComponent, NavbarSkeleton);
