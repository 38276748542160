import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div`
	${tw`
		absolute
		bottom-24
		left-1/2
		-translate-x-1/2
		w-[calc(100%-24px-24px)]
		flex
		flex-col
		items-end
		gap-8
		bg-shade-0
		rounded-24
	`}

	box-shadow: ${({ theme }) => theme.componentStyles?.card?.boxShadow};
`;

export const ProgressWrapper = styled.div`
	${tw`
		absolute
		w-[calc(100%+8px)]
		h-1/2
		left-1/2
		-translate-x-1/2
		-top-[4px]
		rounded-[28px_28px_3px_3px]
		bg-shade-20
		overflow-hidden
	`}
`;

export const Progress = styled.div<{ prgs?: string }>`
	${tw`
		absolute 
		bg-brand-strong-100 
		h-full
	`}

	width: ${({ prgs }) => prgs}%;
	transition: width 0.5s;
`;

export const ContentWrapper = styled.div`
	${tw`
		flex
		justify-end
		z-20
		w-full
		h-full
		p-16
		rounded-24
		bg-shade-0
	`}
`;

export const ButtonsWrapper = tw.div`
	flex
	flex-row
	justify-end
	gap-16
`;
