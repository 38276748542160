/**
 * @generated SignedSource<<c421b3945cb61002016a05b161336cd3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type NoteReminderKind = "NonRecurring" | "Recurring";
import { FragmentRefs } from "relay-runtime";
export type reminderOverview_ReminderCardInlineFragment$data = {
  readonly content: string | null;
  readonly id: string;
  readonly reminder: {
    readonly isCompleted: boolean;
    readonly kind: NoteReminderKind;
    readonly remindAt: string;
  } | null;
  readonly " $fragmentType": "reminderOverview_ReminderCardInlineFragment";
};
export type reminderOverview_ReminderCardInlineFragment$key = {
  readonly " $data"?: reminderOverview_ReminderCardInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"reminderOverview_ReminderCardInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "reminderOverview_ReminderCardInlineFragment"
};

(node as any).hash = "7296a63b809171557cfbff2492b03c43";

export default node;
