import React from "react";
import { ScreenWithNavbarLayout } from "src/layouts/screen-with-navbar-layout";
import { TkaH1Span } from "@themes/font-tags";
import { Header, NoGrowWrapper, SearchAndFiltersWrapper } from "./offers.styles";
import { FiltersSkeleton } from "./parts/filters/filters.skeleton";
import { GridViewSkeleton } from "./parts/grid-view/grid-view.skeleton";
import { SearchBarSkeleton } from "./parts/search-bar/search-bar.skeleton";

//TODO: remove-translations
export const OffersScreenSkeleton = () => {
	return (
		<ScreenWithNavbarLayout>
			<Header>
				<TkaH1Span>Angebotskatalog</TkaH1Span>
				<SearchAndFiltersWrapper>
					<SearchBarSkeleton />
					<NoGrowWrapper>
						<FiltersSkeleton />
					</NoGrowWrapper>
				</SearchAndFiltersWrapper>
			</Header>
			<GridViewSkeleton />
		</ScreenWithNavbarLayout>
	);
};
