import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import React, { useContext, useEffect } from "react";
import { readInlineData, useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { EducationalOfferCard } from "@components/educational-offer-card";
import { gridView_PublishedLearnableFragment$key } from "@relay/gridView_PublishedLearnableFragment.graphql";
import { gridView_Query } from "@relay/gridView_Query.graphql";
import { gridView_QueryFragment$key } from "@relay/gridView_QueryFragment.graphql";
import { gridViewRefetchQuery } from "@relay/gridViewRefetchQuery.graphql";
import { GridViewSkeleton } from "./grid-view.skeleton";
import { OffersScreenContext } from "../../offers.context";
import {
	PUBLISHED_LEARNABLE_INLINE_FRAGMENT,
	QUERY,
	QUERY_FRAGMENT,
} from "../grid-view/grid-view.graphql";
import { Gap, GridItem, StyledGrid } from "../grid-view/grid-view.styles";
import { Tags } from "../tags";

const GridViewComponent = () => {
	const query = useLazyLoadQuery<gridView_Query>(
		QUERY,
		{ kinds: [], tagIds: [] },
		{ fetchPolicy: "store-and-network" },
	);

	const {
		filters,
		helpers: { setProductIds },
	} = useContext(OffersScreenContext);
	const { data, refetch } = usePaginationFragment<
		gridViewRefetchQuery,
		gridView_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const learnables = data.Learnable.PublishedLearnables.edges!.map((e) =>
		readInlineData<gridView_PublishedLearnableFragment$key>(
			PUBLISHED_LEARNABLE_INLINE_FRAGMENT,
			e!.node,
		),
	);
	// TODO refactor to namespace enum -> no more magic strings

	useEffect(() => {
		setProductIds(learnables.map((o) => o.id));
	}, [data]);

	useEffect(() => {
		refetch({
			...filters,
			text: filters.text,
		});
	}, [filters]);

	return (
		<div>
			<Gap />
			<Tags queryFragmentRef={data} />
			<Gap />
			<StyledGrid>
				{learnables.map((o) => (
					<GridItem key={o.id}>
						{o.kind === "Course" ? (
							<EducationalOfferCard publishedCourseLearnableFragmentRef={o} />
						) : (
							<React.Fragment />
						)}
					</GridItem>
				))}
			</StyledGrid>
		</div>
	);
};

export const GridView = withSuspense(GridViewComponent, GridViewSkeleton);
