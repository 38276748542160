import { css } from "@styled-system/css";

export const colorPrimary20Class = css({
	color: "primary.20",
});
export const colorPrimary40Class = css({
	color: "primary.40",
});
export const colorPrimary80Class = css({
	color: "primary.80",
});
export const colorPrimary100Class = css({
	color: "primary.100",
});
export const colorSecondary20Class = css({
	color: "secondary.20",
});
export const colorSecondary40Class = css({
	color: "secondary.40",
});
export const colorSecondary80Class = css({
	color: "secondary.80",
});
export const colorSecondary100Class = css({
	color: "secondary.100",
});
export const colorSecondary120Class = css({
	color: "secondary.120",
});
export const colorOnPrimaryClass = css({
	color: "onPrimary",
});
export const colorOnSecondaryClass = css({
	color: "onSecondary",
});
export const colorShade0Class = css({
	color: "shade.0",
});
export const colorShade5Class = css({
	color: "shade.5",
});
export const colorShade10Class = css({
	color: "shade.10",
});
export const colorShade20Class = css({
	color: "shade.20",
});
export const colorShade40Class = css({
	color: "shade.40",
});
export const colorShade60Class = css({
	color: "shade.60",
});
export const colorShade80Class = css({
	color: "shade.80",
});
export const colorShade100Class = css({
	color: "shade.100",
});
export const colorSuccess0Class = css({
	color: "success.0",
});
export const colorSuccess20Class = css({
	color: "success.20",
});
export const colorSuccess100Class = css({
	color: "success.100",
});
export const colorInfo0Class = css({
	color: "info.0",
});
export const colorInfo20Class = css({
	color: "info.20",
});
export const colorInfo100Class = css({
	color: "info.100",
});
export const colorWarning0Class = css({
	color: "warning.0",
});
export const colorWarning20Class = css({
	color: "warning.20",
});
export const colorWarning100Class = css({
	color: "warning.100",
});
export const colorError0Class = css({
	color: "error.0",
});
export const colorError20Class = css({
	color: "error.20",
});
export const colorError100Class = css({
	color: "error.100",
});
export const colorTransparentClass = css({
	color: "transparent",
});
