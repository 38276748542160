import { TabBar } from "@thekeytechnology/academies-lib-webapp/components/tab-bar";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { Skeleton } from "@thekeytechnology/epic-ui";
import { TabPanel } from "primereact/tabview";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { AdvanceTreeNavigation } from "@components/advance-tree-navigation";
import { useCurrentPath } from "@hooks/use-current-path";
import { ScreenWithNavbarSidemenuLayout } from "src/layouts/screen-with-navbar-sidemenu-layout";
import { BranchNodeTabSkeleton } from "./branch-nodes-tab/branch-nodes-tab.skeleton";
import { MaterialsTabSkeleton } from "./materials-tab/materials-tab.skeleton";
import { RootOverviewTabSkeleton } from "./root-overview-tab/root-overview-tab.skeleton";

import { SideMenuHeaderWrapper, SideMenuWrapper, StickyWrapper, Wrapper } from "./root.styles";
import { getTabIndexForPath } from "./root.util";
import { RootProgressSkeleton } from "../../components/root-progress/RootProgress.skeleton";

export const RootScreenSkeleton = () => {
	const currentPath = useCurrentPath();
	const { isXLargeUp } = useWindowSize();

	const [activeIndex] = useState(getTabIndexForPath(currentPath?.route.path));

	const tabBar = (
		<TabBar activeIndex={activeIndex}>
			<TabPanel headerTemplate={<Skeleton width="5rem" />} />
			<TabPanel headerTemplate={<Skeleton width="5rem" />} />
			<TabPanel headerTemplate={<Skeleton width="5rem" />} />
		</TabBar>
	);

	return (
		<ScreenWithNavbarSidemenuLayout
			noSideMenuPadding
			canGoBack={!isXLargeUp}
			sideMenu={
				<SideMenuWrapper>
					<SideMenuHeaderWrapper>
						<RootProgressSkeleton />
						{tabBar}
					</SideMenuHeaderWrapper>
					<TabBar
						hideHeader
						renderActiveOnly={false}
						activeIndex={activeIndex}
						onTabChange={() => {
							//ignore just required from primereact to work with activeIndex
						}}
					>
						<TabPanel>
							<RootOverviewTabSkeleton />
						</TabPanel>
						<TabPanel>
							<BranchNodeTabSkeleton />
						</TabPanel>
						<TabPanel>
							<MaterialsTabSkeleton />
						</TabPanel>
					</TabBar>
				</SideMenuWrapper>
			}
			bottomContent={<AdvanceTreeNavigation />}
		>
			<Wrapper>
				{!isXLargeUp && <RootProgressSkeleton />}
				{!isXLargeUp && <StickyWrapper>{tabBar}</StickyWrapper>}
				<Outlet />
			</Wrapper>
		</ScreenWithNavbarSidemenuLayout>
	);
};
