/**
 * @generated SignedSource<<4715dfa1c7bfa33e91c8b0263a782d6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PageDataKind = "image" | "imageWithButton";
import { FragmentRefs } from "relay-runtime";
export type messagePreviewCard_MessageFragment$data = {
  readonly hasSeen: boolean;
  readonly messageDefinition: {
    readonly id: string;
    readonly pages: ReadonlyArray<{
      readonly data: {
        readonly element?: {
          readonly image: {
            readonly url: string | null;
          };
        };
        readonly kind: PageDataKind;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"messageModal_MessageFragment">;
  readonly " $fragmentType": "messagePreviewCard_MessageFragment";
};
export type messagePreviewCard_MessageFragment$key = {
  readonly " $data"?: messagePreviewCard_MessageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messagePreviewCard_MessageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ImageElement",
    "kind": "LinkedField",
    "name": "element",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "image",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "messagePreviewCard_MessageFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "messageModal_MessageFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSeen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageDefinition",
      "kind": "LinkedField",
      "name": "messageDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageDefinitionPage",
          "kind": "LinkedField",
          "name": "pages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "ImagePage",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v0/*: any*/),
                  "type": "ImageWithButtonPage",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Message",
  "abstractKey": null
};
})();

(node as any).hash = "a995ed5314ef0844bbf8a44e3af18ea3";

export default node;
