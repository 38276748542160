import { useCallback, useMemo } from "react";
import { useDrop } from "react-dnd";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { GapInputDropdown } from "@components/gap-input-dropdown";
import { P2Span } from "@themes/font-tags";
import { CLOZE_TEXT_LEARN_ELEMENT_FRAGMENT } from "./cloze-text-learn-element.graphql";
import { gapTextWrapperClass } from "./cloze-text-learn-element.styles";
import type { ClozeTextLearnElementProps, DragObject } from "./cloze-text-learn-element.types";

export const ClozeTextLearnElement = ({
	disabled,
	answerIds,
	selectedCellIndex,
	setSelectedCellIndex,
	availableAnswers,
	getStatusForIndex,
	setSelectedAnswersId,
	learnElementFragmentRef,
}: ClozeTextLearnElementProps) => {
	const element = useFragment(CLOZE_TEXT_LEARN_ELEMENT_FRAGMENT, learnElementFragmentRef ?? null);

	const parts = useMemo(() => element?.parts || [], [element?.parts]);
	const shuffledAnswers = element?.shuffledAnswers || [];
	const clozeIndexMap = useMemo(() => {
		const map: Record<number, number> = {};
		parts.forEach((part, index) => {
			if (part.kind === "cloze") {
				map[index] = Object.keys(map).length;
			}
		});
		return map;
	}, [parts]);
	const requiredSelections = useMemo(
		() => parts.filter((part) => part.kind === "cloze").length,
		[parts],
	);

	const handleTextGapOnClick = (index: number) => {
		if (disabled) return;
		setSelectedCellIndex?.(index);
	};

	const handleAnswerOnClick = useCallback(
		(id: string) => {
			if (selectedCellIndex === undefined) return;
			if (selectedCellIndex < requiredSelections - 1) {
				setSelectedCellIndex?.(selectedCellIndex + 1);
			} else {
				setSelectedCellIndex?.(undefined);
			}
			setSelectedAnswersId?.((answerIds) => {
				const answerSlice = answerIds.slice();
				answerSlice[selectedCellIndex] = id;
				return answerSlice;
			});
		},
		[selectedCellIndex, setSelectedAnswersId, requiredSelections, setSelectedCellIndex],
	);

	const ClozePart = ({ index }: { index: number }) => {
		const handleAnswerDrop = (item: DragObject) => {
			if (!item && !selectedCellIndex) return;
			const targetCellIndex = clozeIndexMap[index];
			setSelectedCellIndex?.(targetCellIndex);
			setSelectedAnswersId?.((answerIds) => {
				const updatedAnswerIds = [...answerIds];
				updatedAnswerIds[targetCellIndex] = item.id;
				return updatedAnswerIds;
			});
		};

		const [{ isOver }, drop] = useDrop<DragObject, void, { isOver: boolean }>(
			() => ({
				accept: "Answer",
				drop: (item) => handleAnswerDrop(item),
				collect: (monitor) => ({
					isOver: !!monitor.isOver(),
				}),
			}),
			[],
		);

		const clozeIndex = clozeIndexMap[index] || 0;
		const clozeAnswerId = answerIds?.[clozeIndex];
		const answer = shuffledAnswers.find((a) => a.id === clozeAnswerId);

		return (
			<div className={gapTextWrapperClass}>
				<GapInputDropdown
					key={`${answer?.text}_${index}`}
					status={getStatusForIndex?.(index)}
					selected={selectedCellIndex === clozeIndex}
					text={answer?.text}
					useMinWidth={!answer?.text}
					onClick={(e) => {
						e.stopPropagation();
						handleTextGapOnClick(clozeIndex);
					}}
					onAnswerSelect={handleAnswerOnClick}
					availableAnswers={availableAnswers}
					isOver={isOver}
					drop={drop}
				/>
			</div>
		);
	};

	return (
		<>
			{parts.map((part, index) => {
				return match(part.kind)
					.with("text", () => <P2Span key={`${part.text}_${index}`}>{part.text}</P2Span>)
					.with("cloze", () => <ClozePart key={`${part.text}_${index}`} index={index} />)
					.exhaustive();
			})}
		</>
	);
};
