import { graphql } from "react-relay";

export const INSTRUCTORS_CONNECTION_FRAGMENT = graphql`
	fragment instructorsComponent_InstructorsConnectionFragment on InstructorsConnection {
		edges {
			node {
				id
				...instructorCard_InstructorFragment
			}
		}
	}
`;
