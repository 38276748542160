import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { InputText } from "@thekeytechnology/epic-ui";
import { useContext } from "react";
import { shade100, shade40 } from "@themes/colors";
import { SearchBarProps } from "./search-bar.types";
import { OffersScreenContext } from "../../offers.context";

export const SearchBar = ({ onClick: handleOnClick }: SearchBarProps) => {
	const { t } = useTkaTranslation("offers");
	const placeholder = t("Suchen");
	const {
		filters: { text: value },
		helpers: { setText },
	} = useContext(OffersScreenContext);
	const handleOnChange = (e?: string) => {
		setText(e);
	};
	const { isMediumUp } = useWindowSize();
	const iconColor = isMediumUp ? shade40 : shade100;
	const iconSize = isMediumUp ? 1.5 : 0.75;
	if (!isMediumUp)
		return (
			<Icon icon={"search"} sizeRem={iconSize} color={iconColor} onClick={handleOnClick} />
		);

	return (
		<InputText
			icon={"search"}
			placeholder={placeholder + "..."}
			value={value}
			onChange={handleOnChange}
		/>
	);
};
