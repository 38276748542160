import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { IconWrapper } from "./toggle-extend-collapse.styles";
import { ToggleExtendCollapseProps } from "./toggle-extend-collapse.types";

export const ToggleExtendCollapse = ({
	isCollapsed,
	onToggle: handleOnClick,
}: ToggleExtendCollapseProps) => {
	return (
		<IconWrapper isCollapsed={isCollapsed} onClick={handleOnClick}>
			<Icon sizeRem={0.5} icon={"playArrow"} />
		</IconWrapper>
	);
};
