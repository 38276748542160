import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AdvanceTreeNavigation } from "@components/advance-tree-navigation";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";

import { ContentOverview } from "../content-overview/content-overview.component";

export const ContentOverviewMobile = () => {
	const navigate = useNavigate();
	const { isXLargeUp } = useWindowSize();

	useEffect(() => {
		if (isXLargeUp) {
			navigate(-1);
		}
	}, [isXLargeUp]);

	return (
		<ScreenWithNavbarLayout canGoBack bottomContent={<AdvanceTreeNavigation />}>
			<ContentOverview showDetails />
		</ScreenWithNavbarLayout>
	);
};
