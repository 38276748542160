import { DialogTemplate } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import { ResponsiveBottomSheetModal } from "@thekeytechnology/academies-lib-webapp/components/responsive-bottom-sheet-modal";
import { H1Span } from "@thekeytechnology/epic-ui";
import { useRef, type FC } from "react";
import { useFragment } from "react-relay";
import { ReminderForm, type ReminderFormRef } from "@components/reminder-form";
import { REMINDER_NOTE_FRAGMENT } from "./upsert-reminder-modal.graphql";
import { wrapperClass } from "./upsert-reminder-modal.styles";
import type { UpsertReminderModalProps } from "./upsert-reminder-modal.types";

export const UpsertReminderModal: FC<UpsertReminderModalProps> = ({
	isVisible = true,
	onDismiss,
	onSuccess,
	noteFragmentRef,
}) => {
	const reminderFormRef = useRef<ReminderFormRef>(null);

	const data = useFragment(REMINDER_NOTE_FRAGMENT, noteFragmentRef ?? null);

	const isUpdate = Boolean(data);

	const handleDismiss = () => {
		onDismiss?.();
	};

	const handleSuccess = () => {
		onSuccess?.();
	};

	const handleSubmit = async () => {
		const isValid = await reminderFormRef.current?.validate();

		if (isValid) {
			await reminderFormRef.current?.submit();
		}
	};

	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} onDismiss={handleDismiss}>
			<DialogTemplate
				maxWidthRem={45}
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={handleDismiss}
				primaryButtonLabel={isUpdate ? "Speichern" : "Erstellen"}
				primaryButtonIconName="arrowRight"
				onPrimaryButtonClick={handleSubmit}
			>
				<div data-no-selection-menu className={wrapperClass}>
					<H1Span>{isUpdate ? "Erinnerung bearbeiten" : "Neue Erinnerung"}</H1Span>
					<div>
						<ReminderForm
							ref={reminderFormRef}
							noteFragmentRef={data}
							onSuccess={handleSuccess}
						/>
					</div>
				</div>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
