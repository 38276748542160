import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import React, { useEffect, useRef, useState } from "react";
import { Header } from "@components/header";
import { HubspotGlobalStyle } from "@components/hubspot-style/hubspot-style.styles";
import { MessageModalInjector } from "@components/message-modal-injector/message-modal-injector.component";
import { Navbar } from "@components/navbar";
import {
	BottomContentWrapper,
	ChildrenWrapper,
	NavbarWrapper,
	ScreenWrapper,
	ScrollWrapper,
	Wrapper,
} from "./screen-with-navbar-layout.styles";
import { ScreenWithNavbarLayoutProps } from "./screen-with-navbar-layout.types";

export const ScreenWithNavbarLayout = ({
	headerLeftIcon,
	canGoBack,
	noPadding = false,
	bottomContent,
	children,
}: ScreenWithNavbarLayoutProps) => {
	const bottomContentRef = useRef<HTMLDivElement>(null);
	const hasBottomContent = !!bottomContent;

	const { isXLargeUp } = useWindowSize();
	const hideNavBar = !isXLargeUp && bottomContent;

	const [bottomPadding, setBottomPadding] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (!hasBottomContent || !bottomContentRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			setBottomPadding((padding) => {
				if (padding !== bottomContentRef.current?.clientHeight) {
					return bottomContentRef.current?.clientHeight;
				}
				return padding;
			});
		});
		resizeObserver.observe(bottomContentRef.current);
		return () => resizeObserver.disconnect();
	}, [hasBottomContent]);

	const hubspotBottomMargin = bottomContent ? (bottomPadding ?? 0) / 16 : 4.7;

	return (
		<Wrapper>
			<MessageModalInjector />
			{!hideNavBar && (
				<NavbarWrapper>
					<Navbar />
				</NavbarWrapper>
			)}
			<ScreenWrapper>
				<ScrollWrapper>
					<Header canGoBack={canGoBack} leftIcon={headerLeftIcon} />
					<ChildrenWrapper noPadding={noPadding}>{children}</ChildrenWrapper>
				</ScrollWrapper>
				{bottomContent && (
					<BottomContentWrapper ref={bottomContentRef}>
						{bottomContent}
					</BottomContentWrapper>
				)}
				{(!isXLargeUp || bottomContent) && (
					<HubspotGlobalStyle bottomMarginRem={hubspotBottomMargin} />
				)}
			</ScreenWrapper>
		</Wrapper>
	);
};
