import tw from "twin.macro";
import { TkaH1Span, TkaP2Span } from "@themes/font-tags";

export const Wrapper = tw.div`
  w-full
  h-full
  flex-col
  items-center
  justify-center
  bg-brand-main-100
  px-64
  hidden
  xxl:flex
`;

export const Quote = tw.blockquote`
  flex
  flex-col
  gap-64
`;

export const QuoteWrapper = tw.div`
  flex
  flex-col
  gap-16
  border-l-[3px]
  border-brand-strong-100
  pl-20
`;

export const QuoteHeadline = tw(TkaH1Span)`
  text-shade-0
  leading-[28px]
  max-w-[50rem]
  font-medium
`;

export const QuoteAuthor = tw(TkaP2Span)`
  text-shade-0
  text-[0.875rem]
  font-medium
`;

export const MarketingImage = tw.img`
  border-[10px]
  border-solid
  border-shade-0
  rounded-[30px]
`;
