import { graphql } from "react-relay";

export const MARK_MISTAKES_LEARN_ELEMENT_FRAGMENT = graphql`
	fragment markMistakesLearnElement_MarkMistakesLearnElementFragment on MarkMistakesLearnElement {
		id
		title
		snippets {
			content
		}
	}
`;
