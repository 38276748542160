import tw from "twin.macro";

export const Wrapper = tw.div`
	h-full
	grid
	grid-rows-[1fr_min-content]
	xl:grid-cols-[min-content_1fr]
	bg-shade-5
`;

export const NavbarWrapper = tw.div`
	row-start-2
	row-end-2
	xl:row-auto
	shadow-brand-main-100/10
	shadow-[0rem_-0.125rem_0.625rem_0rem]
	xl:shadow-none
	z-10
`;

export const ScreenWrapper = tw.div`
	relative
	row-start-1
	row-end-1
	xl:row-auto
	overflow-y-auto
	flex
	flex-col
`;

export const ChildrenWrapper = tw.div`
	xl:overflow-hidden
	flex
	flex-col
	flex-1
`;
