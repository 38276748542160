import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { ChangeEvent, useRef } from "react";
import { FileSelectButtonProps } from "./file-select-button.interface";
import { StyledInput } from "./file-select-button.styles";

export const FileSelectButton = ({ onFileSelected, accept, ...props }: FileSelectButtonProps) => {
	const hiddenFileInput = useRef<HTMLInputElement>(null);

	const handleOnClick = () => {
		hiddenFileInput.current?.click();
	};
	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		file && onFileSelected?.(file);
	};
	return (
		<>
			<Button {...props} onClick={handleOnClick} />
			<StyledInput
				type="file"
				accept={accept}
				ref={hiddenFileInput}
				onChange={handleFileChange}
			/>
		</>
	);
};
