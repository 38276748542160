import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { TabBar } from "@thekeytechnology/academies-lib-webapp/components/tab-bar";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { TabPanel, type TabViewTabChangeEvent } from "primereact/tabview";
import { useState } from "react";
import { useLazyLoadQuery, useRefetchableFragment } from "react-relay";
import { UpsertNoteModal } from "@components/upsert-note-modal";
import { UpsertReminderModal } from "@components/upsert-reminder-modal";
import { ScreenWithProfileMenuLayout } from "@layouts/screen-with-profile-menu-layout";
import { notes_NotesFragment$key } from "@relay/notes_NotesFragment.graphql";
import { notes_NotesQuery } from "@relay/notes_NotesQuery.graphql";
import { notes_RefetchQuery } from "@relay/notes_RefetchQuery.graphql";
import { H1Span } from "@themes/font-tags";
import { QUERY, QUERY_FRAGMENT } from "./notes.graphql";
import { NotesScreenSkeleton } from "./notes.skeleton";
import { bottonBarWrapper, header } from "./notes.styles";
import { NotesOverview } from "./parts/notes-overview";
import { ReminderOverviewComponent } from "./parts/reminder-overview";

export const NotesScreenComponent = () => {
	const query = useLazyLoadQuery<notes_NotesQuery>(QUERY, {});

	const [data, refetch] = useRefetchableFragment<notes_RefetchQuery, notes_NotesFragment$key>(
		QUERY_FRAGMENT,
		query,
	);

	const [activeIndex, setActiveIndex] = useState(0);
	const [upsertNoteModalOpen, setUpsertNoteModalOpen] = useState(false);
	const [upsertReminderModalOpen, setUpsertReminderModalOpen] = useState(false);

	const handleRefetch = () => {
		refetch(
			{},
			{
				fetchPolicy: "store-and-network",
			},
		);
	};

	const handleOnTabChange = (event: TabViewTabChangeEvent) => {
		setActiveIndex(event.index);
	};

	const handleClickCreateNote = () => {
		setUpsertNoteModalOpen(true);
	};

	const handleUpsertNoteModalDismiss = () => {
		setUpsertNoteModalOpen(false);
	};

	const handleNoteUpsertSuccess = () => {
		setUpsertNoteModalOpen(false);
		handleRefetch();
	};

	const handleClickCreateReminder = () => {
		setUpsertReminderModalOpen(true);
	};

	const handleUpsertReminderModalDismiss = () => {
		setUpsertReminderModalOpen(false);
	};

	const handleReminderUpsertSuccess = () => {
		setUpsertReminderModalOpen(false);
		handleRefetch();
	};

	const handleDeleteReminder = () => {
		handleRefetch();
	};

	return (
		<>
			{upsertNoteModalOpen && (
				<UpsertNoteModal
					onDismiss={handleUpsertNoteModalDismiss}
					onSuccess={handleNoteUpsertSuccess}
				/>
			)}
			{upsertReminderModalOpen && (
				<UpsertReminderModal
					onDismiss={handleUpsertReminderModalDismiss}
					onSuccess={handleReminderUpsertSuccess}
				/>
			)}
			<ScreenWithProfileMenuLayout
				bottomContent={
					<div className={bottonBarWrapper}>
						{activeIndex === 0 ? (
							<Button
								label="Neue Notiz"
								iconName="add"
								onClick={handleClickCreateNote}
							/>
						) : (
							<Button
								label="Neue Erinnerung"
								iconName="add"
								onClick={handleClickCreateReminder}
							/>
						)}
					</div>
				}
			>
				<div className={header}>
					<H1Span>Notizen & Erinnerungen</H1Span>
					<TabBar
						activeIndex={activeIndex}
						onTabChange={handleOnTabChange}
						disableMaxParentHeight
					>
						<TabPanel header="Notizen">
							<NotesOverview notesFragmentRef={data.Note.Note} />
						</TabPanel>
						<TabPanel header="Erinnerungen">
							<ReminderOverviewComponent
								onDeleteReminder={handleDeleteReminder}
								reminderFragmentRef={data.Note.Note}
							/>
						</TabPanel>
					</TabBar>
				</div>
			</ScreenWithProfileMenuLayout>
		</>
	);
};

export const NotesScreen = withSuspense(NotesScreenComponent, NotesScreenSkeleton);
