import { CSSProperties } from "react";
import { Link } from "react-scroll";
import styled, { css } from "styled-components";
import { shade0, shade10 } from "@themes/colors";

export const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.spacing12};
`;

export const LinkCss = css`
	margin: 0 ${({ theme }) => theme.spacing.spacing24} 0;
	padding: ${({ theme }) => theme.spacing.spacing16};
	border: 1px solid ${shade10};
	border-radius: ${({ theme }) => theme.borderRadius?.borderRadius24};
	cursor: pointer;
`;

export const StyledLink = styled(Link)`
	${LinkCss}
`;

export const StyledLinkMock = styled.div`
	${LinkCss}
`;

export const ActiveItem: CSSProperties = {
	backgroundColor: shade0.getColor(),
	border: `1px solid ${shade0.getColor()}`,
	marginRight: 0,
	borderBottomRightRadius: 0,
	borderTopRightRadius: 0,
};
