import { DragDropContext, Droppable, Draggable, DroppableStateSnapshot } from "@hello-pangea/dnd";
import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useContext, useMemo } from "react";
import { useFragment } from "react-relay";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import {
	getBackgroundColor,
	getBorderColor,
	getDragingIconColor,
	getIconColor,
} from "@components/order-element/order-element.util";
import { H3Span, P2Span } from "@themes/font-tags";
import { ORDER_LEARN_ELEMENT_FRAGMENT } from "./order-learn-element.graphql";
import {
	cardItemWrapperClass,
	draggableWrapperClass,
	listClass,
	numberClass,
	textClass,
} from "./order-learn-element.styles";
import type { OrderElementMap, OrderLearnElementProps } from "./order-learn-element.types";

export const OrderLearnElement = ({
	dragDisabled,
	elementIds,
	getStatusForIndexAndElementId,
	onDrop,
	learnElementFragmentRef,
}: OrderLearnElementProps) => {
	const { isModalVisible } = useContext(ContentSubmissionModalContext);

	const element = useFragment(ORDER_LEARN_ELEMENT_FRAGMENT, learnElementFragmentRef ?? null);

	const elementsMappedById: OrderElementMap = useMemo(() => {
		return (
			element?.orderItems?.reduce(
				(current, value) => ({ ...current, [value.id]: value }),
				{},
			) || {}
		);
	}, [element?.orderItems]);

	const handleDrop = (droppedItem: any) => {
		onDrop?.(droppedItem);
	};

	const items = elementIds ? elementIds : element?.orderItems?.map((e) => e.id) || [];

	const createOrderItem = (id: string, index: number, listSnapshot: DroppableStateSnapshot) => {
		const item = elementsMappedById[id];
		const status = getStatusForIndexAndElementId
			? getStatusForIndexAndElementId(index, item.id)
			: "default";
		const showIcon = status === "correct";

		return (
			<Draggable
				key={item.id}
				draggableId={item.id}
				index={index}
				isDragDisabled={dragDisabled}
			>
				{(provided, snapshot) => (
					<div
						className={draggableWrapperClass}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}
					>
						<Card
							bodyPaddingInRem={1}
							backgroundColor={getBackgroundColor(status)}
							borderColor={getBorderColor(
								listSnapshot.isDraggingOver,
								snapshot.isDragging,
								isModalVisible,
								status,
							)}
						>
							<div className={cardItemWrapperClass}>
								<Icon
									sizeRem={1}
									color={getDragingIconColor(
										listSnapshot.isDraggingOver,
										snapshot.isDragging,
										isModalVisible,
										status,
									)}
									icon="dragDrop"
								/>
								<H3Span
									className={numberClass({
										darker:
											(!listSnapshot.isDraggingOver || snapshot.isDragging) &&
											!isModalVisible,
										status: status as "correct" | undefined,
									})}
								>
									{index + 1}
								</H3Span>
								<P2Span
									className={textClass({
										darker: !isModalVisible,
										status: status as "correct" | "actuallyCorrect" | undefined,
									})}
								>
									{item.text}
								</P2Span>
								{showIcon && (
									<Icon
										sizeRem={1.5}
										icon="correct"
										color={getIconColor(status)}
									/>
								)}
							</div>
						</Card>
					</div>
				)}
			</Draggable>
		);
	};

	return (
		<DragDropContext onDragEnd={handleDrop}>
			<Droppable droppableId="dnd-list">
				{(provided, listSnapshot) => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						<div className={listClass}>
							{items?.map((id, index) => createOrderItem(id, index, listSnapshot))}
							{provided.placeholder}
						</div>
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};
