/**
 * @generated SignedSource<<48e80b9230485823e7b1947414e96519>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type goToNextElementButton_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly canGoToNext?: boolean;
    readonly currentElementState?: {
      readonly element: {
        readonly id: string;
      };
    } | null;
  };
  readonly id: string;
  readonly " $fragmentType": "goToNextElementButton_ContentSubmissionFragment";
};
export type goToNextElementButton_ContentSubmissionFragment$key = {
  readonly " $data"?: goToNextElementButton_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"goToNextElementButton_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "goToNextElementButton_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "canGoToNext",
              "storageKey": null
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "4567e16e8dcf2f9722523e3ea03ada3e";

export default node;
