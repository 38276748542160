import { ButtonSkeleton } from "@thekeytechnology/academies-lib-webapp/components/button";
import { ButtonsWrapper, Wrapper } from "./bottom-navigation-template.styles";

export const BottomNavigationTemplateSkeleton = ({
	showSecondaryButton,
}: {
	showSecondaryButton?: boolean;
}) => {
	return (
		<Wrapper>
			<ButtonsWrapper>
				{showSecondaryButton && <ButtonSkeleton iconOnly />}
				<ButtonSkeleton />
			</ButtonsWrapper>
		</Wrapper>
	);
};
