import { Skeleton } from "@thekeytechnology/epic-ui";
import { useTheme } from "styled-components";
import tw from "twin.macro";
import { ListWrapper } from "./focus-content-menu.styles";

export const FocusContentMenuSkeleton = () => {
	const theme = useTheme();

	return (
		<ListWrapper>
			<ItemWrapper>
				<Skeleton
					width="100%"
					height="2.87rem"
					borderRadius={theme.borderRadius?.borderRadius10}
				/>
			</ItemWrapper>
			<ItemWrapper>
				<Skeleton
					width="100%"
					height="2.87rem"
					borderRadius={theme.borderRadius?.borderRadius10}
				/>
			</ItemWrapper>
			<ItemWrapper>
				<Skeleton
					width="100%"
					height="2.87rem"
					borderRadius={theme.borderRadius?.borderRadius10}
				/>
			</ItemWrapper>
			<ItemWrapper>
				<Skeleton
					width="100%"
					height="2.87rem"
					borderRadius={theme.borderRadius?.borderRadius10}
				/>
			</ItemWrapper>
		</ListWrapper>
	);
};

const ItemWrapper = tw.div`
	mx-24
`;
