import { graphql } from "react-relay";

export const FILE_LEARN_ELEMENT_FRAGMENT = graphql`
	fragment imageLearnElement_ImageLearnElementFragment on FileLearnElement {
		id
		title
		file {
			url
		}
	}
`;
