import {
	getBranchNodeNumberFromIndexPath,
	getContentNodeNumberFromIndexPath,
} from "@thekeytechnology/academies-lib-webapp/utils";
import { parseHtml } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import { graphql } from "react-relay";
import React from "react";
import { useFragment } from "react-relay";
import { ContentSubmissionModalContextProvider } from "@components/content-submission-modal-context-provider";
import { ContentSubmissionNavigation } from "@components/content-submission-navigation";
import { UploadElement } from "@components/upload-element";
import { asyncContentSubmissionScreen_ContentSubmissionFragment$key } from "@relay/asyncContentSubmissionScreen_ContentSubmissionFragment.graphql";
import { TkaP2Span, TkaH1Span } from "@themes/font-tags";
import { spacing12, spacing4 } from "@themes/spacing";
import { AsyncContentSubmissionProps } from "./async-content-submission.interface";
import { FlexColumn, VerticalSpace } from "./async-content-submission.style";
import { ScreenSidemenuLayout } from "../../layouts/screen-sidemenu-layout";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment asyncContentSubmissionScreen_ContentSubmissionFragment on ContentSubmission {
		id
		learnOpportunity {
			structureDefinition {
				title
			}
			description
			indexPath
		}
		definition {
			... on PassedAsyncContentSubmissionDefinition {
				element {
					id
					elementKind
				}
			}
			... on ActiveAsyncContentSubmissionDefinition {
				element {
					id
					elementKind
				}
			}
		}
		...uploadElement_ContentSubmissionFragment
		...contentSubmissionNavigation_ContentSubmissionFragment
		...ContentSubmissionModalContextProvider_ContentSubmissionFragment
	}
`;

export const AsyncContentSubmission = ({
	contentSubmissionFragmentRef,
	refetch,
}: AsyncContentSubmissionProps) => {
	const contentSubmission =
		useFragment<asyncContentSubmissionScreen_ContentSubmissionFragment$key>(
			CONTENT_SUBMISSION_FRAGMENT,
			contentSubmissionFragmentRef,
		);

	const learnOpportunity = contentSubmission!.learnOpportunity;
	const indexPath = learnOpportunity!.indexPath;
	const title = learnOpportunity!.structureDefinition!.title;
	const description = parseHtml(learnOpportunity!.description || "");
	const path = `Lektion ${getBranchNodeNumberFromIndexPath(
		indexPath,
	)}.${getContentNodeNumberFromIndexPath(indexPath)}`;

	const elementId = contentSubmission?.definition?.element?.id;
	const elementKind = contentSubmission?.definition?.element?.elementKind;

	const element = (() => {
		switch (elementKind) {
			case "Upload":
				return (
					<UploadElement
						contentSubmissionFragmentRef={contentSubmission}
						refetch={refetch}
					/>
				);
			default:
				return <React.Fragment />;
		}
	})();

	if (!contentSubmission) return <React.Fragment />;
	return (
		<ContentSubmissionModalContextProvider
			hideBackButton
			contentSubmissionFragmentRef={contentSubmission}
		>
			<ScreenSidemenuLayout
				canGoBack
				headerLeftIconName="close"
				sideMenu={
					<FlexColumn>
						<TkaP2Span>{path}</TkaP2Span>
						<VerticalSpace spacing={spacing4} />
						<TkaH1Span>{title}</TkaH1Span>
						<VerticalSpace spacing={spacing12} />
						<TkaP2Span>{description}</TkaP2Span>
					</FlexColumn>
				}
				bottomContent={
					<ContentSubmissionNavigation contentSubmissionFragmentRef={contentSubmission} />
				}
			>
				<React.Fragment key={elementId}>{element}</React.Fragment>
			</ScreenSidemenuLayout>
		</ContentSubmissionModalContextProvider>
	);
};
