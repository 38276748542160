import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { FC } from "react";
import { useFragment } from "react-relay";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { NoteCard } from "@components/note-card";
import { warning100, warning20 } from "@themes/colors";
import { QUERY_FRAGMENT } from "./notes-overview.graphql";
import { NotesOverviewSkeleton } from "./notes-overview.skeleton";
import { grid } from "./notes-overview.styles";
import { NotesOverviewProps } from "./notes-overview.types";

export const NotesOverviewComponent: FC<NotesOverviewProps> = ({ notesFragmentRef }) => {
	const query = useFragment(QUERY_FRAGMENT, notesFragmentRef);

	const notes = query?.edges?.filter((edge) => edge?.node.reminder === null) || [];

	return (
		<>
			<EmptyPlaceholder
				isVisible={notes?.length === 0}
				iconName="note"
				iconColor={warning100}
				iconBackgroundColor={warning20}
				title="Hier findest du deine Notizen"
				subtitle="Du kannst jederzeit Notizen erstellen und einsehen. Indem du einen Textabschnitt markierst, hast du auch die Möglichkeit, deine Notizen direkt zu erstellen."
			>
				<div className={grid}>
					{notes.map((note) => (
						<NoteCard key={note?.node.id} noteFragmentRef={note?.node} />
					))}
				</div>
			</EmptyPlaceholder>
		</>
	);
};

export const NotesOverview = withSuspense(NotesOverviewComponent, NotesOverviewSkeleton);
