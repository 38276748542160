/**
 * @generated SignedSource<<b573523e696a67edfccaf78e77e24b66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type reminderCard_ReminderNoteFragmentRefetch$variables = {
  id: string;
};
export type reminderCard_ReminderNoteFragmentRefetch$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"reminderCard_ReminderNoteFragment">;
  } | null;
};
export type reminderCard_ReminderNoteFragmentRefetch = {
  response: reminderCard_ReminderNoteFragmentRefetch$data;
  variables: reminderCard_ReminderNoteFragmentRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reminderCard_ReminderNoteFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "reminderCard_ReminderNoteFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reminderCard_ReminderNoteFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "reminder",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCompleted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remindAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Note",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ed69d47bfdcc4ef598fe3d5510ed4b3",
    "id": null,
    "metadata": {},
    "name": "reminderCard_ReminderNoteFragmentRefetch",
    "operationKind": "query",
    "text": "query reminderCard_ReminderNoteFragmentRefetch(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...reminderCard_ReminderNoteFragment\n    id\n  }\n}\n\nfragment reminderCard_ReminderNoteFragment on Note {\n  __typename\n  id\n  content\n  reminder {\n    __typename\n    kind\n    isCompleted\n    remindAt\n  }\n  ...upsertReminderModal_ReminderNoteFragment\n}\n\nfragment reminderForm_QueryFragment on Note {\n  id\n  content\n  reminder {\n    __typename\n    kind\n    remindAt\n    isCompleted\n  }\n}\n\nfragment upsertReminderModal_ReminderNoteFragment on Note {\n  id\n  ...reminderForm_QueryFragment\n}\n"
  }
};
})();

(node as any).hash = "08e37f3738811049afcd3ff4f609bac4";

export default node;
