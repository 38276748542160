import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { HubspotGlobalStyle } from "@components/hubspot-style/hubspot-style.styles";
import { Header } from "src/components/header";
import { Navbar } from "src/components/navbar";
import { SidemenuLayout } from "src/layouts/sidemenu-layout";
import {
	ChildrenWrapper,
	NavbarWrapper,
	ScreenWrapper,
	Wrapper,
} from "./screen-with-navbar-sidemenu-layout.styles";
import { ScreenWithNavbarSidemenuLayoutProps } from "./screen-with-navbar-sidemenu-layout.types";

export const ScreenWithNavbarSidemenuLayout = ({
	canGoBack,
	onBackClick,
	headerLeftIcon,
	noPadding,
	noSideMenuPadding,
	noContentPadding,
	contentContainerId,
	sideMenu,
	bottomContent,
	keepSidemenuContentOnMobile,
	children,
}: ScreenWithNavbarSidemenuLayoutProps) => {
	const { isXLargeUp } = useWindowSize();
	const hideNavBar = !isXLargeUp && bottomContent;
	return (
		<Wrapper>
			{!hideNavBar && (
				<NavbarWrapper>
					<Navbar />
				</NavbarWrapper>
			)}
			<ScreenWrapper>
				<Header
					canGoBack={canGoBack}
					onLeftItemClick={onBackClick}
					leftIcon={headerLeftIcon}
				/>
				<ChildrenWrapper>
					<SidemenuLayout
						noPadding={noPadding}
						noSideMenuPadding={noSideMenuPadding}
						noContentPadding={noContentPadding}
						contentContainerId={contentContainerId}
						sideMenu={sideMenu}
						bottomContent={bottomContent}
						keepSidemenuContentOnMobile={keepSidemenuContentOnMobile}
					>
						{children}
					</SidemenuLayout>
				</ChildrenWrapper>
			</ScreenWrapper>
			{!isXLargeUp && !bottomContent && <HubspotGlobalStyle />}
		</Wrapper>
	);
};
