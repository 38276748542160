import { MaterialCard } from "@thekeytechnology/academies-lib-webapp/components/material-card";
import { useMemo } from "react";
import { MaterialCardsCollectionProps } from "./material-cards-collection.interface";
import { MainWrapper } from "./material-cards-collection.styles";

export const MaterialCardsCollection = ({ cards, rows = 2 }: MaterialCardsCollectionProps) => {
	const cardsList = useMemo(
		() =>
			cards.map((card) => (
				<MaterialCard key={`${card.title}_${card.description}`} {...card} bodyPadding={0} />
			)),
		[cards],
	);
	return <MainWrapper rows={rows}>{cardsList}</MainWrapper>;
};
