import { Card } from "@thekeytechnology/academies-lib-webapp/components/card";
import { Checkbox } from "@thekeytechnology/academies-lib-webapp/components/checkbox";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { RadioButton } from "@thekeytechnology/academies-lib-webapp/components/radio-button";
import { shade100 } from "@themes/colors";
import {
	multipleChoiceTextCheckboxIconMappings,
	multipleChoiceTextRadioIconMappings,
	multipleChoiceTextStyleMappings,
} from "./multiple-choice-gap-text.const";
import {
	MultipleChoiceGapTextProps,
	MultipleChoiceGapTextStatus,
} from "./multiple-choice-gap-text.interface";
import { AnswerLetter, AnswerText, SpacedContentsDiv } from "./multiple-choice-gap-text.styles";
import {
	convertMultipleChoiceStatusToCheckboxStatus,
	convertMultipleChoiceStatusToRadioButtonStatus,
} from "./multiple-choice-gap-text.util";

export const MultipleChoiceGapText = ({
	text,
	isRadio = false,
	answerLetter,
	status,
	checked,
	onClick,
}: MultipleChoiceGapTextProps) => {
	const mapping = multipleChoiceTextStyleMappings[status];
	const radioButtonStyle = multipleChoiceTextRadioIconMappings[status];
	const checkboxStyle = multipleChoiceTextCheckboxIconMappings[status];
	const radioStatus = convertMultipleChoiceStatusToRadioButtonStatus(status);
	const checkboxStatus = convertMultipleChoiceStatusToCheckboxStatus(status);
	const borderColor =
		status === MultipleChoiceGapTextStatus.Default && checked ? shade100 : mapping.borderColor;
	return (
		<Card
			bodyPaddingInRem={1}
			backgroundColor={mapping.backgroundColor}
			showBorder={true}
			borderColor={borderColor}
			hasCursorPointer={status === MultipleChoiceGapTextStatus.Default}
			onClick={status === MultipleChoiceGapTextStatus.Default ? onClick : undefined}
		>
			<SpacedContentsDiv>
				{answerLetter && (
					<AnswerLetter tkaColor={mapping.textColor}>{answerLetter}</AnswerLetter>
				)}
				<AnswerText bold={checked} tkaColor={mapping.textColor}>
					{text}
				</AnswerText>
				{isRadio ? (
					<RadioButton
						checked={checked}
						status={radioStatus}
						icon={
							radioButtonStyle?.icon && (
								<Icon
									icon={radioButtonStyle.icon}
									color={radioButtonStyle.iconColor || mapping.backgroundColor}
									sizeRem={1.5}
								/>
							)
						}
						iconBackgroundColor={radioButtonStyle?.iconBackgroundColor}
					/>
				) : (
					<Checkbox
						checked={checked || status === MultipleChoiceGapTextStatus.ActuallyCorrect}
						status={checkboxStatus}
						backgroundColor={checkboxStyle?.iconBackgroundColor}
					/>
				)}
			</SpacedContentsDiv>
		</Card>
	);
};
