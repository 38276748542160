import { css } from "@styled-system/css";

export const messagesOverviewClass = css({
	mt: "32",
	display: "flex",
	flexWrap: "wrap",
	gap: "80",
	justifyContent: "space-evenly",
	mdDown: {
		gap: "40",
	},
	_only: {
		justifyContent: "start",
	},
});
