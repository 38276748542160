/**
 * @generated SignedSource<<488f50c860d158d5d1e3b1d232c73adc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messageModal_MessageFragment$data = {
  readonly id: string;
  readonly messageDefinition: {
    readonly pages: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"messageModalPage_PageFragment">;
    }>;
    readonly showUntil: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"messageModalPage_MessageFragment">;
  };
  readonly " $fragmentType": "messageModal_MessageFragment";
};
export type messageModal_MessageFragment$key = {
  readonly " $data"?: messageModal_MessageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messageModal_MessageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "messageModal_MessageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageDefinition",
      "kind": "LinkedField",
      "name": "messageDefinition",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "messageModalPage_MessageFragment"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showUntil",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MessageDefinitionPage",
          "kind": "LinkedField",
          "name": "pages",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "messageModalPage_PageFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Message",
  "abstractKey": null
};
})();

(node as any).hash = "8d87227986e0a419ce55f9a1ab7dd555";

export default node;
