import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div`
	${tw`
		absolute
		bottom-24
		left-1/2
		-translate-x-1/2
		w-[calc(100%-24px-24px)]
		flex
		flex-col
		items-end
		gap-8
		p-16
		bg-shade-0
		rounded-24
	`}

	box-shadow: ${({ theme }) => theme.componentStyles?.card?.boxShadow};
`;

export const ButtonsWrapper = tw.div`
	flex
	flex-row
	justify-end
	items-center
	gap-16
`;

export const BottomTextWrapper = tw.div`
	px-16
`;
