import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { shade40, shade5 } from "@themes/colors";
import { TkaH1Span, TkaP2Span } from "@themes/font-tags";
import {
	IconBottomNodeWrapper,
	IconWrapper,
	TextWrapper,
	Wrapper,
} from "./icon-headline-button-template.styles";
import { IconHeadlineButtonTemplateProps } from "./icon-headline-button-template.types";

export const IconHeadlineButtonTemplate = ({
	icon,
	iconName,
	iconColor,
	iconBackgroundColor,
	iconBottomNode,
	title,
	subtitle,
	buttonLabel,
	buttonIconName,
	buttonIcon,
	buttonWidthRem,
	buttonColorVersion,
	onClick,
	secondaryButtonLabel,
	secondaryButtonIconName,
	secondaryButtonIcon,
	secondaryButtonWidthRem,
	secondaryButtonColorVersion,
	secondaryOnClick,
}: IconHeadlineButtonTemplateProps) => {
	const handleOnClick = () => {
		if (onClick) {
			onClick();
		}
	};
	const renderIcon = () => {
		if (!icon && !iconName) return null;
		return (
			<IconWrapper backgorundColor={iconBackgroundColor ?? shade5}>
				{icon ??
					(iconName && (
						<Icon icon={iconName} color={iconColor ?? shade40} sizeInRem={2.5} />
					))}
				{iconBottomNode && <IconBottomNodeWrapper>{iconBottomNode}</IconBottomNodeWrapper>}
			</IconWrapper>
		);
	};

	return (
		<Wrapper>
			{renderIcon()}
			{(title || subtitle) && (
				<TextWrapper>
					{title && <TkaH1Span>{title}</TkaH1Span>}
					{subtitle && <TkaP2Span>{subtitle}</TkaP2Span>}
				</TextWrapper>
			)}
			{(buttonLabel || buttonIconName || buttonIcon) && (
				<Button
					label={buttonLabel}
					iconName={buttonIconName}
					icon={buttonIcon}
					widthRem={buttonWidthRem}
					colorVersion={buttonColorVersion}
					onClick={handleOnClick}
				/>
			)}
			{(secondaryButtonLabel || secondaryButtonIconName || secondaryButtonIcon) && (
				<Button
					label={secondaryButtonLabel}
					iconName={secondaryButtonIconName}
					icon={secondaryButtonIcon}
					widthRem={secondaryButtonWidthRem}
					colorVersion={secondaryButtonColorVersion}
					onClick={secondaryOnClick}
				/>
			)}
		</Wrapper>
	);
};
