import { SideMenuContentPadding } from "@thekeytechnology/academies-lib-webapp/containers/side-menu-content-padding";
import tw from "twin.macro";
import { TkaP2Span } from "@themes/font-tags";

export const ElementWrapper = tw(SideMenuContentPadding)`
    flex
    flex-col
`;

export const Subtitle = tw(TkaP2Span)`
    mt-32   
`;
