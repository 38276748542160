import { css } from "@styled-system/css";

export const titleClass = css({
	display: "block",
	color: "shade.100",
	marginTop: "16",
	marginBottom: "24",
});

export const multipleChoiceLearnElementWrapperClass = css({
	display: "flex",
	gap: "12",
	flexDirection: "column",
	width: "full",
	md: {
		flexDirection: "row",
	},
});

export const markMistakesLearnElementWrapperClass = css({
	display: "flex",
	flexWrap: "wrap",
	columnGap: "8",
	rowGap: "12",
});

export const textLearnElementWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "2",
});

export const orderLearnElementWrapperClass = css({
	width: "full",
});

export const clozeTextLearnElementWrapperClass = css({
	rowGap: "12",
});

export const videoLearnElementWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	flex: "1",
	marginTop: "-32",
});
