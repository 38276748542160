import * as yup from "yup";
import type { reminderForm_QueryFragment$key } from "@relay/reminderForm_QueryFragment.graphql";

export type ReminderFormProps = {
	onSuccess?: () => void;
	noteFragmentRef?: reminderForm_QueryFragment$key | null;
};

export type ReminderFormRef = {
	submit: () => Promise<any>;
	validate: () => Promise<boolean>;
};

export const reminderSchema = yup.object().shape({
	content: yup.string().required("Bitte gib einen Text ein."),
	remindAt: yup.date().required("Bitte gib ein Datum ein."),
});

export type ReminderFormState = {
	content: string;
	remindAt: Date;
};
