import { LabelSkeleton } from "@thekeytechnology/academies-lib-webapp/components/label";
import { CollapsedWrapper } from "./tags.styles";

export const TagsSkeleton = () => {
	return (
		<CollapsedWrapper>
			<div tw={"shrink-0 grow-0"}>
				<LabelSkeleton size="big" />
			</div>
			<div tw={"shrink-0 grow-0"}>
				<LabelSkeleton size="big" />
			</div>
			<div tw={"shrink-0 grow-0"}>
				<LabelSkeleton size="big" />
			</div>
		</CollapsedWrapper>
	);
};
