import { SideMenu } from "@thekeytechnology/academies-lib-webapp/containers/side-menu";
import { SideMenuContent } from "@thekeytechnology/academies-lib-webapp/containers/side-menu-content";
import { Spacing } from "@thekeytechnology/epic-ui";
import styled from "styled-components";
import tw from "twin.macro";
import { AlternativeNavigationHeightRem } from "@components/alternative-navigation-organism";
import { BreakpointInPx } from "@themes/breakpoint";

export const ScreenWrapper = tw.div`
	flex
	flex-col
	h-full
	bg-shade-5
`;

export const StyledSideMenu = tw(SideMenu)`
	overflow-y-hidden
	p-0
	flex-1
`;

export const StyledSideMenuPadding = tw(SideMenuContent)`
	overflow-y-auto 
	no-scrollbar
	p-24
`;

export const SideMenuContentElement = styled(SideMenuContent)`
	${tw`
		p-0
		flex
		flex-col
		overflow-y-hidden
		max-w-none
	`}
	padding-bottom: ${AlternativeNavigationHeightRem}rem;
`;

export const StyledFooterSection = styled(SideMenuContent)`
	${tw`
		absolute
		bottom-0
		left-0
		right-0
		p-0
		max-w-none
	`}
	@media screen and (min-width: ${BreakpointInPx.xlarge}px) {
		grid-row: 4 / -1;
	}
`;

export const FlexColumn = tw.div`
	hidden
	xl:flex
	xl:flex-col
`;

export const VerticalSpace = styled.div<{ spacing: Spacing }>`
	padding-bottom: ${(p) => p.spacing.rem()};
`;

export const MaterialCardWorkaround = styled.div`
	transform: translateX(-0.75rem);
	@media screen and (max-width: ${BreakpointInPx.large}px) {
		display: none;
	}
`;
