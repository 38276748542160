import { graphql } from "react-relay";

export const MULTIPLE_CHOICE_LEARN_ELEMENT_FRAGMENT = graphql`
	fragment multipleChoiceLearnElement_MultipleChoiceLearnElementFragment on MultipleChoiceLearnElement {
		question
		answeringType
		answeringTypeText
		answerOptions {
			answer
			image {
				url
			}
		}
	}
`;
