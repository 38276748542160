import { graphql } from "react-relay";

export const QUERY_FRAGMENT = graphql`
	fragment notesOverview_NotesQueryFragment on NoteConnection {
		edges {
			node {
				id
				reminder {
					__typename
				}
				...noteCard_NoteFragment
			}
		}
	}
`;
