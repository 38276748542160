import { css } from "@styled-system/css";

export const selectionMenuClass = css({
	position: "absolute",
	zIndex: 1000,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	paddingX: "8",
	paddingY: "[7px]",
	borderRadius: "24",
	background:
		"[linear-gradient(0deg,rgba(255,255,255,0.6),rgba(255,255,255,0.6)),rgba(255,255,255,0.94)]",
	backgroundBlendMode: "overlay, normal",
	boxShadow: "flyout",
});

export const noteCreateButtonClass = css({
	paddingX: "8",
	paddingY: "4",
});
