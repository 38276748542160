import { graphql } from "react-relay";

export const BASE_DATA_FRAGMENT = graphql`
	fragment orderIhkModal_BaseDataFragment on Query {
		AccountBaseData {
			AccountBaseData {
				__typename
				street
				houseNumber
				postalCode
				city
				countryCode
				... on PrivateBaseData {
					salutation
					title
					firstName
					lastName
				}
			}
		}
	}
`;

export const ORDER_IHK_CERTIFICATE_MUTATION = graphql`
	mutation orderIhkModal_OrderIHKCertificateMutation($input: OrderIHKCertificateInput!) {
		Learn {
			orderIHKCertificate(input: $input) {
				root {
					structureDefinition {
						... on LearnOpportunityRootStructureDefinition {
							extension {
								... on PublishedRootExtensionImpl {
									ihkState
								}
							}
						}
					}
				}
			}
		}
	}
`;
