import { SideMenuContentPadding } from "@thekeytechnology/academies-lib-webapp/containers/side-menu-content-padding";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import { GapTextStatus } from "@components/gap-text/gap-text.interface";
import { MatrixLearnElement } from "@components/matrix-learn-element";
import { SideMenuLayoutContext } from "@layouts/sidemenu-layout/sidemenu-layout.context";
import { MatrixElement_ContentSubmissionFragment$key } from "@relay/MatrixElement_ContentSubmissionFragment.graphql";
import { MatrixElement_SubmitMatrixElementMutation } from "@relay/MatrixElement_SubmitMatrixElementMutation.graphql";
import { shade100 } from "@themes/colors";
import { TkaP2Span, TkaH1Span } from "@themes/font-tags";
import { CellCords, MatrixElementProps, SelectedState } from "./matrix-element.interface";
import {
	ContentWrapper,
	FixedBottomWrapper,
	RelativeBottomWrapper,
	Spacing12,
	Spacing40,
	TitleWrapper,
	Wrapper,
} from "./matrix-element.styles";
import { convertToMap, get2DArray, getIndexKey } from "./matrix-element.util";
import { MatrixElementAnswers } from "./MatrixElementAnswers.component";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment MatrixElement_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					kind
					... on MatrixShowAnswerElementState {
						kind
						lastCheckedIndices {
							xIdx
							yIdx
							index
						}
						correctIndices {
							xIdx
							yIdx
							index
						}
					}
					... on InputAndIsCorrectElementState {
						isCorrect
						inputElementState {
							... on MatrixInputElementState {
								checkedIndices {
									xIdx
									yIdx
									index
								}
							}
						}
					}
					... on MatrixInputElementState {
						checkedIndices {
							index
							xIdx
							yIdx
						}
					}
					element {
						title
						id
						... on MatrixLearnElement {
							id
							title
							cells {
								xIdx
								yIdx
								... on DisplayMatrixLearnElementCell {
									content
									kind
								}
								... on AnswerMatrixLearnElementCell {
									answers {
										content
									}
									kind
								}
							}
							...matrixLearnElementFragment_MatrixLearnElementFragment
						}
					}
				}
			}
			...MatrixElementAnswers_ELearningContentSubmissionDefinitionFragment
		}
	}
`;

const SUBMIT_MATRIX_ELEMENT_MUTATION = graphql`
	mutation MatrixElement_SubmitMatrixElementMutation($input: SubmitMatrixElementV2Input!) {
		LearnV2 {
			submitMatrixElement(input: $input) {
				clientMutationId
				contentSubmission {
					...ContentSubmissionScreen_ContentSubmissionFragment
					definition {
						... on ActiveELearningContentSubmissionDefinition {
							currentElementState {
								kind
								... on InputAndIsCorrectElementState {
									isCorrect
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const MatrixElement = ({ contentSubmissionFragmentRef }: MatrixElementProps) => {
	const contentSubmission = useFragment<MatrixElement_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [submitMatrixElement] = useMutation<MatrixElement_SubmitMatrixElementMutation>(
		SUBMIT_MATRIX_ELEMENT_MUTATION,
	);

	const { bottomContentHeightPx } = useContext(SideMenuLayoutContext);

	const elementState = contentSubmission?.definition?.currentElementState;
	const element = elementState?.element;
	const currentElementStateKind = elementState?.kind || "Untouched";
	const isInput = currentElementStateKind === "Input";

	const isShowAnswer = currentElementStateKind === "ShowAnswer";
	const isInputCorrect = currentElementStateKind === "InputAndIsCorrect";
	const isUntouched =
		currentElementStateKind === "Untouched" ||
		currentElementStateKind === "UntouchedAndPreviouslyIncorrect";
	const correctIndices = elementState?.correctIndices || [];

	const checkedIndices =
		elementState?.lastCheckedIndices ||
		elementState?.inputElementState?.checkedIndices ||
		elementState?.checkedIndices ||
		undefined;

	const hasBorders = true;

	const {
		isModalVisible,
		setCanGoNext,
		addGoToNextOnClickListener,
		addTryAgainOnClickListener,
		setFailureModalVisible,
		setSuccessModalVisible,
		setLoading,
	} = useContext(ContentSubmissionModalContext);

	const [selectedCellCords, setSelectedCellCords] = useState<CellCords | undefined>();
	const [selectedIndexes, setSelectedIndexes] = useState<SelectedState>({});

	const array2DCells = useMemo(() => get2DArray(element?.cells || []), [element?.cells]);
	const inputDisabled = isModalVisible || (!isUntouched && !isInput);

	const handleSubmitClick = useCallback(() => {
		setLoading(true);
		submitMatrixElement({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id!,
					matrixElementId: element?.id!,
					checkedIndices: Object.values(selectedIndexes),
				},
			},
			onCompleted: (response) => {
				setLoading(false);
				const currentElementState =
					response.LearnV2.submitMatrixElement?.contentSubmission.definition
						?.currentElementState;
				if (!currentElementState) return;

				if (currentElementState.isCorrect) {
					setSuccessModalVisible(true);
				} else if (
					currentElementState.isCorrect === false ||
					currentElementState.kind === "UntouchedAndPreviouslyIncorrect"
				) {
					setFailureModalVisible(true);
				}
			},
		});
	}, [
		submitMatrixElement,
		contentSubmission.id,
		element?.id,
		selectedIndexes,
		setSuccessModalVisible,
		setFailureModalVisible,
	]);

	const handleGoToNextClicked = useCallback(() => {
		setSelectedCellCords(undefined);
		if (isUntouched || isInput) {
			handleSubmitClick();
			return true;
		}
		return false;
	}, [isUntouched, isInput, handleSubmitClick]);

	useEffect(() => addGoToNextOnClickListener(handleGoToNextClicked), [handleGoToNextClicked]);

	const handleTryAgainClicked = useCallback(() => {
		setSelectedIndexes({});
	}, []);

	useEffect(() => addTryAgainOnClickListener(handleTryAgainClicked), [handleTryAgainClicked]);

	useEffect(() => {
		const requiredSelections = (array2DCells.length - 1) * (array2DCells[0].length - 1);
		const canGoNext =
			Object.keys(selectedIndexes).length === requiredSelections || !isUntouched;
		setCanGoNext(canGoNext);
	}, [selectedIndexes, isUntouched]);

	useEffect(() => {
		if (!isUntouched && checkedIndices && checkedIndices.length > 0) {
			setSelectedIndexes(convertToMap([...checkedIndices]));
		}
	}, [checkedIndices]);

	const correctIndicesMap: SelectedState = useMemo(
		() => convertToMap([...correctIndices]),
		[correctIndices],
	);

	const useIndices: SelectedState = useMemo(() => {
		return isShowAnswer && Object.keys(correctIndicesMap).length > 0
			? correctIndicesMap
			: selectedIndexes;
	}, [isShowAnswer, selectedIndexes, correctIndicesMap]);

	const calculateGapTextStatus = (x: number, y: number): GapTextStatus => {
		const key = getIndexKey(x, y);
		if (isShowAnswer) {
			const correctIndex = correctIndicesMap[key].index;
			const inputIndex = selectedIndexes[key]?.index;
			const isCorrect = correctIndex === inputIndex;
			if (isCorrect) return GapTextStatus.Correct;
			return GapTextStatus.ActuallyCorrect;
		}
		if (isInputCorrect) return GapTextStatus.Correct;
		return GapTextStatus.Default;
	};

	const handleOnCellClick = useCallback(
		(xIdx: number, yIdx: number) => {
			if (!xIdx || !yIdx || inputDisabled) return;
			setSelectedCellCords({ xIdx, yIdx });
		},
		[inputDisabled],
	);

	const handleOnIndexSelected = useCallback(
		(index: number) => {
			setSelectedCellCords(undefined);
			setSelectedIndexes((indexes) => {
				if (!selectedCellCords) return indexes;
				const xIdx = selectedCellCords.xIdx;
				const yIdx = selectedCellCords.yIdx;
				const key = getIndexKey(xIdx, yIdx);
				return {
					...indexes,
					[key]: {
						xIdx,
						yIdx,
						index,
					},
				};
			});
		},
		[selectedCellCords],
	);

	const answersModal = (
		<MatrixElementAnswers
			selectedCellCords={selectedCellCords}
			onIndexSelected={handleOnIndexSelected}
			contentSubmissionDefinitionFragmentRef={contentSubmission.definition}
		/>
	);

	return (
		<Wrapper data-selection-menu-element-id={elementState?.element.id}>
			<ContentWrapper onClick={() => setSelectedCellCords(undefined)}>
				<SideMenuContentPadding>
					<TitleWrapper>
						<TkaH1Span tkaColor={shade100}>
							{contentSubmission.definition?.currentElementState?.element.title!}
						</TkaH1Span>
						<Spacing12 />
						<TkaP2Span tkaColor={shade100}>
							{"Tippe auf die Felder und wähle die passenden Antworten aus."}
						</TkaP2Span>
					</TitleWrapper>
					<Spacing40 />
					<MatrixLearnElement
						hasBorders={hasBorders}
						state={useIndices}
						onCellClick={handleOnCellClick}
						getStatusForCell={calculateGapTextStatus}
						learnElementFragmentRef={element}
					/>
				</SideMenuContentPadding>
			</ContentWrapper>
			<RelativeBottomWrapper>{answersModal}</RelativeBottomWrapper>
			<FixedBottomWrapper bottomPx={bottomContentHeightPx}>{answersModal}</FixedBottomWrapper>
		</Wrapper>
	);
};
