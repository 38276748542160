import { isAfterIndexPath, isEqualIndexPath } from "@thekeytechnology/academies-lib-webapp/utils";
import { useMemo } from "react";
import { useFragment } from "react-relay";
import { AttachmentsCollection } from "@components/attachments-collection";
import { ContentElementsCollection } from "@components/content-elements-collection";
import { InstructorsComponent } from "@components/instructors-component";
import { LEARN_OPPORTUNITY_FRAGMENT } from "./e-learning-content-overview.graphql";
import {
	AttachmentsWrapper,
	ContentCardsWrapper,
	InstructorsWrapper,
	Wrapper,
} from "./e-learning-content-overview.styles";
import { ELearningContentOverviewProps } from "./e-learning-content-overview.types";

export const ELearningContentOverview = ({
	learnOpportunityV2FragmentRef,
}: ELearningContentOverviewProps) => {
	const node = useFragment(LEARN_OPPORTUNITY_FRAGMENT, learnOpportunityV2FragmentRef);

	const headContentId = node.root?.structureDefinition.viewerTreeState?.headContentId;
	const firstLockedContentId =
		node.root?.structureDefinition.extension?.unlockInfo?.firstContentId;
	const noDemo = node.root?.structureDefinition.extension?.unlockInfo?.kind !== "Demo";

	const isStarted = node.root?.structureDefinition.viewerTreeState?.kind === "IsStarted";
	const isFinished = node.root?.structureDefinition.viewerTreeState?.kind === "IsFinished";

	const headIndexPath: readonly number[] | undefined = useMemo(() => {
		let indexPath: readonly number[] | undefined = undefined;
		node.root?.typeDefinition.children?.forEach((branch) => {
			const foundContent = branch.typeDefinition?.children?.find(
				(content) => content.id === headContentId,
			);
			if (foundContent) {
				indexPath = foundContent.indexPath;
			}
		});
		return indexPath;
	}, [headContentId, node.root?.typeDefinition.children]);

	const isUnlocked = useMemo(
		() =>
			(isStarted &&
				(isAfterIndexPath(node.indexPath, headIndexPath) ||
					(isEqualIndexPath(node.indexPath, headIndexPath) &&
						(headContentId !== firstLockedContentId || noDemo)))) ||
			isFinished,
		[
			isStarted,
			isFinished,
			node.indexPath,
			headIndexPath,
			headContentId,
			firstLockedContentId,
			noDemo,
		],
	);

	const hasAttachments = node.attachmentsV2.length > 0;
	return (
		<Wrapper>
			{node && (
				<ContentCardsWrapper>
					<ContentElementsCollection learnOpportunityV2FragmentRef={node} />
				</ContentCardsWrapper>
			)}
			{hasAttachments && node && isUnlocked && (
				<AttachmentsWrapper>
					<AttachmentsCollection attachmentsCollectionFragmentRef={node} />
				</AttachmentsWrapper>
			)}
			{node?.instructorsV2 && (
				<InstructorsWrapper>
					<InstructorsComponent instructorsConnectionFragmentRef={node?.instructorsV2} />
				</InstructorsWrapper>
			)}
		</Wrapper>
	);
};
