import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { InputText } from "@thekeytechnology/epic-ui";
import { shade100, shade40 } from "@themes/colors";

//TODO: remove-translations
export const SearchBarSkeleton = () => {
	const placeholder = "Suchen";

	const { isMediumUp } = useWindowSize();
	const iconColor = isMediumUp ? shade40 : shade100;
	const iconSize = isMediumUp ? 1.5 : 0.75;
	if (!isMediumUp) return <Icon icon={"search"} sizeRem={iconSize} color={iconColor} />;

	return <InputText icon={"search"} placeholder={placeholder + "..."} />;
};
