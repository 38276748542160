import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.spacing12};
`;

export const LinkCss = css`
	margin: 0 ${({ theme }) => theme.spacing.spacing24} 0;
	padding: ${({ theme }) => theme.spacing.spacing16};
	border: 1px solid ${({ theme }) => theme.colors.shade10};
	border-radius: ${({ theme }) => theme.borderRadius?.borderRadius24};
	cursor: pointer;
`;

export const LinkWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const LinkContent = styled.div`
	display: none;
	width: 100%;
`;

export const StyledLink = styled(NavLink)`
	${LinkCss}

	&[aria-current="page"] {
		box-shadow: ${({ theme }) => theme.componentStyles?.card?.boxShadow};
		border-color: ${({ theme }) => theme.colors.shade0};
		background-color: ${({ theme }) => theme.colors.shade0};

		& span {
			color: ${({ theme }) => theme.colors.primary80};
		}

		& + ${LinkContent} {
			display: block;
		}
	}
`;
