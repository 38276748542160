import { graphql } from "react-relay";

export const INSTRUCTOR_FRAGMENT = graphql`
	fragment instructorCard_InstructorFragment on Instructor {
		name
		... on InstructorImpl {
			description
			shortDescription
			position
			image {
				url
			}
		}
	}
`;
