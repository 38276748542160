/**
 * @generated SignedSource<<e6b82bcc262d63b72c44504b804dfe79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ClozeTextPartKind = "cloze" | "text";
import { FragmentRefs } from "relay-runtime";
export type clozeTextLearnElement_ClozeTextLearnElementFragment$data = {
  readonly id: string;
  readonly parts: ReadonlyArray<{
    readonly id: string;
    readonly kind: ClozeTextPartKind;
    readonly text?: string;
  }>;
  readonly shuffledAnswers: ReadonlyArray<{
    readonly id: string;
    readonly text: string;
  }>;
  readonly title: string;
  readonly " $fragmentType": "clozeTextLearnElement_ClozeTextLearnElementFragment";
};
export type clozeTextLearnElement_ClozeTextLearnElementFragment$key = {
  readonly " $data"?: clozeTextLearnElement_ClozeTextLearnElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"clozeTextLearnElement_ClozeTextLearnElementFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "clozeTextLearnElement_ClozeTextLearnElementFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "parts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/)
          ],
          "type": "TextPart",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClozeAnswer",
      "kind": "LinkedField",
      "name": "shuffledAnswers",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ClozeTextLearnElement",
  "abstractKey": null
};
})();

(node as any).hash = "e350cfc7eecf81415be6c2d2d053ff0c";

export default node;
