import { AccountsDropdown } from "@thekeytechnology/academies-lib-webapp/components/accounts-dropdown";
import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { DividerList } from "@thekeytechnology/academies-lib-webapp/components/divider-list";
import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { ReportModalFlow } from "@thekeytechnology/academies-lib-webapp/components/report-modal-flow";
import { UserAvatar } from "@thekeytechnology/academies-lib-webapp/components/user-avatar";
import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useAuthContext } from "@thekeytechnology/academies-lib-webapp/hooks/use-auth-context";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { Fragment, MouseEvent, useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { DualRowWrapper } from "@components/profile-menu-row/profile-menu-row.styled";
import { profileMenu_AuthViewerSchemaFragment$key } from "@relay/profileMenu_AuthViewerSchemaFragment.graphql";
import { profileMenu_Query } from "@relay/profileMenu_Query.graphql";
import { Path } from "@router/paths";
import { shade0 } from "@themes/colors";
import { TkaH1Span, TkaH3Span } from "@themes/font-tags";
import { AUTH_VIEWER_SCHEMA_FRAGMENT, QUERY } from "./profile-menu.graphql";
import { ProfileMenuSkeleton } from "./profile-menu.skeleton";
import {
	AvatarWrapper,
	CurrentUserWrapper,
	MenuWrapper,
	PointsWrapper,
} from "./profile-menu.styled";
import { ProfileMenuProps } from "./profile-menu.types";
import { Chip } from "../chip";
import { ProfileMenuRow } from "../profile-menu-row";

// TODO: add-translations
export const ProfileMenuComponent = ({
	onGrayBackground,
	onNavigated,
	onClose,
}: ProfileMenuProps) => {
	const query = useLazyLoadQuery<profileMenu_Query>(QUERY, {});

	const auth = useFragment<profileMenu_AuthViewerSchemaFragment$key>(
		AUTH_VIEWER_SCHEMA_FRAGMENT,
		query.Viewer.Auth,
	);

	const navigate = useNavigate();
	const { t } = useTkaTranslation("navbar");
	const { logout } = useAuthContext();
	const [isShowingCreateReportFlow, setIsShowingCreateReportFlow] = useState(false);
	const accounts = auth?.currentUser?.accounts ?? [];

	const handleLogoutOnClick = (event: MouseEvent<HTMLElement>) => {
		logout();
		onNavigated?.(event);
	};
	const handleCreateReportOnClick = (_: MouseEvent<HTMLElement>) => {
		setIsShowingCreateReportFlow(true);
	};
	const handleDismissModalFlowOnClick = () => {
		setIsShowingCreateReportFlow(false);
	};

	const createMenuItemOnClickHandler = (path: string) => (event: MouseEvent<HTMLElement>) => {
		navigate(path);
		onNavigated?.(event);
	};

	const handleOnAccountSelected = () => {
		onClose?.();
	};

	const name = auth?.currentUser?.user?.name;
	const hasMoreThenOneAccount = accounts.length > 1;
	const points = auth?.currentUser?.user.extension.sumGamificationPoints ?? 0;
	const chipBackgroundColor = onGrayBackground ? shade0 : undefined;

	return (
		<Fragment>
			<ReportModalFlow
				isVisible={isShowingCreateReportFlow}
				onDismiss={handleDismissModalFlowOnClick}
			/>
			<MenuWrapper>
				{name && (
					<CurrentUserWrapper>
						<AvatarWrapper>
							<UserAvatar userFragmentRef={auth.currentUser.user} sizeInRem={5} />
							<PointsWrapper>
								<Chip backgroundColor={chipBackgroundColor}>
									<TkaH3Span>{points}</TkaH3Span>
									<Icon icon="coinStar" sizeInRem={1} />
								</Chip>
							</PointsWrapper>
						</AvatarWrapper>
						<TkaH1Span>{name}</TkaH1Span>
					</CurrentUserWrapper>
				)}
				<DividerList hasDivider gapRem={1}>
					{hasMoreThenOneAccount && auth?.currentUser && (
						<AccountsDropdown
							label="Team"
							onAccountSelected={handleOnAccountSelected}
							currentUserFragmentRef={auth.currentUser}
						/>
					)}

					<ProfileMenuRow
						icon="calendar"
						label="Meine Termine"
						onClick={createMenuItemOnClickHandler(Path.appointments.path)}
					/>
					<ProfileMenuRow
						icon="translate"
						label="Vokabeln"
						onClick={createMenuItemOnClickHandler(Path.profileMenu.vocabulary.path)}
					/>
					<ProfileMenuRow
						icon="loginKey"
						label="Lizenzen"
						onClick={createMenuItemOnClickHandler(Path.profileMenu.licenses.path)}
					/>
					<ProfileMenuRow
						icon="book"
						label="Dokumente"
						onClick={createMenuItemOnClickHandler(Path.documents.path)}
					/>
					<ProfileMenuRow
						icon="moneyWallet"
						label="Konto"
						onClick={createMenuItemOnClickHandler(Path.profileMenu.account.path)}
					/>
					<ProfileMenuRow
						icon="materialComplete"
						label="Impressum"
						onClick={createMenuItemOnClickHandler(Path.profileMenu.imprint.path)}
					/>
					<DualRowWrapper>
						<ProfileMenuRow
							icon={"question"}
							label={"Inhalte melden & Feedback"}
							onClick={handleCreateReportOnClick}
							isShort
						/>
						<Button
							colorVersion={"error"}
							label={t("navbar.logout")}
							onClick={handleLogoutOnClick}
							iconName={"logout"}
						/>
					</DualRowWrapper>
				</DividerList>
			</MenuWrapper>
		</Fragment>
	);
};

export const ProfileMenu = withSuspense(ProfileMenuComponent, ProfileMenuSkeleton);
