import { graphql } from "react-relay";

export const MESSAGE_FRAGMENT = graphql`
	fragment messageModal_MessageFragment on Message {
		id
		messageDefinition {
			...messageModalPage_MessageFragment
			showUntil
			pages {
				id
				...messageModalPage_PageFragment
			}
		}
	}
`;

export const ACKNOWLEDGE_MESSAGE_MUTATION = graphql`
	mutation messageModal_AcknowledgeMessageMutation($input: AcknowledgeMessageInput!) {
		Viewer {
			Message {
				acknowledgeMessage(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
