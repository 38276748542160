import { useCallback, useEffect, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { ContentSubmissionFailureModal } from "@components/content-submission-failure-modal";
import { ContentSubmissionSuccessModal } from "@components/content-submission-success-modal";
import { ContentSubmissionModalContextProvider_ContentSubmissionFragment$key } from "@relay/ContentSubmissionModalContextProvider_ContentSubmissionFragment.graphql";
import { ContentSubmissionModalContext as SubmissionContext } from "./content-submission-modal-context-provider.context";
import {
	ContentSubmissionModalProps,
	SetListenerStateFunction,
} from "./content-submission-modal-context-provider.interface";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ContentSubmissionModalContextProvider_ContentSubmissionFragment on ContentSubmission {
		...ContentSubmissionFailureModal_ContentSubmissionFragment
		...ContentSubmissionSuccessModal_ContentSubmissionFragment
	}
`;

export const ContentSubmissionModalContextProvider = ({
	contentSubmissionFragmentRef,
	hideBackButton,
	children,
}: ContentSubmissionModalProps) => {
	const contentSubmission =
		useFragment<ContentSubmissionModalContextProvider_ContentSubmissionFragment$key>(
			CONTENT_SUBMISSION_FRAGMENT,
			contentSubmissionFragmentRef,
		);

	const [loading, setLoading] = useState(false);
	const [isFailureModalVisible, setFailureModalVisible] = useState(false);
	const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
	const [backButtonHidden, setBackButtonHidden] = useState(hideBackButton ?? false);
	const [nextButtonText, setNextButtonText] = useState<string | undefined>(undefined);
	const [canGoNext, setCanGoNext] = useState(false);
	const [useDefaultGoNext, setUseDefaultGoNext] = useState(false);
	const [tryAgainOnClickListeners, setTryAgainOnClickListeners] = useState<(() => void)[]>([]);
	const [showAnswerOnClickListeners, setShowAnswerOnClickListeners] = useState<(() => void)[]>(
		[],
	);
	const [goToNextOnClickListeners, setGoToNextOnClickListeners] = useState<(() => boolean)[]>([]);
	const [goToPreviousOnClickListeners, setGoToPreviousOnClickListeners] = useState<
		(() => void)[]
	>([]);
	const [onGoToNextListeners, setOnGoToNextListeners] = useState<(() => void)[]>([]);

	const handleSetFailureModalVisible = useCallback((visible: boolean) => {
		setFailureModalVisible(visible);
		visible && setSuccessModalVisible(false);
	}, []);

	const handleFailureModalOnHide = useCallback(() => {
		handleSetFailureModalVisible(false);
	}, [handleSetFailureModalVisible]);

	const handleSetSuccessModalVisible = useCallback((visible: boolean) => {
		setSuccessModalVisible(visible);
		visible && setFailureModalVisible(false);
	}, []);

	const handleSuccessModalOnHide = useCallback(() => {
		handleSetSuccessModalVisible(false);
	}, [handleSetSuccessModalVisible]);

	const getSubscriberFunction = <T,>(setStateFunction: SetListenerStateFunction<T>) => {
		return (listener: () => T) => {
			setStateFunction((listeners) => {
				const listenersSlice = listeners.slice();
				listenersSlice.push(listener);
				return listenersSlice;
			});
			return () => {
				setStateFunction((listeners) => {
					const listenersSlice = listeners.slice();
					const filtered = listenersSlice.filter((l) => l !== listener);
					return filtered;
				});
			};
		};
	};

	const handleAddTryAgainOnClickListener = useCallback(
		getSubscriberFunction(setTryAgainOnClickListeners),
		[],
	);

	const handleTryAgainClicked = useCallback(() => {
		handleSetFailureModalVisible(false);
		handleSetSuccessModalVisible(false);
		tryAgainOnClickListeners.forEach((cb) => cb());
	}, [tryAgainOnClickListeners, handleSetFailureModalVisible, handleSetSuccessModalVisible]);

	const handleAddShowAnswerOnClickListener = useCallback(
		getSubscriberFunction(setShowAnswerOnClickListeners),
		[],
	);

	const handleShowAnswerClicked = useCallback(() => {
		handleSetFailureModalVisible(false);
		handleSetSuccessModalVisible(false);
		showAnswerOnClickListeners.forEach((cb) => cb());
	}, [showAnswerOnClickListeners, handleSetFailureModalVisible, handleSetSuccessModalVisible]);

	const handleAddGoToNextOnClickListener = useCallback(
		getSubscriberFunction(setGoToNextOnClickListeners),
		[],
	);

	const handleGoToNextClicked = useCallback(() => {
		const handeledCallbacks = goToNextOnClickListeners.map((cb) => cb());
		return handeledCallbacks.some(Boolean);
	}, [goToNextOnClickListeners]);

	const handleAddGoToPreviousOnClickListener = useCallback(
		getSubscriberFunction(setGoToPreviousOnClickListeners),
		[],
	);

	const handleGoToPreviousClicked = useCallback(() => {
		goToPreviousOnClickListeners.forEach((cb) => cb());
	}, [goToPreviousOnClickListeners]);

	const handleAddOnGoToNextListener = useCallback(
		getSubscriberFunction(setOnGoToNextListeners),
		[],
	);

	const handleOnGoToNext = useCallback(() => {
		handleSetFailureModalVisible(false);
		handleSetSuccessModalVisible(false);
		setNextButtonText(undefined);
		onGoToNextListeners.forEach((cb) => cb());
	}, [onGoToNextListeners, handleSetFailureModalVisible, handleSetSuccessModalVisible]);

	useEffect(() => {
		setUseDefaultGoNext(goToNextOnClickListeners.length === 0);
	}, [goToNextOnClickListeners]);

	return (
		<SubmissionContext.Provider
			value={{
				isModalVisible: isSuccessModalVisible || isFailureModalVisible,
				isSuccessModalVisible,
				setSuccessModalVisible: handleSetSuccessModalVisible,
				isFailureModalVisible,
				setFailureModalVisible: handleSetFailureModalVisible,
				backButtonHidden,
				setBackButtonHidden,
				nextButtonText,
				setNextButtonText,
				canGoNext: canGoNext || useDefaultGoNext,
				setCanGoNext,
				addTryAgainOnClickListener: handleAddTryAgainOnClickListener,
				tryAgainClicked: handleTryAgainClicked,
				addShowAnswerOnClickListener: handleAddShowAnswerOnClickListener,
				showAnswerClicked: handleShowAnswerClicked,
				addGoToNextOnClickListener: handleAddGoToNextOnClickListener,
				goToNextClicked: handleGoToNextClicked,
				addGoToPreviousOnClickListener: handleAddGoToPreviousOnClickListener,
				goToPreviousClicked: handleGoToPreviousClicked,
				addOnGoToNextListener: handleAddOnGoToNextListener,
				onGoToNext: handleOnGoToNext,
				loading,
				setLoading,
			}}
		>
			<ContentSubmissionFailureModal
				isVisible={isFailureModalVisible}
				onHide={handleFailureModalOnHide}
				contentSubmissionFragmentRef={contentSubmission}
			/>
			<ContentSubmissionSuccessModal
				isVisible={isSuccessModalVisible}
				onHide={handleSuccessModalOnHide}
				contentSubmissionFragmentRef={contentSubmission}
			/>
			{children}
		</SubmissionContext.Provider>
	);
};
