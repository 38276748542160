import { ButtonSkeleton } from "@thekeytechnology/academies-lib-webapp/components/button";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { Skeleton } from "@thekeytechnology/epic-ui";

import { PreviewWrapper, TitleWrapper, Wrapper } from "./material-overview.styles";
import { MaterialsTabSkeleton } from "../materials-tab/materials-tab.skeleton";

export const MaterialOverviewSkeleton = ({ showDetails }: { showDetails?: boolean }) => {
	const { isXLargeUp } = useWindowSize();

	const overview = (
		<Wrapper>
			<TitleWrapper>
				<Skeleton width="4rem" />
				<Skeleton height="2rem" width="15rem" />
			</TitleWrapper>
			<PreviewWrapper>
				<ButtonSkeleton />
			</PreviewWrapper>
		</Wrapper>
	);

	return isXLargeUp || showDetails ? overview : <MaterialsTabSkeleton />;
};
