import { graphql } from "react-relay";

export const MATRIX_LEARN_ELEMENT_FRAGMENT = graphql`
	fragment matrixLearnElementFragment_MatrixLearnElementFragment on MatrixLearnElement {
		id
		title
		cells {
			xIdx
			yIdx
			... on DisplayMatrixLearnElementCell {
				content
				kind
			}
			... on AnswerMatrixLearnElementCell {
				answers {
					content
				}
				kind
			}
		}
	}
`;
