import { useState } from "react";
import { useRefetchableFragment } from "react-relay";
import { EmptyPlaceholder } from "@components/empty-placeholder";
import { MessageModal } from "@components/message-modal";
import { messagePreviewCard_MessageFragment$data } from "@relay/messagePreviewCard_MessageFragment.graphql";
import { messagesOverview_MessagesFragment$key } from "@relay/messagesOverview_MessagesFragment.graphql";
import { messagesOverview_Refetch } from "@relay/messagesOverview_Refetch.graphql";
import { MESSAGES_FRAGMENT } from "@screens/messages/parts/messages-overview/messages-overview.graphql";
import { messagesOverviewClass } from "@screens/messages/parts/messages-overview/messages-overview.styles";
import { MessagesOverviewProps } from "@screens/messages/parts/messages-overview/messages-overview.types";
import { H1Span } from "@themes/font-tags";
import { MessagePreviewCard } from "src/screens/messages/parts/message-preview-card";

export const MessagesOverview = ({ messagesFragmentRef }: MessagesOverviewProps) => {
	const [selectedMessage, setSelectedMessage] =
		useState<messagePreviewCard_MessageFragment$data | null>(null);

	const [data, refetch] = useRefetchableFragment<
		messagesOverview_Refetch,
		messagesOverview_MessagesFragment$key
	>(MESSAGES_FRAGMENT, messagesFragmentRef);
	const messages = data?.Viewer.Message.MessagesForUser.edges?.map((edge) => edge?.node);

	const handleMessageAcknowledged = () => {
		setSelectedMessage(null);
		refetch(
			{},
			{
				fetchPolicy: "network-only",
			},
		);
	};

	return (
		<>
			<H1Span>Unsere Specials für dich</H1Span>

			<EmptyPlaceholder
				isVisible={messages?.length === 0}
				title="Wir haben momentan keine Specials für dich"
				subtitle="Sobald es Neuigkeiten gibt, werden wir sie dir anzeigen."
			>
				<div className={messagesOverviewClass}>
					{messages?.map((message) => {
						if (!message) return null;
						return (
							<MessagePreviewCard
								key={message?.id}
								messageFragmentRef={message}
								onClick={(message) => setSelectedMessage(message)}
							/>
						);
					})}
				</div>
			</EmptyPlaceholder>

			{selectedMessage && (
				<MessageModal
					isVisible={true}
					messageFragmentRef={selectedMessage}
					onHide={handleMessageAcknowledged}
				/>
			)}
		</>
	);
};
