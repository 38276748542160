import { graphql } from "react-relay";

export const MESSAGES_FRAGMENT = graphql`
	fragment messagesOverview_MessagesFragment on Query
	@refetchable(queryName: "messagesOverview_Refetch") {
		Viewer {
			Message {
				MessagesForUser {
					edges {
						node {
							id
							...messagePreviewCard_MessageFragment
							messageDefinition {
								id
							}
						}
					}
				}
			}
		}
	}
`;
