import { css, cva } from "@styled-system/css";

export const tableClass = css({
	width: "[max-content]",
	borderSpacing: "0",
	borderCollapse: "separate",
});

export const tableHeadFirstTableHeadClass = cva({
	base: {
		position: "sticky",
		top: "0",
		left: "0",
		backgroundColor: "shade.0",
		zIndex: "10",
	},
	variants: {
		hasBorders: {
			true: {
				borderTop: "1px solid",
				borderRight: "1px solid",
				borderBottom: "1px solid",
				borderColor: "shade.10",
			},
		},
	},
	defaultVariants: {
		hasBorders: true,
	},
});

export const tableHeadTableHeadClass = cva({
	base: {
		position: "sticky",
		top: "0",
		backgroundColor: "shade.0",
		padding: "12",
	},
	variants: {
		hasBorders: {
			true: {
				borderTop: "1px solid",
				borderBottom: "1px solid",
				borderColor: "shade.10",
			},
		},
	},
	defaultVariants: {
		hasBorders: true,
	},
});

export const tableBodyTableHeadClass = cva({
	base: {
		position: "sticky",
		left: "0",
		backgroundColor: "shade.0",
		padding: "12",
	},
	variants: {
		hasBorders: {
			true: {
				borderRight: "1px solid",
				borderColor: "shade.10",
			},
		},
	},
	defaultVariants: {
		hasBorders: true,
	},
});

export const tableBodyGapTextWrapperTableDataClass = css({
	padding: "8",
});
