import { graphql } from "react-relay";

export const MESSAGE_QUERY = graphql`
	query messageModalInjector_LastUnseenMessageQuery {
		Viewer {
			Message {
				LastUnseenMessage {
					hasSeen
					messageDefinition {
						showUntil
					}
					...messageModal_MessageFragment
				}
			}
		}
	}
`;
