import tw from "twin.macro";

export const Wrapper = tw.div`
  flex
  flex-col
  border
  border-shade-20
  rounded-12
`;

export const DataWrapper = tw.div`
  flex
  flex-col
  gap-4
  p-16
`;

export const Header = tw.div`
  flex
  flex-col
`;
