import { MultiDialogFlowContainer } from "@thekeytechnology/academies-lib-webapp/containers/multi-dialog-flow-container";
import { OrderIHKCertificateSuccessModal } from "@components/order-ihk-certificate-success-modal";
import { OrderIHKModal } from "@components/order-ihk-modal";

import { OrderIHKFlowProps } from "./order-ihk-flow.types";

export const OrderIhkFlow = ({
	rootId,
	isVisible,
	onDismiss,
	baseDataFragmentRef,
}: OrderIHKFlowProps) => {
	return (
		<MultiDialogFlowContainer isVisible={isVisible} onDismiss={onDismiss}>
			{({ onNext, onClose }) => (
				<>
					<OrderIHKModal
						rootId={rootId}
						isVisible
						onDismiss={onClose}
						onOrdered={onNext}
						baseDataFragmentRef={baseDataFragmentRef}
					/>
					<OrderIHKCertificateSuccessModal isVisible onAccept={onClose} />
				</>
			)}
		</MultiDialogFlowContainer>
	);
};
