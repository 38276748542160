import { graphql } from "react-relay";

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment contentElementsCollection_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		typeDefinition {
			... on LearnOpportunityELearningContentTypeDefinition {
				extension {
					... on LearnContentExtensionImpl {
						elementsSummary {
							numFileElements
							sumTextLengthInSec
							sumVideoLengthInSec
							sumPodcastWithTimestampLengthInSec
							numExerciseElements
						}
					}
				}
			}
		}
	}
`;
