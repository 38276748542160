/**
 * @generated SignedSource<<a6347444cce4fde8fc59b215280cc23f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messages_MessagesQuery$variables = {};
export type messages_MessagesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"messagesOverview_MessagesFragment">;
};
export type messages_MessagesQuery = {
  response: messages_MessagesQuery$data;
  variables: messages_MessagesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalTitle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ImageElement",
  "kind": "LinkedField",
  "name": "element",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "messages_MessagesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "messagesOverview_MessagesFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "messages_MessagesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerQueryType",
        "kind": "LinkedField",
        "name": "Viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageViewerSchema",
            "kind": "LinkedField",
            "name": "Message",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MessagesConnection",
                "kind": "LinkedField",
                "name": "MessagesForUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessagesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Message",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MessageDefinition",
                            "kind": "LinkedField",
                            "name": "messageDefinition",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "showUntil",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MessageDefinitionPage",
                                "kind": "LinkedField",
                                "name": "pages",
                                "plural": true,
                                "selections": [
                                  (v0/*: any*/),
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "data",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v3/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v5/*: any*/)
                                        ],
                                        "type": "ImagePage",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v5/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "button",
                                            "plural": false,
                                            "selections": [
                                              (v2/*: any*/),
                                              (v3/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "text",
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v4/*: any*/)
                                                ],
                                                "type": "LinkButton",
                                                "abstractKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "ImageWithButtonPage",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasSeen",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "32eafc24e328166de7eff04e581d2f59",
    "id": null,
    "metadata": {},
    "name": "messages_MessagesQuery",
    "operationKind": "query",
    "text": "query messages_MessagesQuery {\n  ...messagesOverview_MessagesFragment\n}\n\nfragment messageModalPage_MessageFragment on MessageDefinition {\n  internalTitle\n}\n\nfragment messageModalPage_PageFragment on MessageDefinitionPage {\n  internalTitle\n  data {\n    __typename\n    ... on ImagePage {\n      element {\n        image {\n          id\n          url\n        }\n      }\n      kind\n    }\n    ... on ImageWithButtonPage {\n      element {\n        image {\n          id\n          url\n        }\n      }\n      button {\n        __typename\n        kind\n        text\n        ... on ConfirmButton {\n          text\n          kind\n        }\n        ... on TextCopyButton {\n          text\n          kind\n        }\n        ... on LinkButton {\n          text\n          url\n          kind\n        }\n      }\n      kind\n    }\n  }\n}\n\nfragment messageModal_MessageFragment on Message {\n  id\n  messageDefinition {\n    ...messageModalPage_MessageFragment\n    showUntil\n    pages {\n      id\n      ...messageModalPage_PageFragment\n    }\n    id\n  }\n}\n\nfragment messagePreviewCard_MessageFragment on Message {\n  ...messageModal_MessageFragment\n  hasSeen\n  messageDefinition {\n    id\n    pages {\n      data {\n        __typename\n        kind\n        ... on ImagePage {\n          element {\n            image {\n              url\n              id\n            }\n          }\n        }\n        ... on ImageWithButtonPage {\n          element {\n            image {\n              url\n              id\n            }\n          }\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment messagesOverview_MessagesFragment on Query {\n  Viewer {\n    Message {\n      MessagesForUser {\n        edges {\n          node {\n            id\n            ...messagePreviewCard_MessageFragment\n            messageDefinition {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8a006debbfdf0a4a5bbd50c8b95df928";

export default node;
