import {
	shade0,
	shade100,
	brandMain100,
	success100,
	brandStrong20,
	brandStrong100,
	shade20,
} from "@themes/colors";
import { OrderElementStatus } from "./order-element.interface";

export const getTextColor = (isModalVisible: boolean, status: OrderElementStatus) => {
	switch (status) {
		case "correct":
			return shade0;
		case "actuallyCorrect":
			return shade100;
		default:
			return isModalVisible ? shade20 : shade100;
	}
};

export const getBackgroundColor = (status: OrderElementStatus) => {
	switch (status) {
		case "correct":
			return success100;
		case "actuallyCorrect":
			return brandStrong20;
		default:
			return shade0;
	}
};

export const getBorderColor = (
	isDraggingOver: boolean,
	isDragging: boolean,
	isModalVisible: boolean,
	status: OrderElementStatus,
) => {
	switch (status) {
		case "correct":
			return success100;
		case "actuallyCorrect":
			return brandStrong100;
		default:
			return (!isDraggingOver || isDragging) && !isModalVisible ? shade100 : shade20;
	}
};

export const getNumberColor = (
	isDraggingOver: boolean,
	isDragging: boolean,
	isModalVisible: boolean,
	status: OrderElementStatus,
) => {
	switch (status) {
		case "correct":
			return shade0;
		default:
			return (!isDraggingOver || isDragging) && !isModalVisible ? shade100 : shade20;
	}
};

export const getDragingIconColor = (
	isDraggingOver: boolean,
	isDragging: boolean,
	isModalVisible: boolean,
	status: OrderElementStatus,
) => {
	switch (status) {
		case "correct":
			return shade0;
		case "actuallyCorrect":
			return shade100;
		default:
			return (!isDraggingOver || isDragging) && !isModalVisible ? shade100 : shade20;
	}
};

export const getIconColor = (status: OrderElementStatus) => {
	switch (status) {
		case "correct":
			return shade0;
		default:
			return brandMain100;
	}
};
