import { Icon } from "@thekeytechnology/academies-lib-webapp/components/icon";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useMatch, useNavigate } from "react-router-dom";
import { brandStrong100, shade0, shade100 } from "@themes/colors";
import { TkaL1Span } from "@themes/font-tags";
import { Wrapper } from "./navbar-item.styles";
import { NavbarItemProps } from "./navbar-item.types";

export const NavbarItem = ({ iconName, label, path }: NavbarItemProps) => {
	const navigate = useNavigate();
	const { isXLargeUp } = useWindowSize();
	const isActive = useMatch(path);
	const color = isActive ? brandStrong100 : isXLargeUp ? shade0 : shade100;

	const handleOnClick = () => {
		navigate(path);
	};

	return (
		<Wrapper onClick={handleOnClick}>
			<Icon icon={iconName} sizeRem={1} color={color} />
			<TkaL1Span tkaColor={color} bold>
				{label}
			</TkaL1Span>
		</Wrapper>
	);
};
