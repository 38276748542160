import { VideoPlayer } from "@thekeytechnology/academies-lib-webapp/components/video-player";
import { TkaH2Span } from "@themes/font-tags";
import { VideoTrailerProps } from "./video-trailer.interface";
import { VideoWrapper } from "./video-trailer.styles";

export const VideoTrailer = ({ title, videoUrl }: VideoTrailerProps) => {
	return (
		<div>
			<TkaH2Span>{title}</TkaH2Span>
			<VideoWrapper>
				<VideoPlayer url={videoUrl} />
			</VideoWrapper>
		</div>
	);
};
