/**
 * @generated SignedSource<<ff54c6fbe7aaee461830a440ea60d11e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSubmissionStatus = "active" | "failed" | "passed";
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "podcast" | "podcastWithTimestamp" | "text" | "video";
export type ViewerTreeStateKind = "CanBeStarted" | "CanNotBeStarted" | "IsFinished" | "IsStarted" | "NotVisible";
import { FragmentRefs } from "relay-runtime";
export type ELearningContentSubmissionScreen_ContentSubmissionFragment$data = {
  readonly definition: {
    readonly currentElementState?: {
      readonly element: {
        readonly elementType: ElementTypeV2;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"PodcastWithTimestamp_PodcastWithTimestampElementFragment">;
      };
    } | null;
    readonly status: ContentSubmissionStatus;
    readonly " $fragmentSpreads": FragmentRefs<"ImageElement_ElearningContentSubmissionDefinitionFragment" | "TextElement_ElearningContentSubmissionDefinitionFragment" | "VideoElement_ContentSubmissionDefinitionFragment">;
  };
  readonly id: string;
  readonly learnOpportunity: {
    readonly description: string | null;
    readonly id: string;
    readonly indexPath: ReadonlyArray<number>;
    readonly nextContentNodeId: string | null;
    readonly root: {
      readonly structureDefinition: {
        readonly viewerTreeState?: {
          readonly kind: ViewerTreeStateKind;
        };
      };
    } | null;
    readonly structureDefinition: {
      readonly title: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"contentElementsCollection_LearnOpportunityV2Fragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ClozeTextElement_ContentSubmissionFragment" | "ContentSubmissionModalContextProvider_ContentSubmissionFragment" | "MarkMistakesElement_ContentSubmissionFragment" | "MatrixElement_ContentSubmissionFragment" | "MultipleChoiceElement_ContentSubmissionFragment" | "OrderElement_ContentSubmissionFragment" | "contentFinished_ContentSubmissionFragment" | "contentSubmissionNavigation_ContentSubmissionFragment">;
  readonly " $fragmentType": "ELearningContentSubmissionScreen_ContentSubmissionFragment";
};
export type ELearningContentSubmissionScreen_ContentSubmissionFragment$key = {
  readonly " $data"?: ELearningContentSubmissionScreen_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ELearningContentSubmissionScreen_ContentSubmissionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ELearningContentSubmissionScreen_ContentSubmissionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LearnOpportunityV2",
      "kind": "LinkedField",
      "name": "learnOpportunity",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nextContentNodeId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "structureDefinition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "contentElementsCollection_LearnOpportunityV2Fragment"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "indexPath",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LearnOpportunityV2",
          "kind": "LinkedField",
          "name": "root",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "structureDefinition",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "viewerTreeState",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "kind",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "LearnOpportunityRootStructureDefinition",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "definition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "currentElementState",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "elementType",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "PodcastWithTimestamp_PodcastWithTimestampElementFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TextElement_ElearningContentSubmissionDefinitionFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "VideoElement_ContentSubmissionDefinitionFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ImageElement_ElearningContentSubmissionDefinitionFragment"
            }
          ],
          "type": "ActiveELearningContentSubmissionDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MultipleChoiceElement_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderElement_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MarkMistakesElement_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MatrixElement_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentSubmissionNavigation_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentSubmissionModalContextProvider_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClozeTextElement_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "contentFinished_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};
})();

(node as any).hash = "fc9046e212e8cd1f4af432aefe1e51b0";

export default node;
