import { parseHtml } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import { TkaP2Span, TkaH2Span } from "@themes/font-tags";
import { TextParagraphProps } from "./text-paragraph.interface";
import { HeadlineWrapper, TextWrapper } from "./text-paragraph.styles";

export const TextParagraph = ({ title, html, headline }: TextParagraphProps) => {
	return (
		<div>
			{headline && (
				<HeadlineWrapper>
					<TkaP2Span>{headline}</TkaP2Span>
				</HeadlineWrapper>
			)}
			<TkaH2Span>{title}</TkaH2Span>
			<TextWrapper>
				<TkaP2Span>{parseHtml(html)}</TkaP2Span>
			</TextWrapper>
		</div>
	);
};
