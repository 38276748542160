import styled from "styled-components";
import tw from "twin.macro";
import { TkaL1Span } from "@themes/font-tags";
import { ReactComponent as CertificateLine } from "../../assets/certificate-line.svg";

export const Wrapper = styled.div<{ noMaxWidth?: boolean }>`
	${tw`
		flex
		max-w-[24rem]
		items-center
	`}
	${({ noMaxWidth }) => noMaxWidth && tw`max-w-none`}
`;

export const Header = tw.div`
	flex
	items-center
	gap-8
	mb-16
`;

export const HeaderTitle = styled(TkaL1Span)`
	${tw`
		uppercase
	`}

	color: ${({ theme }) => theme.colors.primary40};
`;

export const Flex = tw.div`
	flex
	gap-24
	items-center
`;

export const OrderWrapper = tw.div`
	flex
	flex-col
	gap-8
`;

export const StyledLine = styled(CertificateLine)<{ reverse?: boolean }>`
	${Wrapper}:hover & {
		.svg-line {
			animation: ${(props) => (props.reverse ? "squiggle-reverse" : "squiggle")} 2s linear
				infinite;
		}
	}

	@keyframes squiggle {
		to {
			stroke-dashoffset: -300;
		}
	}

	@keyframes squiggle-reverse {
		to {
			stroke-dashoffset: 300;
		}
	}
`;
