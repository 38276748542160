import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useLazyLoadQuery } from "react-relay";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { messages_MessagesQuery } from "@relay/messages_MessagesQuery.graphql";
import { QUERY } from "@screens/messages/messages.graphql";
import { MessagesScreenSkeleton } from "@screens/messages/messages.skeleton";
import { MessagesOverview } from "@screens/messages/parts/messages-overview";

const MessagesScreenComponent = () => {
	const query = useLazyLoadQuery<messages_MessagesQuery>(
		QUERY,
		{},
		{ fetchPolicy: "store-and-network" },
	);

	return (
		<ScreenWithNavbarLayout>
			<MessagesOverview messagesFragmentRef={query} />
		</ScreenWithNavbarLayout>
	);
};

export const MessagesScreen = withSuspense(MessagesScreenComponent, MessagesScreenSkeleton);
