import { MaterialsTreeSkeleton } from "@thekeytechnology/academies-lib-webapp/components/materials-tree";
import { TabWrapper } from "./materials-tab.styles";

export const MaterialsTabSkeleton = () => {
	return (
		<TabWrapper>
			<MaterialsTreeSkeleton />
		</TabWrapper>
	);
};
