/**
 * @generated SignedSource<<e2090102a1ef82e8feefbb605ded8b41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentSubmissionScreen_ContentSubmissionFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ELearningContentSubmissionScreen_ContentSubmissionFragment" | "asyncContentSubmissionScreen_ContentSubmissionFragment">;
  readonly " $fragmentType": "ContentSubmissionScreen_ContentSubmissionFragment";
};
export type ContentSubmissionScreen_ContentSubmissionFragment$key = {
  readonly " $data"?: ContentSubmissionScreen_ContentSubmissionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentSubmissionScreen_ContentSubmissionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentSubmissionScreen_ContentSubmissionFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ELearningContentSubmissionScreen_ContentSubmissionFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "asyncContentSubmissionScreen_ContentSubmissionFragment"
    }
  ],
  "type": "ContentSubmission",
  "abstractKey": null
};

(node as any).hash = "56e5dd99cb7b8d3d36f389e3b2ffdb13";

export default node;
