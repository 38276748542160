/**
 * @generated SignedSource<<88ff15d220ed88a6250a45b95df49eea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CountryCode = "AT" | "CH" | "DE";
export type SalutationType = "Divers" | "Mr" | "Mrs" | "Unknown";
import { FragmentRefs } from "relay-runtime";
export type orderIhkModal_BaseDataFragment$data = {
  readonly AccountBaseData: {
    readonly AccountBaseData: {
      readonly __typename: string;
      readonly city: string | null;
      readonly countryCode: CountryCode | null;
      readonly firstName?: string | null;
      readonly houseNumber: string | null;
      readonly lastName?: string | null;
      readonly postalCode: string | null;
      readonly salutation?: SalutationType | null;
      readonly street: string | null;
      readonly title?: string | null;
    };
  };
  readonly " $fragmentType": "orderIhkModal_BaseDataFragment";
};
export type orderIhkModal_BaseDataFragment$key = {
  readonly " $data"?: orderIhkModal_BaseDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"orderIhkModal_BaseDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "orderIhkModal_BaseDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountBaseDataQueries",
      "kind": "LinkedField",
      "name": "AccountBaseData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "AccountBaseData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "houseNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "countryCode",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "salutation",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                }
              ],
              "type": "PrivateBaseData",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "447c3e75a4e0872a6469e7e6d15947bb";

export default node;
