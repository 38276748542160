import { graphql } from "react-relay";

export const REMINDER_NOTE_FRAGMENT = graphql`
	fragment upsertReminderModal_ReminderNoteFragment on Note
	@refetchable(queryName: "upsertReminderModal_ReminderNoteFragmentRefetch") {
		id
		...reminderForm_QueryFragment
	}
`;

export const CREATE_REMINDER_MUTATION = graphql`
	mutation upsertReminderModal_CreateReminderMutation($input: CreateNoteInput!) {
		Note {
			createNote(input: $input) {
				note {
					node {
						...upsertReminderModal_ReminderNoteFragment
					}
				}
			}
		}
	}
`;

export const EDIT_REMINDER_MUTATION = graphql`
	mutation upsertReminderModal_EditReminderMutation($input: EditNoteInput!) {
		Note {
			editNote(input: $input) {
				note {
					node {
						...upsertReminderModal_ReminderNoteFragment
					}
				}
			}
		}
	}
`;
