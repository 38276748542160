import { Image } from "@thekeytechnology/academies-lib-webapp/components/image";
import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { EpicH3Span, EpicP2Span, EpicP3Span } from "@thekeytechnology/epic-ui";
import { useFragment } from "react-relay";
import { useTheme } from "styled-components";
import { instructorCard_InstructorFragment$key } from "@relay/instructorCard_InstructorFragment.graphql";
import { INSTRUCTOR_FRAGMENT } from "./instructor-card.graphql";
import { DataWrapper, Header, Wrapper } from "./instructor-card.styles";
import { InstructorCardProps } from "./instructor-card.types";

export const InstructorCard = ({ instructorFragmentRef }: InstructorCardProps) => {
	const instructor = useFragment<instructorCard_InstructorFragment$key>(
		INSTRUCTOR_FRAGMENT,
		instructorFragmentRef,
	);

	const theme = useTheme();

	const shortDescription = stripHtml(instructor.shortDescription ?? "");

	return (
		<Wrapper>
			{instructor.image?.url && (
				<Image src={instructor.image?.url} borderRadius="12px 12px 0 0" />
			)}
			<DataWrapper>
				<Header>
					<EpicH3Span $color={theme.colors.shade100}>{instructor.name}</EpicH3Span>
					<EpicP3Span $color={theme.colors.shade60}>{instructor.position}</EpicP3Span>
				</Header>
				<EpicP2Span $color={theme.colors.shade80}>{shortDescription}</EpicP2Span>
			</DataWrapper>
		</Wrapper>
	);
};
