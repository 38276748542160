import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div`
	${tw`
		xl:h-full
		flex
		flex-row
		xl:flex-col
		xl:bg-brand-main-100
		gap-40
		items-center
		px-24
		py-16
		xl:px-16
		xl:py-24
	`}
	${({ theme }) =>
		theme.componentStyles?.navbar?.backgroundColor
			? `
				@media (min-width: ${theme.breakpoints.xlarge}px) {
					background-color: ${theme.componentStyles?.navbar?.backgroundColor.toString()}
				}`
			: tw`xl:bg-brand-main-100`}
`;

export const CenterWrapper = tw.div`
	flex-1
	flex
	flex-row
	xl:flex-col
	justify-between
	xl:justify-center
	items-center
`;

// export const StyledLogo = tw(LogoSquareSVG)`
//     w-[2.125rem]
// 	h-[2.125rem]
// 	hidden
// 	xl:block
// 	hover:cursor-pointer
// `;

export const ShowXLUp = tw.div`hidden xl:block`;
export const HideXLUp = tw.div`block xl:hidden`;
