import { Element } from "react-scroll";
import tw from "twin.macro";
import { TkaH1Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	grid 
	grid-cols-4
	md:grid-cols-12
	gap-y-32
	lg:gap-32
	overflow-hidden
`;

export const Title = tw(TkaH1Span)`
	col-span-full
`;

export const TitlePointsWrapper = tw.div`
	col-span-full
	flex
	flex-row
	justify-between
	gap-32
	items-start
`;

export const PointsWrapper = tw.div`
	absolute
	top-0
	right-0
	xl:hidden
`;

export const ContentSection = tw.div`
	grid 
	grid-cols-4
	md:grid-cols-12
	col-span-full
	gap-32
`;

export const TextParagraphWrapper = tw.div`
	relative
	col-span-full 
	flex
	flex-col
	gap-24
	lg:col-span-6
`;

export const TrailerSection = tw(Element)`
	col-span-full 
	lg:col-span-6
	xl:col-span-full
	xxl:col-span-6
`;

export const GoalsSection = tw(Element)`
	col-span-full 
	lg:col-span-6
`;

export const DefaultSection = tw(Element)`col-span-full`;

export const CertificateWrapper = tw.div`col-span-full xl:hidden`;
