import { type FC, useState, useRef, useEffect } from "react";
import type { NoteColor } from "@relay/noteCard_NoteFragment.graphql";
import { cx } from "@styled-system/css";
import { AVAILABLE_COLORS } from "./note-color-picker.consts";
import { selectedColorClass, selectorFlyoutClass, wrapperClass } from "./note-color-picker.styles";
import { NoteColorPickerProps } from "./note-color-picker.types";

export const NoteColorPicker: FC<NoteColorPickerProps> = ({
	defaultColor,
	onChange,
	selectedColorSize,
	flyoutDirection,
	className,
}) => {
	const flyoutRef = useRef<HTMLDivElement>(null);
	const [open, setOpen] = useState(false);
	const [color, setColor] = useState(defaultColor);

	const handleColorChange = (clr: NoteColor) => {
		if (color === clr) {
			clr = "default";
		}

		setColor(clr);
		onChange?.(clr);
		setOpen(false);
	};

	const handlePickerClick = () => {
		setOpen(true);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (flyoutRef.current && !flyoutRef.current.contains(event.target as Node)) {
				setOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const createColorButton = (clr: NoteColor) => {
		const isSelected = clr === color;

		return (
			<button
				key={color}
				type="button"
				onClick={() => {
					handleColorChange(clr);
				}}
				className={selectedColorClass({
					size: "lg",
					color: clr,
					isSelected,
				})}
			>
				{isSelected && (
					<svg
						width="23"
						height="20"
						viewBox="0 0 23 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M1.5 12.0503L5.58333 17.8503C5.9 18.317 6.41667 18.6003 6.98333 18.6337C7.55 18.6503 8.08333 18.4003 8.43333 17.9503L21.5 1.41699"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				)}
			</button>
		);
	};

	return (
		<div className={cx(wrapperClass, className)}>
			<button
				type="button"
				onClick={handlePickerClick}
				className={selectedColorClass({
					color,
					size: selectedColorSize,
					hasBorder: true,
				})}
			></button>
			{open && (
				<div
					ref={flyoutRef}
					className={selectorFlyoutClass({
						direction: flyoutDirection,
					})}
				>
					{Object.values(AVAILABLE_COLORS).map(createColorButton)}
				</div>
			)}
		</div>
	);
};
