import { graphql } from "react-relay";

export const QUERY = graphql`
	query navbar_Query {
		Viewer {
			Auth {
				...navbar_AuthViewerSchemaFragment
			}
		}
	}
`;

export const AUTH_VIEWER_SCHEMA_FRAGMENT = graphql`
	fragment navbar_AuthViewerSchemaFragment on AuthViewerSchema {
		...avatarWithProfileMenu_AuthViewerSchemaFragment
	}
`;
