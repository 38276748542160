import { Skeleton, SkeletonShape } from "@thekeytechnology/epic-ui";
import { useTheme } from "styled-components";
import { CenterWrapper, HideXLUp, ShowXLUp, Wrapper } from "./navbar.styles";
import { NavbarItemSkeleton } from "../navbar-item/navbar-item.skeleton";

// TODO: remove-translations
export const NavbarSkeleton = () => {
	const theme = useTheme();

	return (
		<Wrapper>
			<theme.LogoSquareComponent />
			<CenterWrapper>
				<NavbarItemSkeleton />
				<NavbarItemSkeleton />
				<NavbarItemSkeleton />
				<HideXLUp>
					<Skeleton shape={SkeletonShape.Circle} size="2.5rem" />
				</HideXLUp>
			</CenterWrapper>
			<ShowXLUp>
				<Skeleton shape={SkeletonShape.Circle} size="2.5rem" />
			</ShowXLUp>
		</Wrapper>
	);
};
