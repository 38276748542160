import { ScrollPanel } from "primereact/scrollpanel";
import styled from "styled-components";
import tw from "twin.macro";
import { TkaP2Span } from "@themes/font-tags";

export const Wrapper = tw.div`
	relative
	w-full	
	h-full
	flex
	flex-col
	overflow-y-hidden
`;

export const ContentWrapper = tw.div`
	flex-1
	flex
	flex-col
	overflow-y-scroll
`;

export const TextWrapper = tw.div`
  gap-y-12
`;

export const TextSpan = tw(TkaP2Span)`
	leading-10
`;

export const Spacing16 = tw.div`
  mb-16
`;

export const GapTextWrapper = tw.div`
	inline-block
	mx-8
`;

export const FixedBottomWrapper = styled.div<{ bottomPx: number }>`
	${tw`
		xl:hidden
		fixed
		w-full
		z-20
	`}
	bottom: ${({ bottomPx }) => bottomPx}px;
`;

export const RelativeBottomWrapper = tw.div`
	invisible
	xl:visible
`;

export const AnswersComponentWrapper = tw.div`
	bg-shade-10 
	p-24
	pb-8
	flex 
	flex-col 
	gap-12
`;

export const StyledScrollPanel = styled(ScrollPanel)`
	&.p-scrollpanel .p-scrollpanel-content {
		${tw`
			no-scrollbar
			flex
			flex-row
			flex-nowrap
			items-start
			gap-8
		`};
	}
`;
