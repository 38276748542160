import { OfferCardSkeleton } from "@thekeytechnology/academies-lib-webapp/components/offer-card";
import { Gap, GridItem, StyledGrid } from "./grid-view.styles";
import { TagsSkeleton } from "../tags/tags.skeleton";

export const GridViewSkeleton = () => {
	return (
		<div>
			<Gap />
			<TagsSkeleton />
			<Gap />
			<StyledGrid>
				<GridItem>
					<OfferCardSkeleton />
				</GridItem>
				<GridItem>
					<OfferCardSkeleton />
				</GridItem>
				<GridItem>
					<OfferCardSkeleton />
				</GridItem>
				<GridItem>
					<OfferCardSkeleton />
				</GridItem>
			</StyledGrid>
		</div>
	);
};
