import { parseHtml } from "@thekeytechnology/academies-lib-webapp/utils/parse-html";
import { useState } from "react";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { H4Span } from "@themes/font-tags";
import { ENHANCED_TEXT_LEARN_ELEMENT_FRAGMENT } from "./text-learn-element.graphql";
import { showMoreButtonClass } from "./text-learn-element.styles";
import { TextLearnElementProps } from "./text-learn-element.types";

export const TextLearnElement = ({ learnElementFragmentRef }: TextLearnElementProps) => {
	const element = useFragment(
		ENHANCED_TEXT_LEARN_ELEMENT_FRAGMENT,
		learnElementFragmentRef ?? null,
	);

	const [showMore, setShowMore] = useState(false);

	const showMoreButtonText = match({
		hasButtonText: !!element?.readMoreButtonText,
		showMore,
	})
		.with(
			{ hasButtonText: true, showMore: true },
			() => `${element?.readMoreButtonText} verbergen`,
		)
		.with(
			{ hasButtonText: true, showMore: false },
			() => `${element?.readMoreButtonText} anzeigen`,
		)
		.with({ hasButtonText: false, showMore: true }, () => "Weniger anzeigen")
		.with({ hasButtonText: false, showMore: false }, () => "Mehr anzeigen")
		.exhaustive();

	const toggleShowMore = () => {
		setShowMore((showMore) => !showMore);
	};

	return (
		<>
			{element?.text && parseHtml(element.text)}
			{element?.readMore && (
				<H4Span role="button" onClick={toggleShowMore} className={showMoreButtonClass}>
					{showMoreButtonText}
				</H4Span>
			)}
			{showMore && element?.readMore && parseHtml(element.readMore)}
		</>
	);
};
