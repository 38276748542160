import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { graphql } from "react-relay";
import { Suspense, useContext, useEffect, useState } from "react";
import { usePreloadedQuery, useQueryLoader } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { LicenseCard } from "@components/license-card";
import { OrderIhkFlow } from "@components/order-ihk-flow";
import { TextParagraph } from "@components/text-paragraph";
import { VideoTrailer } from "@components/video-trailer";
import { rootOverview_GetRootOverviewQuery } from "@relay/rootOverview_GetRootOverviewQuery.graphql";
import { GoalsSectionId, TrailerSectionId, TrainerSectionId } from "./root-overview.const";
import {
	RootOverviewWithPreloadedQueryProps,
	RootOverviewWithRootIdProps,
} from "./root-overview.interface";
import { RootOverviewSkeleton } from "./root-overview.skeleton";
import {
	CertificateWrapper,
	DefaultSection,
	GoalsSection,
	TextParagraphWrapper,
	Title,
	TitlePointsWrapper,
	TrailerSection,
	Wrapper,
} from "./root-overview.styles";
import { CertificateCard } from "../../../components/certificate-card";
import { InstructorsComponent } from "../../../components/instructors-component";
import { RootScreenContext } from "../root.context";

const QUERY = graphql`
	query rootOverview_GetRootOverviewQuery($id: ID!) {
		node(id: $id) {
			... on LearnOpportunityV2 {
				id
				description
				instructorsV2 {
					edges {
						node {
							id
						}
					}
					...instructorsComponent_InstructorsConnectionFragment
				}
				structureDefinition {
					title
					... on LearnOpportunityRootStructureDefinition {
						extension {
							... on PublishedRootExtensionImpl {
								trailer {
									url
								}
								learnGoals
								reducedData {
									receivableGamificationPoints
									isIHK
								}
								unlockInfo {
									kind
								}
								# licenseAvailability {
								# 	... on LicenseAvailabilityFreeLicensesAvailable {
								# 		numAvailable
								# 	}
								# }
								cartClickout {
									relativeLink
								}
								ihkState
							}
						}
					}
				}
			}
		}
	}
`;

export const RootOverviewWithPreloadedQuery = ({
	queryRef,
}: RootOverviewWithPreloadedQueryProps) => {
	const { t } = useTkaTranslation("courseScreen");
	const { setSpyEnabled } = useContext(RootScreenContext);

	const { node } = usePreloadedQuery<rootOverview_GetRootOverviewQuery>(QUERY, queryRef);

	const navigate = useNavigate();

	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleOrderOnClick = () => {
		setIsModalVisible(true);
	};

	const handleOnDismiss = () => {
		setIsModalVisible(false);
	};

	useEffect(() => {
		setSpyEnabled(true);
		return () => {
			setSpyEnabled(false);
		};
	}, []);

	const title = t("course_screen.overviewTitle");
	const contentTitle = t("course_screen.courseDescriptionTitle");
	const videoTitle = t("course_screen.trailerTitle");
	const goalsTitle = t("course_screen.goalsTitle");

	const isIHK = node?.structureDefinition?.extension?.reducedData?.isIHK;
	const availableLicensesCount = 0; // node?.structureDefinition?.extension?.licenseAvailability?.numAvailable ?? 0;
	const canOrderIHK = node?.structureDefinition?.extension?.ihkState === "ReadyToOrder";

	const rootName = node?.structureDefinition?.title ?? "";
	const goalsHtml = node?.structureDefinition?.extension?.learnGoals;

	const videoUrl = node?.structureDefinition?.extension?.trailer?.url;
	const needLicense = node?.structureDefinition?.extension?.unlockInfo?.kind === "Demo";
	const hasInstructors = (node?.instructorsV2?.edges?.length ?? 0) > 0;

	const handleBuyRootOnClick = () => {
		const link = node?.structureDefinition?.extension?.cartClickout?.relativeLink;
		link && navigate(link);
	};

	const ihkCertificate = isIHK && node.id && (
		<CertificateWrapper>
			<CertificateCard
				canOrder={canOrderIHK}
				rootName={rootName}
				rootId={node.id}
				onOrder={handleOrderOnClick}
			/>
		</CertificateWrapper>
	);

	return (
		<Wrapper>
			<TitlePointsWrapper>
				<Title>{title}</Title>
			</TitlePointsWrapper>
			{canOrderIHK && ihkCertificate}

			<TextParagraphWrapper>
				<TextParagraph title={contentTitle} html={node?.description ?? ""} />
				{availableLicensesCount === 0 && needLicense && (
					<Button
						label="Lizenz erwerben"
						colorVersion="outline"
						iconName="shoppingBasket"
						onClick={handleBuyRootOnClick}
					/>
				)}
			</TextParagraphWrapper>
			{goalsHtml && (
				<GoalsSection name={TrailerSectionId} id={GoalsSectionId}>
					<TextParagraph title={goalsTitle} html={goalsHtml} />
				</GoalsSection>
			)}
			{videoUrl && (
				<TrailerSection name={TrailerSectionId} id={TrailerSectionId}>
					<VideoTrailer title={videoTitle} videoUrl={videoUrl} />
				</TrailerSection>
			)}
			{node?.instructorsV2 && hasInstructors && (
				<DefaultSection name={TrailerSectionId} id={TrainerSectionId}>
					<InstructorsComponent instructorsConnectionFragmentRef={node?.instructorsV2} />
				</DefaultSection>
			)}
			{!canOrderIHK && ihkCertificate}
			{needLicense && (
				<CertificateWrapper>
					<LicenseCard />
				</CertificateWrapper>
			)}
			{node?.id && (
				<OrderIhkFlow
					rootId={node.id}
					isVisible={isModalVisible}
					onDismiss={handleOnDismiss}
				/>
			)}
		</Wrapper>
	);
};

export const RootOverviewWithRootId = ({ rootId }: RootOverviewWithRootIdProps) => {
	const [queryReference, loadQuery] = useQueryLoader<rootOverview_GetRootOverviewQuery>(QUERY);

	useEffect(() => {
		loadQuery({ id: rootId });
	}, [loadQuery, rootId]);

	return (
		<Suspense fallback={<RootOverviewSkeleton />}>
			{queryReference && <RootOverviewWithPreloadedQuery queryRef={queryReference} />}
		</Suspense>
	);
};

export const RootOverview = () => {
	const { rootId } = useParams();
	return rootId ? <RootOverviewWithRootId rootId={rootId} /> : null;
};
