/**
 * @generated SignedSource<<edfeada75f9dc2871e05a5d48baf8947>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectionMenu_LearnOpportunityV2Query$variables = {
  id: string;
  skip: boolean;
};
export type selectionMenu_LearnOpportunityV2Query$data = {
  readonly node?: {
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"upsertNoteModal_LearnOpportunityV2Fragment">;
  } | null;
};
export type selectionMenu_LearnOpportunityV2Query = {
  response: selectionMenu_LearnOpportunityV2Query$data;
  variables: selectionMenu_LearnOpportunityV2Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "selectionMenu_LearnOpportunityV2Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "upsertNoteModal_LearnOpportunityV2Fragment"
                  }
                ],
                "type": "LearnOpportunityV2",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "selectionMenu_LearnOpportunityV2Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnail",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "structureDefinition",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "definitionType",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "typeDefinition",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LearnOpportunityV2",
                            "kind": "LinkedField",
                            "name": "children",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "structureDefinition",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "LearnOpportunityBranchTypeDefinition",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LearnOpportunityV2",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5a7efb9ecc25d2ff85f639c537885f26",
    "id": null,
    "metadata": {},
    "name": "selectionMenu_LearnOpportunityV2Query",
    "operationKind": "query",
    "text": "query selectionMenu_LearnOpportunityV2Query(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on LearnOpportunityV2 {\n      id\n      ...upsertNoteModal_LearnOpportunityV2Fragment\n    }\n    id\n  }\n}\n\nfragment upsertNoteModal_LearnOpportunityV2Fragment on LearnOpportunityV2 {\n  id\n  image {\n    name\n    id\n    thumbnail\n  }\n  description\n  structureDefinition {\n    __typename\n    title\n    definitionType\n  }\n  typeDefinition {\n    __typename\n    ... on LearnOpportunityBranchTypeDefinition {\n      children {\n        id\n        description\n        structureDefinition {\n          __typename\n          title\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3bb53f66955bdabed07975fd5b58cd46";

export default node;
