/**
 * @generated SignedSource<<c208577e514c16f25b20de36f3f15ed0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type upsertNoteModal_NoteFragmentRefetch$variables = {
  id: string;
};
export type upsertNoteModal_NoteFragmentRefetch$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"upsertNoteModal_NoteFragment">;
  } | null;
};
export type upsertNoteModal_NoteFragmentRefetch = {
  response: upsertNoteModal_NoteFragmentRefetch$data;
  variables: upsertNoteModal_NoteFragmentRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "LearnOpportunityV2",
  "kind": "LinkedField",
  "name": "publishedRootNode",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "upsertNoteModal_NoteFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "upsertNoteModal_NoteFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "upsertNoteModal_NoteFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "markedText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "source",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "element",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "elementType",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "type": "ElementNoteSourceType",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v5/*: any*/),
                    "type": "ModuleNoteSource",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v5/*: any*/),
                    "type": "CourseNoteSource",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Note",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "55becdb153288afa558528cf8127382b",
    "id": null,
    "metadata": {},
    "name": "upsertNoteModal_NoteFragmentRefetch",
    "operationKind": "query",
    "text": "query upsertNoteModal_NoteFragmentRefetch(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...upsertNoteModal_NoteFragment\n    id\n  }\n}\n\nfragment upsertNoteModal_NoteFragment on Note {\n  id\n  content\n  markedText\n  color\n  source {\n    __typename\n    kind\n    ... on ElementNoteSourceType {\n      element {\n        __typename\n        elementType\n        id\n      }\n      publishedRootNode {\n        id\n        structureDefinition {\n          __typename\n          title\n          definitionType\n        }\n        image {\n          url\n          id\n        }\n      }\n    }\n    ... on ModuleNoteSource {\n      publishedRootNode {\n        id\n        structureDefinition {\n          __typename\n          title\n          definitionType\n        }\n        image {\n          url\n          id\n        }\n      }\n    }\n    ... on CourseNoteSource {\n      publishedRootNode {\n        id\n        structureDefinition {\n          __typename\n          title\n          definitionType\n        }\n        image {\n          url\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c80ceb081fd0075b13923c2797f1207";

export default node;
