import { useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { GapText } from "@components/gap-text";
import { P2Span } from "@themes/font-tags";
import { MATRIX_LEARN_ELEMENT_FRAGMENT } from "./matrix-learn-element.graphql";
import {
	tableBodyGapTextWrapperTableDataClass,
	tableBodyTableHeadClass,
	tableClass,
	tableHeadFirstTableHeadClass,
	tableHeadTableHeadClass,
} from "./matrix-learn-element.styles";
import type { CellCords, ElementCell, MatrixLearnElementProps } from "./matrix-learn-element.types";
import { equalCords, get2DArray, getIndexKey } from "./matrix-learn-element.utils";

export const MatrixLearnElement = ({
	disabled,
	hasBorders,
	state,
	onCellClick,
	getStatusForCell,
	learnElementFragmentRef,
}: MatrixLearnElementProps) => {
	const [selectedCellCords, setSelectedCellCords] = useState<CellCords>();

	const element = useFragment(MATRIX_LEARN_ELEMENT_FRAGMENT, learnElementFragmentRef ?? null);

	const cellMap = useMemo(() => get2DArray(element?.cells || []), [element?.cells]);
	const headRow = cellMap[0] || [];
	const bodyRows = cellMap.slice(1);

	const handleCellClick = (xIdx: number, yIdx: number) => {
		if (!xIdx || !yIdx || disabled) return;
		setSelectedCellCords({ xIdx, yIdx });
		onCellClick?.(xIdx, yIdx);
	};

	const renderHeadCell = (cell: ElementCell, cellIndex: number) => {
		if (cellIndex === 0) {
			return (
				<th
					key={cellIndex}
					className={tableHeadFirstTableHeadClass({
						hasBorders,
					})}
				/>
			);
		}

		return (
			<th
				key={cellIndex}
				className={tableHeadTableHeadClass({
					hasBorders,
				})}
			>
				<P2Span>{cell.content}</P2Span>
			</th>
		);
	};

	const renderBodyCell = (cell: ElementCell, cellIndex: number) => {
		if (cellIndex === 0) {
			return (
				<th
					key={cellIndex}
					className={tableBodyTableHeadClass({
						hasBorders,
					})}
				>
					<P2Span>{cell.content}</P2Span>
				</th>
			);
		}

		const inputtedAnswers = state?.[getIndexKey(cell.xIdx, cell.yIdx)];
		const answer = inputtedAnswers ? cell.answers?.[inputtedAnswers.index] : undefined;
		const selected = equalCords(selectedCellCords, cell);
		const status = getStatusForCell?.(cell.xIdx, cell.yIdx);

		const handleTdClick = (e: React.MouseEvent<HTMLTableDataCellElement>) => {
			e.stopPropagation();
			handleCellClick(cell.xIdx, cell.yIdx);
		};

		return (
			<td
				key={`${cell.xIdx}-${cell.yIdx}`}
				className={tableBodyGapTextWrapperTableDataClass}
				onClick={handleTdClick}
			>
				<GapText useMinWidth text={answer?.content} selected={selected} status={status} />
			</td>
		);
	};

	return (
		<table className={tableClass}>
			<thead>
				<tr>{headRow.map(renderHeadCell)}</tr>
			</thead>
			<tbody>
				{bodyRows.map((row, rowIndex) => (
					<tr key={`row-${rowIndex}`}>{row.map(renderBodyCell)}</tr>
				))}
			</tbody>
		</table>
	);
};
