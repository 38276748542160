import { css, cva } from "@styled-system/css";

export const wrapperClass = css({
	position: "relative",
	display: "flex",
});

export const selectedColorClass = cva({
	base: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "24",
		height: "24",
		borderRadius: "full",
		boxShadow: "flyout",
	},
	variants: {
		color: {
			default: {
				backgroundColor: "shade.80",
			},
			yellow: {
				backgroundColor: "warning.20",
			},
			blue: {
				backgroundColor: "info.20",
			},
			green: {
				backgroundColor: "success.20",
			},
			red: {
				backgroundColor: "error.20",
			},
		},
		size: {
			md: {
				minWidth: "24",
				minHeight: "24",
			},
			lg: {
				minWidth: "40",
				minHeight: "40",
			},
		},
		hasBorder: {
			true: {
				border: "2px solid",
				borderColor: "shade.0",
			},
		},
		isSelected: {
			true: {
				outline: "2px solid",
				outlineOffset: "-4",
			},
			false: {
				borderColor: "shade.0",
			},
		},
	},
	compoundVariants: [
		{
			color: "yellow",
			isSelected: true,
			css: {
				outlineColor: "warning.100",
				color: "warning.100",
			},
		},
		{
			color: "blue",
			isSelected: true,
			css: {
				outlineColor: "info.100",
				color: "info.100",
			},
		},
		{
			color: "green",
			isSelected: true,
			css: {
				outlineColor: "success.100",
				color: "success.100",
			},
		},
		{
			color: "red",
			isSelected: true,
			css: {
				outlineColor: "error.100",
				color: "error.100",
			},
		},
	],
	defaultVariants: {
		color: "yellow",
		size: "md",
		hasBorder: false,
	},
});

export const selectorFlyoutClass = cva({
	base: {
		position: "absolute",
		top: "100%",
		display: "grid",
		gridTemplateColumns: "[repeat(2, 1fr)]",
		gap: "8",
		backgroundColor: "shade.0",
		borderRadius: "24",
		boxShadow: "flyout",
		padding: "8",
	},
	variants: {
		direction: {
			row: {
				flexDirection: "row",
				right: "0",
				transform: "translate(0, -50%)",
			},
			column: {
				flexDirection: "column",
				left: "50%",
				transform: "translate(-50%, -50%)",
			},
		},
	},
	defaultVariants: {
		direction: "column",
	},
});
