import { SideMenuContentPadding } from "@thekeytechnology/academies-lib-webapp/containers/side-menu-content-padding";
import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { graphql, useFragment } from "react-relay";
import { VideoLearnElement } from "@components/video-learn-element";
import { VideoElement_ContentSubmissionDefinitionFragment$key } from "@relay/VideoElement_ContentSubmissionDefinitionFragment.graphql";
import { H1Span } from "@themes/font-tags";
import { VideoElementProps } from "./video-element.interface";
import { titleClass, wrapperClass } from "./video-element.styles";

const ELEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT = graphql`
	fragment VideoElement_ContentSubmissionDefinitionFragment on ActiveELearningContentSubmissionDefinition {
		currentElementState {
			element {
				title
				id
				... on VideoLearnElement {
					streamFile {
						url
					}
					subtitles {
						language
						subtitles {
							startTimeInSec
							endTimeInSec
							text
						}
					}
					thumbnailFile {
						url
					}
					...videoLearnElement_VideoLearnElementFragment
				}
			}
		}
	}
`;

export const VideoElement = ({ contentSubmissionDefinitionFragmentRef }: VideoElementProps) => {
	const contentSubmissionDefinition =
		useFragment<VideoElement_ContentSubmissionDefinitionFragment$key>(
			ELEARNING_CONTENT_SUBMISSION_DEFINITION_FRAGMENT,
			contentSubmissionDefinitionFragmentRef,
		);
	const element = contentSubmissionDefinition.currentElementState?.element;

	return (
		<SideMenuContentPadding
			className={wrapperClass}
			data-selection-menu-element-id={element?.id}
		>
			<H1Span className={titleClass}>{stripHtml(element?.title || "")}</H1Span>
			<VideoLearnElement learnElementFragmentRef={element} />
		</SideMenuContentPadding>
	);
};
