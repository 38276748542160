import { type FC } from "react";
import { useFragment } from "react-relay";
import { match } from "ts-pattern";
import { ClozeTextLearnElement } from "@components/cloze-text-learn-element";
import { ImageLearnElement } from "@components/image-learn-element";
import { MarkMistakesLearnElement } from "@components/mark-mistakes-learn-element";
import { MatrixLearnElement } from "@components/matrix-learn-element";
import { MultipleChoiceLearnElement } from "@components/multiple-choice-learn-element";
import { OrderLearnElement } from "@components/order-learn-element";
import { PodcastWithTimestamp } from "@components/podcast-with-timestamps-element";
import { TextLearnElement } from "@components/text-learn-element";
import { VideoLearnElement } from "@components/video-learn-element";
import { H1Span } from "@themes/font-tags";
import {
	LEARN_ELEMENT_FRAGMENT,
	CONTENT_SUBMISSION_FRAGMENT,
} from "./note-source-element-renderer.graphql";
import {
	titleClass,
	textLearnElementWrapperClass,
	videoLearnElementWrapperClass,
	orderLearnElementWrapperClass,
	clozeTextLearnElementWrapperClass,
	markMistakesLearnElementWrapperClass,
	multipleChoiceLearnElementWrapperClass,
} from "./note-source-element-renderer.styles";
import type { NoteSourceElementRendererProps } from "./note-source-element-renderer.types";

export const NoteSourceElementRenderer: FC<NoteSourceElementRendererProps> = ({
	contentSubmissionFragmentRef,
	learnElementFragmentRef,
}) => {
	const contentSubmissionData = useFragment(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef ?? null,
	);
	const elementData = useFragment(LEARN_ELEMENT_FRAGMENT, learnElementFragmentRef ?? null);

	const elementType =
		contentSubmissionData?.definition.currentElementState?.element.elementType ||
		elementData?.elementType;

	if (!elementType) return null;

	const element = match(elementType)
		.with("matrix", () => (
			<MatrixLearnElement
				learnElementFragmentRef={
					elementData ?? contentSubmissionData?.definition.currentElementState?.element
				}
			/>
		))
		.with("multipleChoice", () => (
			<div className={multipleChoiceLearnElementWrapperClass}>
				<MultipleChoiceLearnElement
					disabled
					learnElementFragmentRef={
						elementData ??
						contentSubmissionData?.definition.currentElementState?.element
					}
				/>
			</div>
		))
		.with("text", "enhancedText", () => (
			<div className={textLearnElementWrapperClass}>
				<TextLearnElement
					learnElementFragmentRef={
						elementData ??
						contentSubmissionData?.definition.currentElementState?.element
					}
				/>
			</div>
		))
		.with("podcast", "podcastWithTimestamp", () => (
			<PodcastWithTimestamp
				podcastWithTimestampFragmentRef={
					elementData ?? contentSubmissionData?.definition.currentElementState?.element!
				}
			/>
		))
		.with("video", () => (
			<div className={videoLearnElementWrapperClass}>
				<VideoLearnElement
					learnElementFragmentRef={
						elementData ??
						contentSubmissionData?.definition.currentElementState?.element!
					}
				/>
			</div>
		))
		.with("markMistakes", () => (
			<div className={markMistakesLearnElementWrapperClass}>
				<MarkMistakesLearnElement
					learnElementFragmentRef={
						elementData ??
						contentSubmissionData?.definition.currentElementState?.element
					}
				/>
			</div>
		))
		.with("file", () => (
			<ImageLearnElement
				learnElementFragmentRef={
					elementData ?? contentSubmissionData?.definition.currentElementState?.element
				}
			/>
		))
		.with("clozeText", () => (
			<div className={clozeTextLearnElementWrapperClass}>
				<ClozeTextLearnElement
					disabled
					learnElementFragmentRef={
						elementData ??
						contentSubmissionData?.definition.currentElementState?.element
					}
				/>
			</div>
		))
		.with("order", () => (
			<div className={orderLearnElementWrapperClass}>
				<OrderLearnElement
					dragDisabled
					learnElementFragmentRef={
						elementData ??
						contentSubmissionData?.definition.currentElementState?.element
					}
				/>
			</div>
		))
		.exhaustive();

	return (
		<div>
			<H1Span className={titleClass}>{elementData?.title}</H1Span>
			{element}
		</div>
	);
};
