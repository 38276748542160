import { graphql } from "react-relay";

export const ENHANCED_TEXT_LEARN_ELEMENT_FRAGMENT = graphql`
	fragment textLearnElement_EnhancedTextLearnElementFragment on EnhancedTextLearnElement {
		id
		title
		text
		readMore
		readMoreButtonText
	}
`;
