import { css, cva } from "@styled-system/css";
import {
	HORIZONTAL_PADDING_MEDIUM_UP_REM,
	HORIZONTAL_PADDING_SMALL_REM,
} from "./video-learn-element.consts";

export const videoPlayerWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	marginTop: "32",
	overflow: "hidden",
	maxHeight: `[calc((100dvw - ${HORIZONTAL_PADDING_SMALL_REM}rem) / (16/9))]`,
	md: {
		maxHeight: `[calc((100dvw - ${HORIZONTAL_PADDING_MEDIUM_UP_REM}rem) / (16/9))]`,
	},
});

export const tabBarClass = css({
	flex: "1",
	marginTop: "32",
});

export const transcriptsWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "16",
	maxHeight: "[40vh]",
	paddingTop: "32",
	paddingBottom: "24",
	overflowY: "auto",
	md: {
		paddingBottom: "40",
	},
});

export const subtitleItemClass = css({
	display: "flex",
	gap: "16",
});

export const subtitleTextClass = cva({
	variants: {
		active: {
			true: {
				color: "shade.100",
			},
			false: {
				color: "shade.20",
			},
		},
	},
	defaultVariants: {
		active: false,
	},
});
