import { graphql } from "react-relay";

export const LEARN_OPPORTUNITY_FRAGMENT = graphql`
	fragment asyncContentOverview_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		typeDefinition {
			... on LearnAsyncContentTypeDefinition {
				task {
					status
					elementKind
					...UploadAsyncContentOverview_AsyncElementTaskFragment
				}
				taskDescription
			}
		}
		attachmentsV2 {
			id
		}
		indexPath
		root {
			structureDefinition {
				... on LearnOpportunityRootStructureDefinition {
					viewerTreeState {
						kind
						... on IsStartedViewerTreeState {
							headContentId
						}
					}
					extension {
						... on PublishedRootExtensionImpl {
							unlockInfo {
								kind
								... on UnlockInfoDemo {
									firstContentId
								}
							}
						}
					}
				}
			}
			typeDefinition {
				... on LearnOpportunityBranchTypeDefinition {
					children {
						typeDefinition {
							... on LearnOpportunityBranchTypeDefinition {
								children {
									id
									indexPath
								}
							}
						}
					}
				}
			}
		}
		...attachmentsCollection_AttachmentsCollectionFragment
	}
`;
