import { RouteDefinition } from "@thekeytechnology/academies-lib-webapp/navigation";
import { Path as LibraryPath } from "@thekeytechnology/academies-lib-webapp/router";
import { ForgotPasswordScreen } from "@thekeytechnology/academies-lib-webapp/screens/forgot-password";
import { Navigate } from "react-router-dom";
import { DashboardScreen } from "@screens/dashboard/dashboard.screen";
import { MessagesScreen } from "@screens/messages";
import { NotesScreen } from "@screens/notes";
import { BranchNodesPath, MaterialsPath, Path, RootOverviewPath } from "./paths";
import { ContentSubmission } from "../screens/content-submission/ContentSubmission.screen";
import { LoginScreen } from "../screens/login";
import { OffersScreen } from "../screens/offers";
import { RootScreen } from "../screens/root";
import { ContentOverview } from "../screens/root/content-overview";
import { ContentOverviewMobile } from "../screens/root/content-overview-mobile";
import { MaterialOverview } from "../screens/root/material-overview";
import { RootOverview } from "../screens/root/root-overview";
import { SignUpScreen } from "../screens/sign-up";

export const Routes: RouteDefinition[] = [
	{
		requiredPermissions: "only-logged-out",
		path: LibraryPath.login.path,
		element: <LoginScreen />,
	},
	{
		requiredPermissions: "only-logged-out",
		path: LibraryPath.forgotPassword.path,
		element: <ForgotPasswordScreen />,
	},
	// {
	// 	requiredPermissions: "only-logged-out",
	// 	path: Path.resetPassword.withIdPlaceholder().path,
	// 	element: <ResetPasswordScreen />,
	// },
	{
		requiredPermissions: "only-logged-out",
		path: LibraryPath.signup.path,
		element: <SignUpScreen />,
	},
	{
		requiredPermissions: "first-login",
		path: Path.login.path,
		element: <Navigate replace to={Path.orientation.path} />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.signup.path,
		element: <Navigate replace to={Path.orientation.path} />,
	},
	// {
	// 	requiredPermissions: "logged-in-and-logged-out",
	// 	path: Path.acceptInvitation.withIdPlaceholder().path,
	// 	element: <AcceptInvitationScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in-and-logged-out",
	// 	path: Path.acceptInvitation.withIdPlaceholder().error.path,
	// 	element: <AcceptInvitationErrorScreen />,
	// },
	// {
	// 	requiredPermissions: "only-logged-out",
	// 	path: Path.activate.withIdPlaceholder().success.path,
	// 	element: <ActivateSuccessScreen />,
	// },
	// {
	// 	requiredPermissions: "only-logged-out",
	// 	path: Path.activate.withIdPlaceholder().error.path,
	// 	element: <ActivateErrorScreen />,
	// },
	// {
	// 	requiredPermissions: "only-logged-out",
	// 	path: Path.activate.withIdPlaceholder().path,
	// 	element: <ActivateScreen />,
	// },
	{
		requiredPermissions: "logged-in",
		path: Path.progression.path,
		element: <OffersScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.dashboard.path,
		element: <DashboardScreen />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.notes.path,
		element: <NotesScreen />,
	},
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.coachProfile.withIdPlaceholder().path,
	// 	element: <CoachScreen />,
	// },
	{
		requiredPermissions: "logged-in",
		path: Path.root.withIdPlaceholder().path,
		element: <RootScreen />,
		children: [
			{
				path: RootOverviewPath.pathName,
				element: <RootOverview />,
			},
			{
				path: BranchNodesPath.pathName,
				element: <ContentOverview />,
			},
			{
				path: BranchNodesPath.withIdPlaceholder().path,
				element: <ContentOverview />,
			},
			{
				path: MaterialsPath.pathName,
				element: <MaterialOverview />,
			},
			{
				path: MaterialsPath.withIdPlaceholder().path,
				element: <MaterialOverview />,
			},
		],
	},
	{
		requiredPermissions: "logged-in",
		path: Path.root.withIdPlaceholder().branchNodes.withIdPlaceholder().lessonOverview.path,
		element: <ContentOverviewMobile />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.contentSubmission.withIdPlaceholder().path,
		element: <ContentSubmission />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.contentSubmission.withIdPlaceholder().branchNodes.path,
		element: <ContentSubmission />,
	},

	{
		requiredPermissions: "logged-in",
		path: "/",
		element: <Navigate replace to={Path.dashboard.path} />,
	},
	{
		requiredPermissions: "logged-in",
		path: Path.messages.path,
		element: <MessagesScreen />,
	},
	// {
	// 	requiredPermissions: "logged-in-and-logged-out",
	// 	path: Path.createOrder.withIdPlaceholder().path,
	// 	element: <CreateOrderScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in-and-logged-out",
	// 	path: Path.shoppingCart.withIdPlaceholder().products.path,
	// 	element: <ShoppingCartProductsScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in-and-logged-out",
	// 	path: Path.shoppingCart.withIdPlaceholder().invoice.path,
	// 	element: <ShoppingCartInvoiceScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in-and-logged-out",
	// 	path: Path.shoppingCart.withIdPlaceholder().paymentMethod.path,
	// 	element: <ShoppingCartPaymentMethodScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in-and-logged-out",
	// 	path: Path.shoppingCart.withIdPlaceholder().overview.path,
	// 	element: <ShoppingCartOverviewScreenWithPaymentContext />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.shoppingCart.withIdPlaceholder().success.path,
	// 	element: <ShoppingCartSuccessScreen />,
	// },

	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.profileMenu.path,
	// 	element: <ProfileMenuScreen />,
	// },

	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.profileMenu.profile.path,
	// 	element: <ProfileScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.appointments.path,
	// 	element: <AppointmentsScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.profileMenu.licenses.path,
	// 	element: <LicensesScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.profileMenu.licenses.detail.withIdPlaceholder().path,
	// 	element: <LicenseDetails />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.profileMenu.licenses.detail.withIdPlaceholder().createSingle.path,
	// 	element: <LicenseDetails />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.profileMenu.licenses.detail.withIdPlaceholder().createGroup.path,
	// 	element: <LicenseDetails />,
	// },

	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.profileMenu.vocabulary.path,
	// 	element: <VocabularyScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.documents.path,
	// 	element: <MyDocumentsScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.profileMenu.myAvailabilities.path,
	// 	element: <MyAvailabilitiesScreen />,
	// },

	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.coaching.withIdPlaceholder().path,
	// 	element: <CoachingDetailsScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.bookAppointment.withIdPlaceholder().path,
	// 	element: <BookAppointmentScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.orientation.path,
	// 	element: <OrientationScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.placement.path,
	// 	element: <PlacementScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.profileMenu.myCoachingOffers.path,
	// 	element: <MyCoachingOffersScreen />,
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.profileMenu.account.path,
	// 	element: <AccountScreen />,
	// 	children: [
	// 		{
	// 			path: AccountDataPath.pathName,
	// 			element: <GroupsTab />,
	// 		},
	// 		{
	// 			path: MembersPath.pathName,
	// 			element: <GroupsTab />,
	// 		},
	// 		{
	// 			path: GroupsPath.pathName,
	// 			element: <GroupsTab />,
	// 		},
	// 		{
	// 			path: GroupsPath.withIdPlaceholder().path,
	// 			element: <GroupDetails />,
	// 		},
	// 	],
	// },
	// {
	// 	requiredPermissions: "logged-in",
	// 	path: Path.profileMenu.imprint.path,
	// 	element: <ImprintScreen />,
	// },
];
