import { Skeleton } from "@thekeytechnology/epic-ui";
import { useTheme } from "styled-components";

export const NoteCardSkeleton = () => {
	const theme = useTheme();

	const width = "100%";
	const height = "7rem";
	const borderRadius = theme.borderRadius?.borderRadius12;

	return <Skeleton width={width} height={height} borderRadius={borderRadius} />;
};
