import { NoteCardSkeleton } from "@components/note-card";
import { grid } from "./notes-overview.styles";

export const NotesOverviewSkeleton = () => {
	return (
		<div className={grid}>
			<NoteCardSkeleton />
			<NoteCardSkeleton />
			<NoteCardSkeleton />

			<NoteCardSkeleton />
			<NoteCardSkeleton />
			<NoteCardSkeleton />

			<NoteCardSkeleton />
			<NoteCardSkeleton />
			<NoteCardSkeleton />
		</div>
	);
};
