export const Levels = {
	1: "Novize",
	10: "Lehrling",
	20: "Geselle",
	30: "Experte",
	40: "Meister",
	50: "Großmeister",
};

export const WEEKDAYS = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"] as const;

export const WEEKDAY_CIRCLE_RADIUS = 20;
export const WEEKDAY_CIRCLE_DIAMETER = WEEKDAY_CIRCLE_RADIUS * 2;
export const WEEKDAY_CIRCLE_STROKE_WIDTH = 7.5;
export const WEEKDAY_CIRCLE_NUMBER_OF_DASHES = 20;
