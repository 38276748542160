import { graphql } from "react-relay";

export const ORDER_LEARN_ELEMENT_FRAGMENT = graphql`
	fragment orderLearnElement_OrderLearnElementFragment on OrderLearnElement {
		id
		title
		answeringTypeText
		orderItems {
			id
			text
		}
	}
`;
