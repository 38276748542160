import { FC } from "react";
import { ReactComponent as QuoteIcon } from "@assets/quotation_mark.svg";
import {
	MarketingImage,
	Quote,
	QuoteAuthor,
	QuoteHeadline,
	QuoteWrapper,
	Wrapper,
} from "./login-marketing.styles";

export const LoginMarketing: FC = () => {
	return (
		<Wrapper>
			<Quote>
				<QuoteWrapper>
					<QuoteIcon />
					<QuoteHeadline>
						Spannender Interaktivlehrgang mit beeindruckender Bild- und Tonqualität.
						Viel Input von Expert*innen aus der Praxis für die Praxis. Klare Empfehlung
						für Zeit- und Ortsunabhängiges Lernen auf höchsten Niveau. Die inkludierte
						IHK-Zertifizierung rundet das tolle Angebot ab.
					</QuoteHeadline>
					<QuoteAuthor>Sascha (Google Rezension)</QuoteAuthor>
				</QuoteWrapper>
				<MarketingImage src="https://via.placeholder.com/774x548" alt="Marketing Image" />
			</Quote>
		</Wrapper>
	);
};
