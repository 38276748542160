import { withSuspense } from "@thekeytechnology/academies-lib-webapp/components/with-suspense";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { TkaH1Span } from "@themes/font-tags";
import { IOffersScreenContext, OffersScreenContext } from "./offers.context";
import { OffersScreenSkeleton } from "./offers.skeleton";
import { Header, NoGrowWrapper, SearchAndFiltersWrapper, SearchBarWrapper } from "./offers.styles";
import { OffersLocationState } from "./offers.types";
import { Filters } from "./parts/filters";
import { GridView } from "./parts/grid-view";
import { SearchBar } from "./parts/search-bar";
import { SearchBarMobile } from "./parts/search-bar/search-bar-mobile";

const OffersScreenComponent = () => {
	const { isMediumUp } = useWindowSize();
	const { state: locationState }: OffersLocationState = useLocation();
	const [state, setState] = useState<Omit<IOffersScreenContext, "helpers">>({
		filters: {
			tagIds: [],
			kinds: [],
			...locationState?.filters,
		},
		productIds: [],
		activeIndex: 0,
		filtersAreVisible: false,
		searchIsVisibleOnMobile: false,
	});

	const helpers: Pick<IOffersScreenContext, "helpers"> = {
		helpers: {
			setText: (text) => {
				setState((state) => ({
					...state,
					filters: { ...state.filters, text },
				}));
			},
			setKinds: (kinds) => {
				setState((state) => ({
					...state,
					filters: { ...state.filters, kinds },
				}));
			},
			setTagIds: (tagIds) => {
				setState((state) => ({
					...state,
					filters: { ...state.filters, tagIds },
				}));
			},
			setProductIds: (productIds) => {
				setState((state) => ({
					...state,
					productIds: productIds,
				}));
			},
			setActiveIndex: (activeIndex) => {
				setState((state) => ({ ...state, activeIndex }));
			},
			toggleKind: (kind) => {
				const {
					filters: { kinds },
				} = { ...state };
				if (kinds.includes(kind)) {
					const newKinds = [...kinds].filter((k) => k !== kind);
					setState({ ...state, filters: { ...state.filters, kinds: newKinds } });
				} else {
					setState({
						...state,
						filters: { ...state.filters, kinds: [...state.filters.kinds, kind] },
					});
				}
			},
			toggleFiltersAreVisible: () => {
				setState((state) => ({ ...state, filtersAreVisible: !state.filtersAreVisible }));
			},
			toggleSearchIsVisibleOnMobile: () => {
				setState((state) => ({
					...state,
					searchIsVisibleOnMobile: !state.searchIsVisibleOnMobile,
				}));
			},
		},
	};
	const contextValue: IOffersScreenContext = { ...state, helpers: helpers.helpers };

	const { t } = useTkaTranslation("offers");
	const header = t("Angebotskatalog");

	const handleToggleOnClick = () => {
		if (isMediumUp) return;
		helpers.helpers.toggleSearchIsVisibleOnMobile();
	};

	useEffect(() => {
		if (!isMediumUp) return;
		if (state.searchIsVisibleOnMobile) {
			helpers.helpers.toggleSearchIsVisibleOnMobile();
		}
	}, [isMediumUp, state.searchIsVisibleOnMobile]);

	return (
		<OffersScreenContext.Provider value={contextValue}>
			<ScreenWithNavbarLayout>
				<Header>
					<TkaH1Span>{header}</TkaH1Span>
					<SearchAndFiltersWrapper>
						<SearchBar onClick={handleToggleOnClick} />
						<NoGrowWrapper>
							<Filters />
						</NoGrowWrapper>
					</SearchAndFiltersWrapper>
				</Header>
				<SearchBarWrapper>
					{state.searchIsVisibleOnMobile && <SearchBarMobile />}
				</SearchBarWrapper>
				<GridView />
			</ScreenWithNavbarLayout>
		</OffersScreenContext.Provider>
	);
};

export const OffersScreen = withSuspense(OffersScreenComponent, OffersScreenSkeleton);
