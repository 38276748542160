/**
 * @generated SignedSource<<a00d398659773c5fe1b8ea863b0d3926>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookTreeInput = {
  clientMutationId?: string | null;
  rootId: string;
};
export type advanceTreeNavigation_BookTreeMutation$variables = {
  input: BookTreeInput;
};
export type advanceTreeNavigation_BookTreeMutation$data = {
  readonly Billing: {
    readonly bookTree: {
      readonly clientMutationId: string | null;
      readonly root: {
        readonly " $fragmentSpreads": FragmentRefs<"advanceTreeNavigation_LearnOpportunityV2Fragment">;
      };
    } | null;
  };
};
export type advanceTreeNavigation_BookTreeMutation = {
  response: advanceTreeNavigation_BookTreeMutation$data;
  variables: advanceTreeNavigation_BookTreeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contentKind",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "contentNodeAdvancementResult",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reasonConfigTypes",
            "storageKey": null
          }
        ],
        "type": "CanNotBeStartedContentNodeAdvancementResult",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "advanceTreeNavigation_BookTreeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BookTreePayload",
            "kind": "LinkedField",
            "name": "bookTree",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunityV2",
                "kind": "LinkedField",
                "name": "root",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "advanceTreeNavigation_LearnOpportunityV2Fragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "advanceTreeNavigation_BookTreeMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BillingMutations",
        "kind": "LinkedField",
        "name": "Billing",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BookTreePayload",
            "kind": "LinkedField",
            "name": "bookTree",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LearnOpportunityV2",
                "kind": "LinkedField",
                "name": "root",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LearnOpportunityV2",
                    "kind": "LinkedField",
                    "name": "root",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "viewerTreeState",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "headContentId",
                                        "storageKey": null
                                      },
                                      (v5/*: any*/)
                                    ],
                                    "type": "IsStartedViewerTreeState",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v5/*: any*/)
                                    ],
                                    "type": "IsFinishedViewerTreeState",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "extension",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "unlockInfo",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          (v4/*: any*/),
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "firstContentId",
                                                "storageKey": null
                                              }
                                            ],
                                            "type": "UnlockInfoDemo",
                                            "abstractKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "CartClickout",
                                        "kind": "LinkedField",
                                        "name": "cartClickout",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "relativeLink",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "netPrice",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "ihkState",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "PublishedRootExtensionImpl",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "LearnOpportunityRootStructureDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "typeDefinition",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v7/*: any*/),
                        "type": "LearnOpportunityContentTypeDefinition",
                        "abstractKey": "__isLearnOpportunityContentTypeDefinition"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v7/*: any*/),
                        "type": "LearnAsyncContentTypeDefinition",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nextContentNodeId",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d1b329e84c07ae4da27ba894fec0e86",
    "id": null,
    "metadata": {},
    "name": "advanceTreeNavigation_BookTreeMutation",
    "operationKind": "mutation",
    "text": "mutation advanceTreeNavigation_BookTreeMutation(\n  $input: BookTreeInput!\n) {\n  Billing {\n    bookTree(input: $input) {\n      clientMutationId\n      root {\n        ...advanceTreeNavigation_LearnOpportunityV2Fragment\n        id\n      }\n    }\n  }\n}\n\nfragment advanceTreeNavigation_LearnOpportunityV2Fragment on LearnOpportunityV2 {\n  root {\n    structureDefinition {\n      __typename\n      ... on LearnOpportunityRootStructureDefinition {\n        viewerTreeState {\n          __typename\n          kind\n          ... on IsStartedViewerTreeState {\n            headContentId\n            startedAt\n          }\n          ... on IsFinishedViewerTreeState {\n            startedAt\n          }\n        }\n        extension {\n          __typename\n          ... on PublishedRootExtensionImpl {\n            unlockInfo {\n              __typename\n              kind\n              ... on UnlockInfoDemo {\n                firstContentId\n              }\n            }\n            cartClickout {\n              relativeLink\n              netPrice\n            }\n            ihkState\n          }\n        }\n      }\n    }\n    id\n  }\n  typeDefinition {\n    __typename\n    ... on LearnOpportunityContentTypeDefinition {\n      __isLearnOpportunityContentTypeDefinition: __typename\n      contentKind\n      contentNodeAdvancementResult {\n        __typename\n        status\n        ... on CanNotBeStartedContentNodeAdvancementResult {\n          reasonConfigTypes\n        }\n      }\n    }\n    ... on LearnAsyncContentTypeDefinition {\n      contentKind\n      contentNodeAdvancementResult {\n        __typename\n        status\n        ... on CanNotBeStartedContentNodeAdvancementResult {\n          reasonConfigTypes\n        }\n      }\n    }\n  }\n  nextContentNodeId\n}\n"
  }
};
})();

(node as any).hash = "f0c6f5468c364f9deaa684feb055de6b";

export default node;
