/**
 * @generated SignedSource<<f3e71c035c05eef87248b29ec611e7d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messageModalPage_MessageFragment$data = {
  readonly internalTitle: string;
  readonly " $fragmentType": "messageModalPage_MessageFragment";
};
export type messageModalPage_MessageFragment$key = {
  readonly " $data"?: messageModalPage_MessageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messageModalPage_MessageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "messageModalPage_MessageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    }
  ],
  "type": "MessageDefinition",
  "abstractKey": null
};

(node as any).hash = "605c7d47b2654cc92e7963b592226f3e";

export default node;
