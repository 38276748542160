import React from "react";
import { MessagePreviewCardSkeleton } from "@screens/messages/parts/message-preview-card/message-preview-card.skeleton";
import { messagesOverviewClass } from "@screens/messages/parts/messages-overview/messages-overview.styles";
import { ScreenWithNavbarLayout } from "src/layouts/screen-with-navbar-layout";

export const MessagesScreenSkeleton = () => {
	const numCardSkeletons = 6;
	return (
		<ScreenWithNavbarLayout>
			<div className={messagesOverviewClass}>
				{Array.from({ length: numCardSkeletons }).map((_, index) => (
					<MessagePreviewCardSkeleton key={index} />
				))}
			</div>
		</ScreenWithNavbarLayout>
	);
};
