/**
 * @generated SignedSource<<9cb6eff95670ae0380223d1053aed599>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type LearnableKind = "Course";
import { FragmentRefs } from "relay-runtime";
export type dashboard_PublishedLearnableFragment$data = {
  readonly id: string;
  readonly kind: LearnableKind;
  readonly " $fragmentSpreads": FragmentRefs<"educationalOfferCard_PublishedCourseLearnableFragment">;
  readonly " $fragmentType": "dashboard_PublishedLearnableFragment";
};
export type dashboard_PublishedLearnableFragment$key = {
  readonly " $data"?: dashboard_PublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"dashboard_PublishedLearnableFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "dashboard_PublishedLearnableFragment"
};

(node as any).hash = "240da1c54d66b2d8b156957617323414";

export default node;
