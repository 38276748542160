import { LocalDateTime, ZonedDateTime, ZoneId } from "@js-joda/core";
import { ValidatedInputText } from "@thekeytechnology/academies-lib-webapp";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle } from "react";
import { useFragment, useMutation } from "react-relay";
import { ValidatedCalendar } from "@components/validated-calendar";
import type { reminderForm_createNoteMutation } from "@relay/reminderForm_createNoteMutation.graphql";
import type { reminderForm_editNoteMutation } from "@relay/reminderForm_editNoteMutation.graphql";
import { NOTE_FRAGMENT, EDIT_NOTE_MUTATION, CREATE_NOTE_MUTATION } from "./reminder-form.graphql";
import { formClass } from "./reminder-form.style";
import {
	reminderSchema,
	type ReminderFormRef,
	type ReminderFormProps,
	type ReminderFormState,
} from "./reminder-form.types";
import { dateTimeFormatter } from "./reminder-form.utils";

export const ReminderForm = forwardRef<ReminderFormRef, ReminderFormProps>(function ReminderForm(
	{ onSuccess, noteFragmentRef },
	ref,
) {
	const data = useFragment(NOTE_FRAGMENT, noteFragmentRef ?? null);
	const [createReminder] = useMutation<reminderForm_createNoteMutation>(CREATE_NOTE_MUTATION);
	const [editReminder] = useMutation<reminderForm_editNoteMutation>(EDIT_NOTE_MUTATION);

	const isUpdate = Boolean(data && data?.id);

	const form = useFormik<ReminderFormState>({
		initialValues: {
			content: data?.content ?? "",
			remindAt: data?.reminder?.remindAt
				? new Date(
						ZonedDateTime.parse(data?.reminder?.remindAt).toLocalDateTime().toString(),
				  )
				: new Date(),
		},
		validateOnBlur: false,
		validateOnChange: false,
		validationSchema: reminderSchema,
		onSubmit: (values) => {
			const remindAt = values.remindAt.toISOString();

			if (isUpdate && data?.id) {
				const kind = data?.reminder?.kind;
				const isCompleted = data?.reminder?.isCompleted ?? false;

				editReminder({
					variables: {
						input: {
							noteId: data?.id,
							content: values.content,
							reminder: {
								...(kind &&
									kind === "NonRecurring" && {
										NonRecurring: {
											kind: "NonRecurring",
											isCompleted,
											remindAt: LocalDateTime.parse(
												values.remindAt.toISOString(),
												dateTimeFormatter,
											)
												.atZone(ZoneId.UTC)
												.toString(),
										},
									}),
								// TODO: How can I change the interval? Doesn't seem to be in the design
								...(kind &&
									kind === "Recurring" && {
										Recurring: {
											kind: "Recurring",
											isCompleted,
											remindAt: LocalDateTime.parse(
												values.remindAt.toISOString(),
												dateTimeFormatter,
											)
												.atZone(ZoneId.UTC)
												.toString(),
											interval: {
												Daily: {
													kind: "Daily",
													value: 1,
												},
											},
											remindUntil: null,
										},
									}),
							},
						},
					},
					onCompleted: () => {
						onSuccess?.();
					},
				});

				return;
			}

			createReminder({
				variables: {
					input: {
						content: values.content,
						reminder: {
							NonRecurring: {
								kind: "NonRecurring",
								isCompleted: false,
								remindAt,
							},
						},
					},
				},
				onCompleted: () => {
					onSuccess?.();
				},
			});
		},
	});

	useImperativeHandle(ref, () => ({
		submit: form.submitForm,
		validate: () => form.validateForm().then((errors) => Object.keys(errors).length === 0),
	}));

	return (
		<div className={formClass}>
			<ValidatedCalendar
				formikConfig={form}
				showTime
				name="remindAt"
				label="Erinnerungsdatum & Uhrzeit*"
				placeholder="..."
			/>
			<ValidatedInputText
				formikConfig={form}
				name="content"
				label="Erinnerungstext*"
				placeholder="Woran willst du erinnert werden?"
			/>
		</div>
	);
});
