import { css } from "@styled-system/css";

export const wrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "24",
	marginTop: "24",
});

export const listWrapperClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
});

export const listClass = css({
	display: "flex",
	flexDirection: "column",
	gap: "8",
});
