import type { DialogTemplateProps } from "@thekeytechnology/academies-lib-webapp/components/dialog-template";
import type { IconMap } from "@thekeytechnology/epic-ui";
import { match } from "ts-pattern";
import type { ElementTypeV2 } from "@relay/upsertNoteModal_NoteFragment.graphql";

export const getElementTypeName = (elementType?: ElementTypeV2) => {
	return match(elementType)
		.with("matrix", () => "Matrix")
		.with("multipleChoice", () => "Fragen")
		.with("text", "enhancedText", () => "Text")
		.with("podcast", "podcastWithTimestamp", () => "Podcast")
		.with("video", () => "Video")
		.with("markMistakes", () => "Fehler markieren")
		.with("file", () => "Heft")
		.with("clozeText", () => "Lückentext")
		.with("order", () => "Reihenfolge")
		.otherwise(() => "Element");
};

export const getElementTypeIcon = (elementType?: ElementTypeV2): keyof IconMap => {
	if (!elementType) return "informationCircle";

	return match<ElementTypeV2, keyof IconMap>(elementType)
		.with("matrix", () => "expandFull")
		.with("multipleChoice", () => "checkCircle")
		.with("text", "enhancedText", () => "pencil")
		.with("podcast", "podcastWithTimestamp", () => "microphonePodcast2")
		.with("video", () => "video")
		.with("markMistakes", () => "close")
		.with("file", () => "folder")
		.with("clozeText", () => "dragDrop")
		.with("order", () => "listBullets")
		.exhaustive();
};

export const getDialogTemplateProps = (isElementPage: boolean): DialogTemplateProps => {
	if (isElementPage) {
		return {
			primaryButtonLabel: "Zurück",
			primaryButtonStretch: true,
		};
	}

	return {
		primaryButtonLabel: "Speichern",
		primaryButtonIconName: "arrowRight",
		secondaryButtonLabel: "Abbrechen",
	};
};

export const getSourceTypeName = (isElement?: boolean, definitionType?: "root" | "child") => {
	if (isElement) return "Element";

	return match(definitionType)
		.with("root", () => "Kurs")
		.with("child", () => "Lektion")
		.otherwise(() => "Element");
};
