import { Button } from "@thekeytechnology/academies-lib-webapp/components/button";
import { Modal } from "@thekeytechnology/academies-lib-webapp/components/modal";
import { useCAOptinCookie } from "@thekeytechnology/academies-lib-webapp/hooks/use-ca-optin-cookie";
import { useWindowSize } from "@thekeytechnology/academies-lib-webapp/hooks/use-window-size";
import { selectIsLoggedIn } from "@thekeytechnology/academies-lib-webapp/slices";
import { isDevEnvironment } from "@thekeytechnology/academies-lib-webapp/utils";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as CookieIcon } from "@assets/cookie-icon.svg";
import { brandStrong120 } from "@themes/colors";
import { TkaH1Span, TkaP2Anchor, TkaP2Span } from "@themes/font-tags";
import { spacing0 } from "@themes/spacing";
import {
	ButtonsWrapper,
	ContentWrapper,
	CookieIconWrapper,
	TextWrapper,
	Wrapper,
} from "./cookie-banner.styles";
import { Header } from "../header";

export const CookieBannerScreen = () => {
	const { isMediumUp } = useWindowSize();

	const isLoggedIn = useSelector(selectIsLoggedIn);

	const { cookieExists, setCookie } = useCAOptinCookie();

	const [isVisible, setIsVisible] = useState(!isLoggedIn && !cookieExists);

	const handleOnHide = () => {
		setCookie(false);
		setIsVisible(false);
	};

	const handleOnAccept = () => {
		setCookie(true);
		setIsVisible(false);
	};

	const modalKey = isMediumUp ? "desktop" : "mobile";

	if (isDevEnvironment()) {
		return null;
	}

	return (
		<Modal
			withShadow
			hasCloseIcon
			key={modalKey}
			position="bottom"
			isVisible={isVisible}
			fullWidth={false}
			marginRem={1}
			onHide={handleOnHide}
			maximized={!isMediumUp}
			borderRadiusRem={isMediumUp ? undefined : 0}
			padding={spacing0}
			modal={false}
		>
			<Wrapper>
				{!isMediumUp && <Header />}
				<ContentWrapper>
					<TextWrapper>
						<TkaH1Span>Datenschutzeinstellungen</TkaH1Span>
						<TkaP2Span>
							Auf unseren Websites{" "}
							<TkaP2Anchor
								tkaColor={brandStrong120}
								underline
								href="https://www.constellation.academy"
								target="_blank"
							>
								https://www.constellation.academy
							</TkaP2Anchor>{" "}
							und{" "}
							<TkaP2Anchor
								tkaColor={brandStrong120}
								underline
								href="https://app.constellation.academy"
								target="_blank"
							>
								https://app.constellation.academy
							</TkaP2Anchor>{" "}
							kommen Cookies und ähnliche Technologien zum Einsatz (gemeinsam
							„Cookies“).
							<br />
							Essenzielle Cookies, die für den Betrieb der Websites technisch zwingend
							erforderlich sind, werden stets gesetzt. Marketing Cookies und Externe
							Medien Cookies werden nur mit Deiner Einwilligung gesetzt. Diese Cookies
							dienen der Direktwerbung und vereinfachen die Interaktion mit anderen
							Websites und sozialen Netzwerken.
							<br />
							<br />
							Mit Klick auf „Akzeptieren und weiter“ bist Du mit den Marketing Cookies
							und Externe Medien Cookies einverstanden. Falls Du dem nicht zustimmen
							magst, beschränken wir uns auf die essenziellen Cookies. Weitere
							Informationen findest Du in unserer{" "}
							<TkaP2Anchor
								tkaColor={brandStrong120}
								underline
								href="https://constellation.academy/datenschutz/"
								target="_blank"
							>
								Datenschutzerklärung
							</TkaP2Anchor>
							.
						</TkaP2Span>
					</TextWrapper>
					<ButtonsWrapper>
						<Button
							fillParent
							label="Ablehnen"
							colorVersion="tertiary"
							onClick={handleOnHide}
						/>
						<Button
							fillParent
							label="Akzeptieren und weiter"
							colorVersion="strong"
							onClick={handleOnAccept}
						/>
					</ButtonsWrapper>
					<CookieIconWrapper>
						<CookieIcon />
					</CookieIconWrapper>
				</ContentWrapper>
			</Wrapper>
		</Modal>
	);
};
