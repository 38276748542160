import "regenerator-runtime/runtime";
import { ReduxStore } from "@thekeytechnology/academies-lib-webapp";
import React from "react";

import "./index.css";
import "@styled-system/styles.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./router/index.ts";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ToastProvider } from "@components/toast-provider";
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
// TODO: add strictmode (react-spring-bottom-sheet doesn't work with strictmode)
root.render(
	<Provider store={ReduxStore}>
		<ToastProvider>
			<App />
		</ToastProvider>
	</Provider>,
);
