import { graphql } from "react-relay";

export const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment contentFinished_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ELearningContentSubmissionDefinition {
				status
			}
			... on PassedELearningContentSubmissionDefinition {
				rewardResults {
					... on GamificationPoints_PointsReceivedRewardResult {
						kind
						points {
							num
						}
					}
				}
			}
			... on FailedELearningContentSubmissionDefinition {
				rewardResults {
					... on GamificationPoints_PointsReceivedRewardResult {
						kind
						points {
							num
						}
					}
				}
			}
		}
		learnOpportunity {
			id
			root {
				id
				structureDefinition {
					... on LearnOpportunityRootStructureDefinition {
						viewerTreeState {
							kind
						}
					}
				}
			}
			typeDefinition {
				... on LearnOpportunityContentTypeDefinition {
					contentNodeAdvancementResult {
						status
					}
				}
			}
			nextContentNodeId
		}
	}
`;

export const RESTART_CONTENT_NODE_AFTER_PASSED_MUTATION = graphql`
	mutation contentFinished_RestartContentNodeAfterPassedMutation(
		$input: RestartContentNodeAfterPassedInput!
	) {
		LearnV2 {
			restartContentNodeAfterPassed(input: $input) {
				clientMutationId
				contentSubmission {
					id
				}
			}
		}
	}
`;

export const RESTART_CONTENT_NODE_AFTER_FAILED_MUTATION = graphql`
	mutation contentFinished_RestartContentNodeAfterFailedMutation(
		$input: RestartContentNodeAfterFailedInput!
	) {
		LearnV2 {
			restartContentNodeAfterFailed(input: $input) {
				clientMutationId
				contentSubmission {
					id
				}
			}
		}
	}
`;
