import { graphql } from "react-relay";

export const MESSAGE_DEFINITION_FRAGMENT = graphql`
	fragment messageModalPage_MessageFragment on MessageDefinition {
		internalTitle
	}
`;

export const PAGE_FRAGMENT = graphql`
	fragment messageModalPage_PageFragment on MessageDefinitionPage {
		internalTitle
		data {
			... on ImagePage {
				element {
					image {
						id
						url
					}
				}
				kind
			}
			... on ImageWithButtonPage {
				element {
					image {
						id
						url
					}
				}
				button {
					kind
					text
					... on ConfirmButton {
						text
						kind
					}
					... on TextCopyButton {
						text
						kind
					}
					... on LinkButton {
						text
						url
						kind
					}
				}
				kind
			}
		}
	}
`;
