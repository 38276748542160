import { SideMenuContentPadding } from "@thekeytechnology/academies-lib-webapp/containers/side-menu-content-padding";
import { useEffect, useState, useContext, useCallback } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { ContentSubmissionModalContext } from "@components/content-submission-modal-context-provider";
import { GapText } from "@components/gap-text";
import { MarkMistakesElement_ContentSubmissionFragment$key } from "@relay/MarkMistakesElement_ContentSubmissionFragment.graphql";
import { MarkMistakesElement_SubmitMarkMistakesElementMutation } from "@relay/MarkMistakesElement_SubmitMarkMistakesElementMutation.graphql";
import { shade100 } from "@themes/colors";
import { TkaH1Span, TkaP2Span } from "@themes/font-tags";
import {
	MarkMistakeElementStatus,
	MarkMistakesElementProps,
} from "./mark-mistakes-element.interface";
import { Spacing12, Spacing32, Wrapper } from "./mark-mistakes-element.styles";
import { MarkMistakesLearnElement } from "@components/mark-mistakes-learn-element";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment MarkMistakesElement_ContentSubmissionFragment on ContentSubmission {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					... on InputAndIsCorrectElementState {
						isCorrect
						inputElementState {
							... on MarkMistakesInputElementState {
								checkedMistakeIndices
							}
						}
					}
					... on MarkMistakesShowAnswerElementState {
						correctMistakeIndices
						lastCheckedMistakeIndices
					}
					... on MarkMistakesInputElementState {
						checkedMistakeIndices
					}
					kind
					element {
						elementType
						id
						title
						... on MarkMistakesLearnElement {
							snippets {
								content
							}
						}
						...markMistakesLearnElement_MarkMistakesLearnElementFragment
					}
				}
			}
		}
	}
`;

const SUBMIT_MARK_MISTAKES_ELEMENT_MUTATION = graphql`
	mutation MarkMistakesElement_SubmitMarkMistakesElementMutation(
		$input: SubmitMarkMistakesElementV2Input!
	) {
		LearnV2 {
			submitMarkMistakesElement(input: $input) {
				contentSubmission {
					definition {
						... on ActiveELearningContentSubmissionDefinition {
							currentElementState {
								kind
								... on InputAndIsCorrectElementState {
									isCorrect
								}
							}
						}
					}
					...ContentSubmissionScreen_ContentSubmissionFragment
				}
			}
		}
	}
`;

export const MarkMistakesElement = ({ contentSubmissionFragmentRef }: MarkMistakesElementProps) => {
	const contentSubmission = useFragment<MarkMistakesElement_ContentSubmissionFragment$key>(
		CONTENT_SUBMISSION_FRAGMENT,
		contentSubmissionFragmentRef,
	);

	const [submitMarkMistakesElement] =
		useMutation<MarkMistakesElement_SubmitMarkMistakesElementMutation>(
			SUBMIT_MARK_MISTAKES_ELEMENT_MUTATION,
		);

	const elementState = contentSubmission?.definition?.currentElementState;
	const element = elementState?.element;
	const currentElementStateKind = elementState?.kind || "Untouched";

	const isShowAnswer = currentElementStateKind === "ShowAnswer";
	const isInputCorrect = currentElementStateKind === "InputAndIsCorrect";
	const isUntouched =
		currentElementStateKind === "Untouched" ||
		currentElementStateKind === "UntouchedAndPreviouslyIncorrect";
	const isInput = currentElementStateKind === "Input";

	const lastCheckedIndices =
		elementState?.lastCheckedMistakeIndices ||
		elementState?.inputElementState?.checkedMistakeIndices ||
		elementState?.checkedMistakeIndices ||
		undefined;
	const correctIndices = elementState?.correctMistakeIndices || [];

	const {
		isModalVisible,
		setCanGoNext,
		addGoToNextOnClickListener,
		addTryAgainOnClickListener,
		setFailureModalVisible,
		setSuccessModalVisible,
		setLoading,
	} = useContext(ContentSubmissionModalContext);

	const [checkedIndexes, setCheckedIndexes] = useState<number[]>([]);

	const handleSubmitClick = useCallback(() => {
		setLoading(true);
		submitMarkMistakesElement({
			variables: {
				input: {
					contentSubmissionId: contentSubmission.id!,
					markMistakesElementId: element?.id!,
					checkedMistakeIndices: checkedIndexes,
				},
			},
			onCompleted: (response) => {
				setLoading(false);
				const currentElementState =
					response.LearnV2.submitMarkMistakesElement?.contentSubmission.definition
						?.currentElementState;
				if (!currentElementState) return;

				if (currentElementState.isCorrect) {
					setSuccessModalVisible(true);
				} else if (
					currentElementState.isCorrect === false ||
					currentElementState.kind === "UntouchedAndPreviouslyIncorrect"
				) {
					setFailureModalVisible(true);
				}
			},
		});
	}, [
		submitMarkMistakesElement,
		contentSubmission.id,
		element?.id,
		checkedIndexes,
		setSuccessModalVisible,
		setFailureModalVisible,
	]);

	const handleGoToNextClicked = useCallback(() => {
		if (isUntouched || isInput) {
			handleSubmitClick();
			return true;
		}
		return false;
	}, [isUntouched, isInput, handleSubmitClick]);

	useEffect(() => addGoToNextOnClickListener(handleGoToNextClicked), [handleGoToNextClicked]);

	useEffect(() => {
		setCanGoNext(checkedIndexes.length > 0 || !isUntouched);
	}, [checkedIndexes, isShowAnswer]);

	const handleTryAgainClicked = useCallback(() => {
		setCheckedIndexes([]);
	}, []);

	useEffect(() => addTryAgainOnClickListener(handleTryAgainClicked), [handleTryAgainClicked]);

	useEffect(() => {
		if (!isUntouched && lastCheckedIndices && lastCheckedIndices.length > 0) {
			setCheckedIndexes([...lastCheckedIndices]);
		}
	}, [lastCheckedIndices, isUntouched]);

	const calculateGapTextStatus = (index: number): MarkMistakeElementStatus => {
		const isSelected = checkedIndexes.includes(index);

		if (isShowAnswer) {
			const isCorrect = correctIndices.includes(index);
			if (isCorrect) {
				return isSelected
					? MarkMistakeElementStatus.Correct
					: MarkMistakeElementStatus.ActuallyCorrect;
			}
		}

		if (isInputCorrect && isSelected) {
			return MarkMistakeElementStatus.Correct;
		}

		return MarkMistakeElementStatus.Default;
	};

	const inputDisabled = isModalVisible || (!isUntouched && !isInput);

	const handleMistakeOnClick = (index: number) => {
		if (inputDisabled) return;
		const selected = checkedIndexes.includes(index);
		if (selected) {
			setCheckedIndexes(checkedIndexes.filter((i) => i !== index));
		} else {
			setCheckedIndexes([...checkedIndexes, index]);
		}
	};

	return (
		<SideMenuContentPadding data-selection-menu-element-id={elementState?.element.id}>
			<TkaH1Span tkaColor={shade100}>
				{contentSubmission.definition.currentElementState?.element.title}
			</TkaH1Span>
			<Spacing12 />
			<TkaP2Span tkaColor={shade100}>
				{"Tippe auf die Felder und wähle die passenden Antworten aus."}
			</TkaP2Span>
			<Spacing32 />
			<Wrapper>
				<MarkMistakesLearnElement
					isUntouched={isUntouched}
					isInput={isInput}
					checkedIndices={checkedIndexes}
					onSnippetClick={handleMistakeOnClick}
					getStatusForSnippetByIndex={calculateGapTextStatus}
					learnElementFragmentRef={element}
				/>
			</Wrapper>
		</SideMenuContentPadding>
	);
};
