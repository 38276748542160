/**
 * @generated SignedSource<<76f4eb4649d56596a22420d2fe69fca9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type upsertReminderModal_ReminderNoteFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"reminderForm_QueryFragment">;
  readonly " $fragmentType": "upsertReminderModal_ReminderNoteFragment";
};
export type upsertReminderModal_ReminderNoteFragment$key = {
  readonly " $data"?: upsertReminderModal_ReminderNoteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"upsertReminderModal_ReminderNoteFragment">;
};

import upsertReminderModal_ReminderNoteFragmentRefetch_graphql from './upsertReminderModal_ReminderNoteFragmentRefetch.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": upsertReminderModal_ReminderNoteFragmentRefetch_graphql,
      "identifierField": "id"
    }
  },
  "name": "upsertReminderModal_ReminderNoteFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "reminderForm_QueryFragment"
    }
  ],
  "type": "Note",
  "abstractKey": null
};

(node as any).hash = "07b8b632212d89369d12dd25b368d0c8";

export default node;
