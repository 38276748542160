import { css, cva } from "@styled-system/css";

export const indicatorsClass = css({
	zIndex: "10",
	position: "relative",
	transform: "translateY(3em)",
	display: "flex!",
	gap: "8!",
	justifyContent: "center",
	mx: "32",
});
export const indicatorButtonClass = cva({
	base: {
		borderRadius: "16!",
		h: "[.5em]",
		w: "[10%]",
		bg: "primary.20",
	},
	variants: {
		isActive: {
			true: {
				bg: "primary.80!",
			},
			false: {
				bg: "shade.0",
			},
		},
	},
});
