import { graphql } from "react-relay";

export const NOTE_FRAGMENT = graphql`
	fragment upsertNoteModal_NoteFragment on Note
	@refetchable(queryName: "upsertNoteModal_NoteFragmentRefetch") {
		id
		content
		markedText
		color
		source {
			kind

			... on ElementNoteSourceType {
				element {
					elementType
				}
				publishedRootNode {
					id
					structureDefinition {
						title
						definitionType
					}
					image {
						url
					}
				}
			}
			... on ModuleNoteSource {
				publishedRootNode {
					id
					structureDefinition {
						title
						definitionType
					}
					image {
						url
					}
				}
			}
			... on CourseNoteSource {
				publishedRootNode {
					id
					structureDefinition {
						title
						definitionType
					}
					image {
						url
					}
				}
			}
		}
	}
`;

export const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment upsertNoteModal_ContentSubmissionFragment on ContentSubmission
	@refetchable(queryName: "upsertNoteModal_ContentSubmissionFragmentRefetch") {
		id
		definition {
			... on ActiveELearningContentSubmissionDefinition {
				currentElementState {
					element {
						id
						elementType
					}
				}
			}
		}
		...noteSourceElementRenderer_ContentSubmissionFragment
	}
`;

export const LEARN_ELEMENT_FRAGMENT = graphql`
	fragment upsertNoteModal_ElementFragment on LearnElement {
		id
		elementType
		...noteSourceElementRenderer_ElementFragment
	}
`;

export const LEARN_OPPORTUNITY_V2_FRAGMENT = graphql`
	fragment upsertNoteModal_LearnOpportunityV2Fragment on LearnOpportunityV2 {
		id
		image {
			name
			id
			thumbnail
		}
		description
		structureDefinition {
			title
			definitionType
		}
		typeDefinition {
			... on LearnOpportunityBranchTypeDefinition {
				children {
					id
					description
					structureDefinition {
						title
					}
				}
			}
		}
	}
`;

export const CREATE_NOTE_MUTATION = graphql`
	mutation upsertNoteModal_CreateNoteMutation($input: CreateNoteInput!) {
		Note {
			createNote(input: $input) {
				note {
					node {
						...noteCard_NoteFragment
					}
				}
			}
		}
	}
`;

export const EDIT_NOTE_MUTATION = graphql`
	mutation upsertNoteModal_EditNoteMutation($input: EditNoteInput!) {
		Note {
			editNote(input: $input) {
				note {
					node {
						...noteCard_NoteFragment
					}
				}
			}
		}
	}
`;

export const REMOVE_NOTE_SOURCE_MUTATION = graphql`
	mutation upsertNoteModal_RemoveNoteSourceMutation($input: RemoveNoteSourceInput!) {
		Note {
			removeNoteSource(input: $input) {
				clientMutationId
			}
		}
	}
`;
