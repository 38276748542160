import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { useState } from "react";
import { ParagraphWithBackgroundColor } from "@components/paragraph-with-background-color";
import { useToast } from "@hooks/useToast";
import uploadFileForAsyncElementService from "@services/upload-file-for-async-element.service";
import { shade20 } from "@themes/colors";
import { TkaP2Span } from "@themes/font-tags";
import { CONTENT_SUBMISSION_FILE_TYPES } from "./async-content-submission-file-upload.const";
import { AsyncContentSubmissionFileUploadProps } from "./async-content-submission-file-upload.interface";
import { ContentWrapper } from "./async-content-submission-file-upload.styles";
import { FileSelectButton } from "../file-select-button";

export const AsyncContentSubmissionFileUpload = ({
	asyncElementId,
	onFileUploaded,
}: AsyncContentSubmissionFileUploadProps) => {
	const { t } = useTkaTranslation("uploadElementScreen");

	const title = t("upload_element_screen.fileUploadTitle");
	const fileNamePlaceholder = t("upload_element_screen.fileUploadPlaceholder");
	const selectFileButtonLabel = t("upload_element_screen.selectFileButtonLabel");
	const wrongFileTypeErrorMessage = t("upload_element_screen.wrongFileErrorMessage");
	const fileSizeExceededErrorMessage = t("upload_element_screen.fileSizeExceededErrorMessage");

	const { showError } = useToast();
	const [uploading, setUploading] = useState(false);

	const acceptFileTypes = CONTENT_SUBMISSION_FILE_TYPES.join(", ");

	const handleFileSelected = (file: File) => {
		if (uploading) return;
		if (file.size > 4000000) {
			showError({ detail: fileSizeExceededErrorMessage });
			return;
		}
		if (!CONTENT_SUBMISSION_FILE_TYPES.includes(file.type)) {
			showError({ detail: wrongFileTypeErrorMessage });
			return;
		}
		setUploading(true);
		uploadFileForAsyncElementService
			.upload(file, asyncElementId)
			.then(() => {
				setUploading(false);
				onFileUploaded?.();
			})
			.catch(() => {
				setUploading(false);
			});
	};

	return (
		<ParagraphWithBackgroundColor title={title}>
			<ContentWrapper>
				<TkaP2Span tkaColor={shade20}>{fileNamePlaceholder}</TkaP2Span>
				<FileSelectButton
					loading={uploading}
					label={selectFileButtonLabel}
					colorVersion="strong"
					onFileSelected={handleFileSelected}
					accept={acceptFileTypes}
					widthRem={12.5}
					iconName="archiveDrawer"
				></FileSelectButton>
			</ContentWrapper>
		</ParagraphWithBackgroundColor>
	);
};
