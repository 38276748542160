import { css, cva } from "@styled-system/css";

export const pageImageClass = cva({
	base: {
		objectFit: "cover",
		borderRadius: "16",
		aspectRatio: "9 / 16",
		transition: "all",
		transitionTimingFunction: "ease-in-out",
		transitionDuration: "[200ms]",
	},
	variants: {
		isLoaded: {
			true: {
				opacity: "1",
			},
			false: {
				opacity: "0",
			},
		},
	},
});

export const pageImageWrapper = cva({
	base: {
		transition: "all",
		transitionDuration: "200ms",
		transitionTimingFunction: "ease",
	},
	variants: {
		isPreview: {
			true: {
				w: "[200px]",
				flexShrink: 0,
			},
			false: { w: "[100%]" },
		},
	},
});

export const pageButtonWrapperClass = css({
	mx: "[2em]",
	transform: "translateY(-4em)",
	fontWeight: "bold",
});
