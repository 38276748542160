import { Badge } from "@thekeytechnology/academies-lib-webapp/components/badge";
import { useTkaTranslation } from "@thekeytechnology/academies-lib-webapp/hooks/use-tka-translation";
import { graphql, useFragment } from "react-relay";
import { ReactComponent as SuccessModalIcon } from "@assets/modal-icon-success-new.svg";
import { GoToNextElementButton } from "@components/go-to-next-element-button";
import { SuccessModal } from "@components/success-modal";
import { ContentSubmissionSuccessModal_ContentSubmissionFragment$key } from "@relay/ContentSubmissionSuccessModal_ContentSubmissionFragment.graphql";
import { brandStrong120 } from "@themes/colors";
import { TkaP2Span, TkaH1Span } from "@themes/font-tags";
import { ContentSubmissionSuccessModalProps } from "./content-submission-success-modal.interface";
import {
	BadgeWrapper,
	ContentWrapper,
	Flex,
	IconAndBadgeWrapper,
	MessageContainer,
} from "./content-submission-success-modal.styles";

const CONTENT_SUBMISSION_FRAGMENT = graphql`
	fragment ContentSubmissionSuccessModal_ContentSubmissionFragment on ContentSubmission {
		...goToNextElementButton_ContentSubmissionFragment
	}
`;

export const ContentSubmissionSuccessModal = ({
	isVisible,
	onHide,
	contentSubmissionFragmentRef,
}: ContentSubmissionSuccessModalProps) => {
	const { t } = useTkaTranslation("successEvaluationModal");

	const Icon = <SuccessModalIcon />;
	const title = t("success_evaluation_modal.title");
	const description = t("success_evaluation_modal.description");
	const contentSubmission =
		useFragment<ContentSubmissionSuccessModal_ContentSubmissionFragment$key>(
			CONTENT_SUBMISSION_FRAGMENT,
			contentSubmissionFragmentRef,
		);

	const points = 0;
	return (
		<SuccessModal isVisible={isVisible} onHide={onHide} displayMode={"success"}>
			<Flex>
				<ContentWrapper>
					<IconAndBadgeWrapper>
						{Icon}
						{points > 0 && (
							<BadgeWrapper>
								<Badge text={"+" + points} />
							</BadgeWrapper>
						)}
					</IconAndBadgeWrapper>
					<MessageContainer>
						<TkaH1Span tkaColor={brandStrong120}>{title}</TkaH1Span>
						<TkaP2Span tkaColor={brandStrong120}>{description}</TkaP2Span>
					</MessageContainer>
				</ContentWrapper>
				<GoToNextElementButton contentSubmissionFragmentRef={contentSubmission} />
			</Flex>
		</SuccessModal>
	);
};
