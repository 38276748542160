import { stripHtml } from "@thekeytechnology/academies-lib-webapp/utils";
import { useFragment } from "react-relay";
import { MultipleChoiceGapText } from "@components/multiple-choice-gap-text/multiple-choice-gap-text.component";
import { MultipleChoiceGapTextStatus } from "@components/multiple-choice-gap-text/multiple-choice-gap-text.interface";
import { ALPHABET } from "./multiple-choice-learn-element.consts";
import { MULTIPLE_CHOICE_LEARN_ELEMENT_FRAGMENT } from "./multiple-choice-learn-element.graphql";
import { MultipleChoiceLearnElementProps } from "./multiple-choice-learn-element.types";

export const MultipleChoiceLearnElement = ({
	disabled = false,
	checkedIndices,
	onAnswerClick,
	getStatusForIndex,
	learnElementFragmentRef,
}: MultipleChoiceLearnElementProps) => {
	const element = useFragment(
		MULTIPLE_CHOICE_LEARN_ELEMENT_FRAGMENT,
		learnElementFragmentRef ?? null,
	);

	const answers = element?.answerOptions;
	const useRadio = element?.answeringType === "allowOnlyOneAnswer";

	const createAnswerOnClickHandler = (index: number) => () => {
		onAnswerClick?.(index);
	};

	return (
		<>
			{answers?.map((answer, index) => {
				const status = disabled
					? MultipleChoiceGapTextStatus.Disabled
					: getStatusForIndex
					? getStatusForIndex?.(index)
					: MultipleChoiceGapTextStatus.Default;
				const checked = checkedIndices?.includes(index) ?? false;

				return (
					<MultipleChoiceGapText
						key={answer.answer}
						text={stripHtml(answer.answer)}
						isRadio={useRadio}
						checked={checked}
						answerLetter={ALPHABET[index]}
						status={status}
						onClick={createAnswerOnClickHandler(index)}
					/>
				);
			})}
		</>
	);
};
